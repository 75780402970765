// CartProvider.jsx
import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
} from "react";
import { useNavigate } from "react-router-dom";
import { ShoppingCart, Trash2, Plus, Minus, ArrowRight } from "lucide-react";

// API Base URL
const API_BASE_URL = "https://api2.made2tech.com";

// Create Cart Context
const CartContext = createContext();

// Cart Provider Component
export const CartProvider = ({ children }) => {
  const [cartItems, setCartItems] = useState([]);
  const [cartCount, setCartCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  // Helper function to get token
  const getToken = useCallback(() => {
    return localStorage.getItem("token");
  }, []);

  // Helper function to format time
  const formatTime = (timeNumber) => {
    // If timeNumber is a timestamp in milliseconds
    if (timeNumber > 10000) {
      const date = new Date(timeNumber);
      return date.toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
      });
    }

    // If timeNumber is in 24-hour format (e.g., 1430 for 2:30 PM)
    if (timeNumber > 0 && timeNumber < 2400) {
      const hours = Math.floor(timeNumber / 100);
      const minutes = timeNumber % 100;
      return `${hours.toString().padStart(2, "0")}:${minutes
        .toString()
        .padStart(2, "0")}`;
    }

    // Default return if format is unknown
    return "Time not available";
  };

  // Fetch cart items directly from API - Memoized with useCallback
  const fetchCartItems = useCallback(async () => {
    try {
      setIsLoading(true);

      const response = await fetch(`${API_BASE_URL}/cart/items`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${getToken()}`,
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        // Instead of throwing an error, set empty cart items
        setCartItems([]);
        setCartCount(0);
        setIsLoading(false);
        return;
      }

      const items = await response.json();

      // Transform API response to match the expected format in the application
      const transformedItems = items.map((item) => {
        const isResource = item.item_type === "resource";

        // Determine title based on item type
        let title = "";
        let price = "0.00";
        let exam = "";

        if (isResource) {
          // For resources, use resource name + theme name
          title =
            item.resource && item.theme
              ? `${item.resource.name} - ${item.theme.name}`
              : `Resource #${item.id}`;

          // Get price from theme
          price =
            item.theme && item.theme.price
              ? item.theme.price === "0" || item.theme.price === 0
                ? "Free"
                : `${item.theme.price}`
              : "Free";
        } else {
          // For events, use event name
          title =
            item.event && item.event.name
              ? item.event.name
              : `Event #${item.id}`;

          // In your transformation logic, just save the price value without the pound symbol
          price =
            item.event && item.event.price
              ? item.event.price === "0" || item.event.price === 0
                ? "Free"
                : item.event.price // No pound symbol
              : "Free";
        }
        // We don't have date, time, location, and exam in the new API response
        // If your API will include these in the future, add them here

        return {
          id: item.id.toString(),
          title: title,
          price: price,
          date: "", // These fields would need to be populated from event details if available
          time: "",
          location: "",
          type: item.item_type,
          quantity: isResource ? item.quantity || 1 : 1,
          attendees: !isResource ? item.quantity || 1 : 1,
          // Preserve original IDs for API calls
          resource_id: item.resource_id,
          theme_id: item.theme_id,
          event_id: item.event_id,
          exam: exam,
        };
      });

      setCartItems(transformedItems);
      setCartCount(transformedItems.length);
      setError(null);
    } catch (err) {
      console.error("Error fetching cart items:", err);
      // Instead of setting an error, set empty cart items
      setCartItems([]);
      setCartCount(0);
    } finally {
      setIsLoading(false);
    }
  }, [getToken]); // Dependency on getToken

  // Initialize cart from API or localStorage
  useEffect(() => {
    const token = localStorage.getItem("token");

    if (token) {
      fetchCartItems();
    } else {
      // Fall back to localStorage if not authenticated
      const savedCart = JSON.parse(localStorage.getItem("cart") || "[]");
      setCartItems(savedCart);
      setCartCount(savedCart.length);
      setIsLoading(false);
    }
  }, [fetchCartItems]); // Now it's safe to include fetchCartItems since it's memoized

  // Add to cart
  const addToCart = useCallback(
    async (item) => {
      try {
        const token = localStorage.getItem("token");

        if (!token) {
          // Fall back to localStorage if not authenticated
          const cartItem = {
            ...item,
            id: Date.now().toString(),
            quantity: item.type === "resource" ? 1 : null,
            attendees: item.type === "event" ? 1 : null,
            addedAt: new Date().toISOString(),
          };

          const newItems = [...cartItems, cartItem];
          setCartItems(newItems);
          localStorage.setItem("cart", JSON.stringify(newItems));
          setCartCount(newItems.length);
          return;
        }

        // Add to API cart
        if (item.type === "resource") {
          const response = await fetch(`${API_BASE_URL}/cart/add-resource`, {
            method: "POST",
            headers: {
              Authorization: `Bearer ${getToken()}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              resource_id: item.resource_id,
              theme_id: item.theme_id,
              quantity: 1,
            }),
          });

          if (!response.ok) {
            throw new Error("Failed to add resource to cart");
          }
        } else if (item.type === "event") {
          const response = await fetch(`${API_BASE_URL}/cart/add-event`, {
            method: "POST",
            headers: {
              Authorization: `Bearer ${getToken()}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ event_id: item.event_id }),
          });

          if (!response.ok) {
            throw new Error("Failed to add event to cart");
          }
        }

        // Refresh cart
        fetchCartItems();
      } catch (err) {
        console.error("Error adding to cart:", err);
        setError("Failed to add item to cart");
        throw err; // Re-throw so that calling components can handle the error
      }
    },
    [cartItems, fetchCartItems, getToken]
  ); // Added proper dependencies

  // Remove from cart
  const removeFromCart = useCallback(
    async (itemId) => {
      try {
        const token = localStorage.getItem("token");

        if (!token) {
          // Fall back to localStorage if not authenticated
          const updatedItems = cartItems.filter((item) => item.id !== itemId);
          setCartItems(updatedItems);
          localStorage.setItem("cart", JSON.stringify(updatedItems));
          setCartCount(updatedItems.length);
          return;
        }

        // Remove from API cart
        const response = await fetch(`${API_BASE_URL}/cart/items/${itemId}`, {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        });

        if (!response.ok) {
          throw new Error("Failed to remove cart item");
        }

        // Refresh cart
        fetchCartItems();
      } catch (err) {
        console.error("Error removing from cart:", err);
        setError("Failed to remove item from cart");
      }
    },
    [cartItems, fetchCartItems, getToken]
  );

  // Clear cart
  const clearCart = useCallback(async () => {
    try {
      const token = localStorage.getItem("token");

      if (!token) {
        // Fall back to localStorage if not authenticated
        setCartItems([]);
        localStorage.removeItem("cart");
        setCartCount(0);
        return;
      }

      // Clear API cart
      const response = await fetch(`${API_BASE_URL}/cart/clear`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      });

      if (!response.ok) {
        throw new Error("Failed to clear cart");
      }

      // Refresh cart
      fetchCartItems();
    } catch (err) {
      console.error("Error clearing cart:", err);
      setError("Failed to clear cart");
      throw err; // Re-throw so that calling components can handle the error
    }
  }, [fetchCartItems, getToken]);

  // Update quantity
  const updateQuantity = useCallback(
    async (itemId, newQuantity) => {
      if (newQuantity < 1) return;

      try {
        const token = localStorage.getItem("token");

        if (!token) {
          // Fall back to localStorage if not authenticated
          const updatedItems = cartItems.map((item) =>
            item.id === itemId ? { ...item, quantity: newQuantity } : item
          );
          setCartItems(updatedItems);
          localStorage.setItem("cart", JSON.stringify(updatedItems));
          return;
        }

        // Update API cart
        const response = await fetch(
          `${API_BASE_URL}/cart/update-resource/${itemId}?new_quantity=${newQuantity}`,
          {
            method: "PUT",
            headers: {
              Authorization: `Bearer ${getToken()}`,
              "Content-Type": "application/json",
            },
          }
        );

        if (!response.ok) {
          throw new Error("Failed to update cart item quantity");
        }

        // Refresh cart
        fetchCartItems();
      } catch (err) {
        console.error("Error updating quantity:", err);
        setError("Failed to update quantity");
      }
    },
    [cartItems, fetchCartItems, getToken]
  );

  // Update attendees
  const updateAttendees = useCallback(
    async (itemId, newAttendees) => {
      if (newAttendees < 1) return;

      try {
        const token = localStorage.getItem("token");

        if (!token) {
          // Fall back to localStorage if not authenticated
          const updatedItems = cartItems.map((item) =>
            item.id === itemId ? { ...item, attendees: newAttendees } : item
          );
          setCartItems(updatedItems);
          localStorage.setItem("cart", JSON.stringify(updatedItems));
          return;
        }

        // Update API cart (attendees are stored as quantity in API)
        const response = await fetch(
          `${API_BASE_URL}/cart/update-resource/${itemId}?new_quantity=${newAttendees}`,
          {
            method: "PUT",
            headers: {
              Authorization: `Bearer ${getToken()}`,
              "Content-Type": "application/json",
            },
            // body: JSON.stringify({ quantity: newAttendees }),
          }
        );

        if (!response.ok) {
          throw new Error("Failed to update cart item attendees");
        }

        // Refresh cart
        fetchCartItems();
      } catch (err) {
        console.error("Error updating attendees:", err);
        setError("Failed to update attendees");
      }
    },
    [cartItems, fetchCartItems, getToken]
  );

  // Calculate total
  const calculateTotal = useCallback(() => {
    return cartItems.reduce((total, item) => {
      // Handle free items
      if (item.price === "Free" || item.price === "free") {
        return total;
      }

      // Extract numeric value from price string and handle currency symbols
      const price = parseFloat(item.price.replace(/[^0-9.-]+/g, "")) || 0;
      // Multiply by quantity for resources or attendees for events
      const multiplier =
        item.type === "resource" ? item.quantity : item.attendees;
      return total + price * multiplier;
    }, 0);
  }, [cartItems]);

  const value = {
    cartItems,
    cartCount,
    isLoading,
    error,
    addToCart,
    removeFromCart,
    clearCart,
    updateQuantity,
    updateAttendees,
    calculateTotal,
    fetchCartItems, // Export fetchCartItems function
  };

  return <CartContext.Provider value={value}>{children}</CartContext.Provider>;
};

// Custom Hook for Cart
export const useCart = () => {
  const context = useContext(CartContext);
  if (!context) {
    throw new Error("useCart must be used within a CartProvider");
  }
  return context;
};

// Cart Page Component
export const CartPage = () => {
  const {
    cartItems,
    removeFromCart,
    updateQuantity,
    updateAttendees,
    calculateTotal,
    isLoading,
    error,
    fetchCartItems, // Add this to get the function from context
  } = useCart();
  const navigate = useNavigate();
  const [checkoutLoading, setCheckoutLoading] = useState(false);
  const [checkoutError, setCheckoutError] = useState(null);

  const handleDirectCheckout = async () => {
    try {
      setCheckoutLoading(true);
      setCheckoutError(null);

      const payload = {
        payment_method: "direct",
        transaction_id: `txn_${Date.now()}`, // Generate a simple transaction ID
      };

      // Make API call to process the purchase
      const response = await fetch(`${API_BASE_URL}/purchases/checkout`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        throw new Error("Checkout failed. Please try again.");
      }

      // If successful, redirect to success page instead of dashboard
      navigate("/order-success");
    } catch (err) {
      console.error("Checkout error:", err);
      setCheckoutError(
        err.message || "Failed to process your order. Please try again."
      );
    } finally {
      setCheckoutLoading(false);
    }
  };

  // Call fetchCartItems when the component mounts - FIXED HERE
  useEffect(() => {
    window.scrollTo(0, 0);
    fetchCartItems(); // Fetch cart items when the page loads
  }, []); // Empty dependency array to prevent infinite loop

  if (isLoading) {
    return (
      <div className="bg-gray-50 py-56">
        <div className="max-w-7xl mx-auto px-4 text-center">
          <div className="flex justify-center">
            <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-yellow-500"></div>
          </div>
          <p className="mt-4 text-gray-600">Loading your cart...</p>
        </div>
      </div>
    );
  }

  // Removed the error display and always showing empty cart when there are no items
  if (cartItems.length === 0) {
    return (
      <div className="bg-gray-50 py-52">
        <div className="max-w-7xl mx-auto px-4">
          {/* Using standard Tailwind classes for the container */}
          <div
            style={{ width: 624 }}
            className="text-center mx-auto border border-yellow-500 p-16 rounded-2xl"
          >
            <div className="">
              <h1
                style={{ fontFamily: "Krona One" }}
                className="text-4xl font-bold text-gray-900"
              >
                Your Cart is empty
              </h1>
              <p className="text-gray-600 text-lg mt-16">
                Browse our events and resources to get started!
              </p>
              <div className="flex justify-center gap-6 pt-12">
                <button
                  onClick={() => navigate("/events")}
                  className="p-2 bg-yellow-600 text-white rounded-lg hover:bg-yellow-700 transition flex items-center gap-2"
                >
                  Browse our Events
                </button>
                <button
                  onClick={() => navigate("/resources")}
                  className="p-2 bg-yellow-500 text-white rounded-lg hover:bg-yellow-700 transition flex items-center gap-2"
                >
                  Browse our Resources
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  // Calculate if cart has any paid items
  const hasOnlyFreeItems = cartItems.every(
    (item) => item.price === "Free" || item.price === "free"
  );

  return (
    <div className="min-h-screen bg-gray-50">
      {/* Header Section */}
      <div className="bg-white border-b pt-32">
        <div className="max-w-7xl mx-auto px-4 py-8">
          <h1 className="text-3xl font-bold text-gray-900">Shopping Cart</h1>
          <p className="text-gray-600 mt-2">
            {cartItems.length} items in your cart
          </p>
        </div>
      </div>

      <div className="max-w-7xl mx-auto px-4 py-12">
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
          {/* Cart Items */}
          <div className="lg:col-span-2 space-y-6">
            <div className="bg-white rounded-xl shadow-sm p-6 space-y-6">
              {cartItems.map((item) => (
                <div
                  key={item.id}
                  className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-4 pb-6 border-b border-gray-100 last:border-0 last:pb-0"
                >
                  <div className="flex flex-col sm:flex-row justify-between w-full gap-4">
                    {/* Left side - Item details */}
                    <div className="space-y-1">
                      <h3 className="text-left font-medium text-gray-900 text-lg">
                        {item.title}
                      </h3>
                      {item.type === "event" && item.date && item.time && (
                        <p className="text-left text-gray-500">
                          {item.date}, {item.time}
                        </p>
                      )}
                      {item.type === "event" && item.location && (
                        <p className="text-left text-gray-500">
                          {item.location}
                        </p>
                      )}
                      {item.type === "resource" && item.exam && (
                        <p className="text-left text-gray-500">
                          Exam board: {item.exam}
                        </p>
                      )}
                      <div className="flex">
                        <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-yellow-100 text-yellow-800">
                          {item.type === "resource" ? "Resource" : "Event"}
                        </span>
                      </div>
                    </div>

                    {/* Right side - Pricing and controls */}
                    <div
                      style={{ width: "243px" }}
                      className="flex items-center gap-6"
                    >
                      <div className="flex flex-col items-center gap-1">
                        <div className="flex items-center gap-3 bg-gray-50 rounded-lg px-3 py-1">
                          <button
                            onClick={() =>
                              item.type === "resource"
                                ? updateQuantity(item.id, item.quantity - 1)
                                : updateAttendees(item.id, item.attendees - 1)
                            }
                            className="text-gray-500 hover:text-gray-700 transition"
                          >
                            <Minus className="w-4 h-4" />
                          </button>
                          <span className="w-8 font-medium">
                            {item.type === "resource"
                              ? item.quantity
                              : item.attendees}
                          </span>
                          <button
                            onClick={() =>
                              item.type === "resource"
                                ? updateQuantity(item.id, item.quantity + 1)
                                : updateAttendees(item.id, item.attendees + 1)
                            }
                            className="text-gray-500 text-left hover:text-gray-700 transition"
                          >
                            <Plus className="w-4 h-4" />
                          </button>
                        </div>
                        <p className="text-left text-sm text-gray-500">
                          {item.type === "resource" ? "Quantity" : "Attendees"}
                        </p>
                      </div>

                      <div className="flex flex-col text-left gap-1">
                        <p className="font-medium text-lg text-gray-900">
                          {item.price === "Free" ? "Free" : `£${item.price}`}
                        </p>
                        <p className="text-left text-sm text-gray-500">
                          {item.price === "Free" || item.price === "free"
                            ? "Subtotal: Free"
                            : `Subtotal: ${
                                parseFloat(
                                  item.price.replace(/[^0-9.-]+/g, "")
                                ) *
                                  (item.type === "resource"
                                    ? item.quantity
                                    : item.attendees) >
                                0
                                  ? `£${(
                                      parseFloat(
                                        item.price.replace(/[^0-9.-]+/g, "")
                                      ) *
                                      (item.type === "resource"
                                        ? item.quantity
                                        : item.attendees)
                                    ).toFixed(2)}`
                                  : "Free"
                              }`}
                        </p>
                      </div>

                      <button
                        onClick={() => removeFromCart(item.id)}
                        className="text-gray-400 hover:text-red-500 transition"
                      >
                        <Trash2 className="w-5 h-5" />
                      </button>
                    </div>
                  </div>
                </div>
              ))}
            </div>

            {/* Add More Section */}
            <div className="bg-white rounded-xl shadow-sm p-6">
              <h3 className="text-lg font-medium text-gray-900 mb-4">
                Add more items
              </h3>
              <div className="flex gap-4">
                <button
                  onClick={() => navigate("/events")}
                  className="flex-1 py-3 px-4 bg-yellow-50 text-yellow-700 rounded-lg hover:bg-yellow-100 transition text-sm font-medium"
                >
                  Browse our Events
                </button>
                <button
                  onClick={() => navigate("/resources")}
                  className="flex-1 py-3 px-4 bg-yellow-50 text-yellow-700 rounded-lg hover:bg-yellow-100 transition text-sm font-medium"
                >
                  Browse our Resources
                </button>
              </div>
            </div>
          </div>

          {/* Summary */}
          <div className="lg:row-start-1 lg:col-start-3">
            <div className="bg-white rounded-xl shadow-sm p-6 sticky top-4">
              <h2 className="text-xl font-bold text-gray-900 mb-6">
                Order summary
              </h2>
              <div className="space-y-4">
                <div className="flex justify-between border-t pt-4">
                  <span className="text-lg font-medium">Total</span>
                  <span className="text-2xl font-bold text-yellow-600">
                    {hasOnlyFreeItems
                      ? "Free"
                      : `£${calculateTotal().toFixed(2)}`}
                  </span>
                </div>

                {checkoutError && (
                  <p className="text-sm text-red-500 text-center">
                    {checkoutError}
                  </p>
                )}

                <button
                  onClick={handleDirectCheckout}
                  disabled={checkoutLoading}
                  className={`w-full ${
                    checkoutLoading
                      ? "bg-yellow-400"
                      : "bg-yellow-600 hover:bg-yellow-700"
                  } text-white py-4 rounded-lg transition flex items-center justify-center gap-2 font-medium`}
                >
                  {checkoutLoading ? (
                    <>
                      <div className="animate-spin rounded-full h-5 w-5 border-b-2 border-white"></div>
                      Processing...
                    </>
                  ) : (
                    <>
                      Complete purchase
                      <ArrowRight className="w-5 h-5" />
                    </>
                  )}
                </button>

                {!hasOnlyFreeItems && (
                  <p className="text-sm text-gray-500 text-center mt-4">
                    We'll process your order immediately
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
