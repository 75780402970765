import React from "react";
import img from "../../Assets/Images/Privacy policy-min.jpg";
import "./style.css";
import GoToTopButton from "../GotoTop";

const PrivacyPolicy = () => {
  return (
    <div>
      {" "}
      <section className="c-pageBanner c-pageBanner--privacy sectionContainer1">
        <div className="c-pageBanner__backgroundOverlay"></div>
        <div className="c-pageBanner__container responsivePadding">
          <div className="c-pageBanner__content">
            <h1 className="c-pageBanner__heading">Privacy policy</h1>
          </div>
          <img src={img} alt="Resources banner" className="c-pageBanner__img" />
        </div>
      </section>
      <section className="sectionContainer1">
        <div className="text-left">
          {/* <h1 className="text-2xl font-bold mb-6">Privacy Policy</h1> */}

          <p className="mb-4">
            This Privacy policy, together with our Cookies Policy, sets out the
            ways in which LEARNOGIC LIMITED (we, us, our) collects, uses and
            shares your personal information in connection with our publishing
            business. It also explains your rights to access or change your
            personal information.
          </p>

          <p className="mb-4">
            This policy applies to the processing of your personal information
            and personally identifiable information, which refers to information
            that can be used on its own or with other data to identify, contact,
            or locate a person (for example, your name or an online identifier)
            ("personal data").
          </p>

          <p className="mb-4">
            For example, if you provide feedback, write a review, contribute to
            a blog, or provide other user-generated content, it may include
            personal data. We take your privacy seriously and are committed to
            protecting your information. We will take care in the use and
            handling of your personal data.
          </p>

          <h2 className="text-xl font-semibold mt-6 mb-3">
            Products and services
          </h2>
          <p className="mb-4">
            Learnogic publishes educational products and provides educational
            services (collectively "a/our Service/s" and individually "a/our
            product/s" and "a/our digital platform/s"). The way Learnogic
            collects and processes your personal data differs depending on how
            you interact with us and the type of service that you are using.
          </p>

          <h2 className="text-xl font-semibold mt-6 mb-3">Users</h2>
          <p className="mb-4">
            The way we process your personal data also varies depending on who
            you are, for example, whether you are a teacher, parent, learner,
            customer, tutor, or content contributor such as authors and
            illustrators.
          </p>

          <p className="mb-4">
            In the case of most processing activities described, we act as a
            data controller for your personal data as we decide how your
            personal data is used and protected. That said, if you are a learner
            using a product or service provided by us via your school or other
            educational institution (your "Educational Institution"), we act as
            a data processor for your personal data. This is the case where we
            process your data on behalf of your Educational Institution (which
            is the data controller for your personal data) as part of providing
            a service to your Educational Institution. The terms and conditions
            for our services provide further required details about our data
            processing activities when we are acting as a data processor.
          </p>

          <p className="mb-4">
            Separately and in relation to specific processing activities related
            to research and product development, we act as a data controller for
            the data we collect when you use our Services. We provide more
            details on this in Children's Use of Our Services – General and How
            We Use Your Personal Data.
          </p>

          <p className="mb-4">
            If you are a learner using any of our Services through your
            Educational Institution, or a child reading this Privacy policy,
            please read Children's Use of Our Services – for Learners of this
            Privacy policy for further details on how we use your information.
          </p>

          <p className="mb-4">
            If you are a parent or guardian and you wish to obtain further
            information on how Learnogic processes the personal data of children
            as part of its Services, please read Children's Use of Our Services
            – General of this Privacy policy.
          </p>

          <h2 className="text-xl font-semibold mt-6 mb-3">
            Children's use of our services
          </h2>

          <h3 className="text-lg font-medium mt-4 mb-2">Students</h3>
          <p className="mb-2">
            Are you under 18 and want to buy a product or subscribe to our
            platform?
          </p>
          <ul className="list-disc pl-8 mb-4">
            <li className="mb-1">
              Please do not use the following Learnogic website without the
              supervision of your parent, guardian or teacher:
              www.learnogic.com.
            </li>
            <li className="mb-1">
              Your parent, guardian or teacher will need to buy Learnogic
              products or services on your behalf (e.g. resources or a
              subscription).
            </li>
          </ul>

          <p className="mb-2">
            Are you using our digital platforms provided by your school?
          </p>
          <ul className="list-disc pl-8 mb-4">
            <li className="mb-1">
              Learnogic will process information about you that your school
              provides to us so you can use the digital platforms.
            </li>
            <li className="mb-1">
              E.g. if you do a test on a digital platform or respond to a
              questionnaire, then your answers and details about you (like your
              name and birthday) are stored by Learnogic so that the school has
              a record of your answers.
            </li>
            <li className="mb-1">
              We store this type of information when your school uses our
              services to help you with your studies.
            </li>
            <li className="mb-1">
              We also use some of your information if we need it for research
              and development purposes, including to create educational
              resources and reports based on the test results on our digital
              platforms.
            </li>
            <li className="mb-1">
              Generally, we keep your information for as long as your school
              needs it. After that, we either delete it completely or remove
              identifying details.
            </li>
          </ul>

          <p className="mb-2">Do you have questions?</p>
          <ul className="list-disc pl-8 mb-4">
            <li className="mb-1">
              Speak to your parent, guardian or teacher if you don't understand
              anything in this Privacy policy.
            </li>
            <li className="mb-1">
              We have an agreement with your school and follow its instructions
              when we use information about you. You can find out more about
              this in your school's privacy policy.
            </li>
            <li className="mb-1">
              Speak with your teacher if you have any other questions or if you
              are unhappy about anything related to your use of a Learnogic
              service.
            </li>
          </ul>

          <h3 className="text-lg font-medium mt-4 mb-2">General</h3>
          <p className="mb-4">
            Where any Learnogic services are provided to an Educational
            Institution and personal data belonging to a learner is shared with
            us so that learners can access our Services, the learner's
            Educational Institution continues to be the data controller for the
            learner's personal data as described above.
          </p>

          <p className="mb-4">
            In such cases we rely on the Educational Institution (i.e. the data
            controller) to instruct and/or supervise their learners, and keep
            parents or guardians informed (where necessary). Please consult your
            Educational Institution's privacy policy for more information. Where
            we act as a data processor for an Educational Institution, we will
            process learners' personal data as needed to provide our Services to
            the Educational Institution in accordance with their instructions.
            For example, we process learners' personal data for the purposes of:
          </p>

          <ul className="list-disc pl-8 mb-4">
            <li className="mb-1">supporting their learning;</li>
            <li className="mb-1">marking assessments;</li>
            <li className="mb-1">recording assessment results; and</li>
            <li className="mb-1">
              monitoring and reporting on their progress to their Educational
              Institution.
            </li>
          </ul>

          <p className="mb-4">
            We will not give learners' personal data to any third parties
            without authorisation of your Educational Institution in these
            circumstances. Where possible we will de-identify data by removing
            the personal data of learners from the data we are processing or by
            replacing it. In some cases the data we use will be pseudonymous and
            in others it will be anonymous (together "de-identified data").
            Learnogic uses de-identified data for the following purposes as a
            separate data controller:
          </p>

          <ul className="list-disc pl-8 mb-4">
            <li className="mb-1">
              standardisation and product development purposes;
            </li>
            <li className="mb-1">benchmarking purposes;</li>
            <li className="mb-1">
              quality assurance and impact assessment purposes; and
            </li>
            <li className="mb-1">
              research purposes, e.g. to prepare aggregated and anonymised
              reporting on trends, academic achievement, performance indicators,
              and attitudes to learning in educational institutions nationwide
              or internationally.
            </li>
          </ul>

          <p className="mb-4">
            Reports and products we create for the above purposes do not contain
            any information that would enable any third party recipient to
            contact, locate or identify learners or individual Educational
            Institutions. You can find more details about this in How We Use
            Your Personal Data.
          </p>

          <h2 className="text-xl font-semibold mt-6 mb-3">
            What personal data we collect about you
          </h2>
          <h3 className="text-lg font-medium mt-4 mb-2">Provided by you</h3>

          <p className="mb-4">
            We collect personal data from you directly when you:
          </p>
          <ul className="list-disc pl-8 mb-4">
            <li className="mb-1">set up an account with us;</li>
            <li className="mb-1">
              subscribe to or access our services (including taking part in
              trials);
            </li>
            <li className="mb-1">order and purchase our products;</li>
            <li className="mb-1">
              contact us for customer support or technical assistance with our
              services;
            </li>
            <li className="mb-1">
              subscribe to our newsletters, or otherwise request access to
              marketing materials related to our services;
            </li>
            <li className="mb-1">
              interact or correspond with us by letter, phone, SMS, email or via
              our website;
            </li>
            <li className="mb-1">
              help us with market research, for instance focus groups or
              surveys;
            </li>
            <li className="mb-1">
              visit our e-commerce websites or use our services;
            </li>
            <li className="mb-1">
              sign up to attend events, including conferences and webinars that
              we host for professional development and revisions purposes, or
              when you otherwise interact with Learnogic representatives in
              person;
            </li>
            <li className="mb-1">
              enter into a contract with us to provide content for any of our
              products or services;
            </li>
            <li className="mb-1">submit permission requests;</li>
            <li className="mb-1">
              apply for electronical materials for visually impaired and print
              disabled materials; or
            </li>
            <li className="mb-1">participate in recorded video conferences.</li>
          </ul>

          <p className="mb-4">
            During your interaction with us in connection with our Services, you
            may provide information to us, such as:
          </p>

          <ul className="list-disc pl-8 mb-4">
            <li className="mb-1">
              your personal contact details, such as your name, personal email
              address, phone number, residential address;
            </li>
            <li className="mb-1">
              your professional contact details, such as your job title, school
              or other educational institution contact address, or work email
              address;
            </li>
            <li className="mb-1">
              if you have ordered a product or service from us – your payment
              and delivery details;
            </li>
            <li className="mb-1">
              if you are a learner – your name, school email address, date of
              birth, information contained in questionnaires and assessments you
              complete via our Services;
            </li>
            <li className="mb-1">
              if you are a parent/carer – your name and relationship to learner;
            </li>
            <li className="mb-1">
              if you have created an account with us – your log-in details
              including passwords;
            </li>
            <li className="mb-1">
              if you are providing content for our products or digital platforms
              – information about your academic and work history, references and
              any other information you may choose to supply (including your
              financial details if we will be paying you);
            </li>
            <li className="mb-1">
              if you have signed up for newsletters, eUpdates or other marketing
              materials – details about the subjects or our Services that you
              are interested in;
            </li>
            <li className="mb-1">
              if you have corresponded with us (e.g. by email, customer support
              query, survey response or via our website) then details of your
              correspondence, query or response;
            </li>
            <li className="mb-1">
              video or voice recordings when you participate in recorded
              webinars or conferences; or
            </li>
            <li className="mb-1">Social media names.</li>
          </ul>

          <p className="mb-4">
            We also collect certain types of personal data automatically when
            you use our digital platforms and our e-commerce website and apps.
            We process information about how you use our digital platforms,
            website and apps and the device that you use to access them. This
            includes your IP address, geographical location, device information
            (such as your hardware model, mobile network information, unique
            device identifiers), browser type, referral source, length of visit
            to the website, number of page reviews, the search queries you make
            on the website, actions you take on the website (such as adding
            items to your shopping basket or beginning the check-out process)
            and similar information. This information may be collected by Google
            Analytics on our behalf and by other apps and plugins provided by
            third parties (such as Meta) using cookies and similar technology
            that we incorporate into our website. To opt out of being tracked by
            Google Analytics across all websites, visit:
            http://tools.google.com/dlpage/gaoptout.
          </p>

          <h3 className="text-lg font-medium mt-4 mb-2">
            Special category data
          </h3>
          <p className="mb-4">
            You may provide us with more sensitive information, such as details
            about your racial or ethnic origin, political opinions, religious,
            philosophical or similar beliefs, trade union membership, genetics,
            biometrics, health, sexual life, sexual orientation or about
            criminal offences or proceedings (some of which is defined as
            'special category data' under data protection laws) (Sensitive
            Information).
          </p>

          <p className="mb-4">
            Sensitive Information may be processed by us on behalf of
            Educational Institutions. We do not generally use Sensitive
            Information for our own purposes. However, we may process Sensitive
            Information for our own purposes in limited circumstances – for
            example, where necessary for research purposes, to help us ensure
            equality of opportunity or treatment or where it is necessary for
            things like public health. We process and manage Sensitive
            Information differently in accordance with data protection laws and
            we or your Educational Institution will explain further before
            collecting such data.
          </p>

          <h3 className="text-lg font-medium mt-4 mb-2">
            Changes to your personal details
          </h3>
          <p className="mb-4">
            It is important that the personal data we hold about you is accurate
            and current. Please keep us informed if your personal data changes
            during your relationship with us (e.g. if you have new contact
            details).
          </p>

          <h3 className="text-lg font-medium mt-4 mb-2">Third Party Links</h3>
          <p className="mb-4">
            Our website may include links to third party websites, plug-ins and
            applications. By clicking on those links or enabling these
            connections you may allow third parties to collect or share data
            about you. We do not control these websites and are not responsible
            for their privacy policy. Please ensure that you read the privacy
            policy on any such external websites.
          </p>

          <h3 className="text-lg font-medium mt-4 mb-2">
            Provided to us by third parties
          </h3>
          <p className="mb-4">
            In certain circumstances, we receive your personal data from third
            parties. For example, we receive information:
          </p>

          <ul className="list-disc pl-8 mb-4">
            <li className="mb-1">
              from third party providers – to verify information provided by you
              in connection with any content you submit to us for publication
              (e.g. where we use third-party databases or websites to confirm
              your publication history);
            </li>
            <li className="mb-1">
              from third parties – if they are referring you to us for
              publication (e.g. if you are the co-author of a manuscript, your
              co-author will be required to provide us with information about
              you, or if you have a literary agent making submissions on your
              behalf, your agent will provide us with information about you);
            </li>
            <li className="mb-1">
              from your colleagues – if you are added as a teacher, teacher
              administrator or an access co-ordinator user to one of our digital
              platforms
            </li>
            <li className="mb-1">
              from Educational Institutions – when they are arranging access for
              learners to our digital platforms, details of learners, including
              names, school, school email address, country, age, date of birth,
              gender, traveller status, first language, Unique Pupil Number,
              pupil premium status, SEN status and information contained in
              questionnaires you complete about learners via our Services;
            </li>
            <li className="mb-1">
              from a parent/carer – details of learners, including name, date of
              birth and information contained in questionnaires you complete
              about learners via our Services;
            </li>
            <li className="mb-1">
              by subscribing to online secure password protected databases of
              education data from The Education Company Ltd and ISC Research
              which contains up to date information about schools and
              institutions and their teaching staff, including professional
              contact details for the UK and internationally respectively;
            </li>
            <li className="mb-1">
              if you give permission for us to contact you to a bookseller or
              other third party running a competition or other event involving
              one of our books or authors; or
            </li>
            <li className="mb-1">
              when we acquire rights from another publisher.
            </li>
          </ul>

          <h2 className="text-xl font-semibold mt-6 mb-3">
            How we use your personal data
          </h2>
          <p className="mb-4">
            We use your personal data lawfully. We do not sell your personal
            data to third parties. However, we may share your personal data as
            set out in 'When We Share Your Personal Data'. We will only process
            your personal data where we have a legal basis to do so.
          </p>

          <p className="mb-4">
            The legal basis will depend on the purposes for which we have
            collected and used your personal data. The details of how we use
            your personal data and the legal bases for our use are set out in
            the table below:
          </p>

          <h3 className="text-lg font-medium mt-4 mb-2">
            Performance of a contract with you
          </h3>
          <p className="mb-4">
            Where necessary to perform the contract we are about to enter into
            or have entered into with you. (E.g. where you have purchased a
            Service from us and we need to use your contact details and payment
            information in order to process your order and provide the Service
            to you.)
          </p>

          <p className="mb-2">We may use your personal data to:</p>
          <ul className="list-disc pl-8 mb-4">
            <li className="mb-1">administer your account with us;</li>
            <li className="mb-1">
              process your requests or orders, for example, to purchase products
              or our Services on behalf of an Educational Institution or on your
              own behalf as the case may be;
            </li>
            <li className="mb-1">
              provide customer service and support to you or your Educational
              Institution; and
            </li>
            <li className="mb-1">
              if you are an author, illustrator or other licensor to administer
              your publishing contract, including receipt, review, editing,
              production, marketing of your content and payment of your
              royalties or fees.
            </li>
          </ul>

          <h3 className="text-lg font-medium mt-4 mb-2">
            Legitimate business interests
          </h3>
          <p className="mb-4">
            Where necessary to conduct our business and pursue our legitimate
            interests (such as understanding our customers and operating our
            business effectively), provided that this is done in a legitimate
            way which does not unduly affect your privacy and other rights.
          </p>

          <p className="mb-4">
            Where we process your information for our legitimate interests, we
            make sure that we take into account any potential impact that such
            use may have on you, particularly where we process children's
            personal data. Our legitimate interests do not automatically
            override yours and we won't use your information if we believe your
            interests should override ours, unless we have other grounds to do
            so (such as your consent or a legal obligation). If you have any
            concerns about our processing, you have rights and choices which
            include the right to object (please see Your Rights and Choices).
          </p>

          <p className="mb-2">We may use your personal data to:</p>
          <ul className="list-disc pl-8 mb-4">
            <li className="mb-1">
              contact you about submissions you made or content you provided to
              us;
            </li>
            <li className="mb-1">
              respond to your queries, correspondence and complaints;
            </li>
            <li className="mb-1">
              send you marketing via social channels and post;
            </li>
            <li className="mb-1">create our educational analysis tools;</li>
            <li className="mb-1">
              communicate service messages to you (e.g. providing information
              about our products which you have bought or services you have
              subscribed to);
            </li>
            <li className="mb-1">
              support your teaching and use of any digital platform products;
            </li>
            <li className="mb-1">de-identify personal data;</li>
            <li className="mb-1">
              prepare aggregated benchmarks for the purpose of building
              educational products and performance indicators;
            </li>
            <li className="mb-1">
              carry out aggregated and anonymised research about general
              engagement with our e-commerce website to help us to provide the
              right kinds of products and services to our website users;
            </li>
            <li className="mb-1">
              carry out research to produce aggregated and anonymised reporting
              about educational trends, academic achievement and wellbeing in
              educational institutions nationwide and internationally, which may
              be shared openly or sold for profit;
            </li>
            <li className="mb-1">assess the quality of our Services;</li>
            <li className="mb-1">
              obtain feedback from you in surveys relating to our Services and
              other educational topics;
            </li>
            <li className="mb-1">
              personalise, enhance, modify or otherwise improve the Services
              and/or communications that we provide to you;
            </li>
            <li className="mb-1">
              analyse your engagement with our communications;
            </li>
            <li className="mb-1">
              detect and prevent fraud and unauthorised access or illegal
              activity;
            </li>
            <li className="mb-1">
              improve security and optimisation of our network sites and
              Services including trouble shooting, updating, testing and
              software development and support;
            </li>
            <li className="mb-1">
              audit and ensure that the data we are storing about individuals is
              accurate and up to date;
            </li>
            <li className="mb-1">
              analyse usage and engagement analytics from our e-commerce site
              and digital platforms to improve the user experience; and
            </li>
            <li className="mb-1">
              measure or understand the effectiveness of advertising.
            </li>
          </ul>

          <h3 className="text-lg font-medium mt-4 mb-2">Consent</h3>
          <p className="mb-4">
            We rely on consent only where we have obtained your active agreement
            to use your personal data for a specified purpose, for example if
            you subscribe to a newsletter or to receive certain marketing from
            us. You can withdraw your consent at any time, including by clicking
            the 'unsubscribe' link at the bottom of any marketing email we send
            you.
          </p>

          <p className="mb-2">We may use your personal data to:</p>
          <ul className="list-disc pl-8 mb-4">
            <li className="mb-1">
              send you marketing communications (including our newsletters and
              eUpdates) about our Services, activities, surveys or other matters
              that we feel may be of interest or use to you (and those of other
              Hachette UK companies that we believe may interest you);
            </li>
            <li className="mb-1">
              share your information with third parties such as authors,
              co-publishers or marketing partners so that they can contact you
              with information that might interest you (as described in When We
              Share Your Personal Data); and
            </li>
            <li className="mb-1">
              collect data using non-essential cookies and similar technology to
              help us analyse your use of our websites and our apps.
            </li>
          </ul>

          <h3 className="text-lg font-medium mt-4 mb-2">
            Compliance with a legal obligation
          </h3>
          <p className="mb-4">
            Where we are subject to a legal obligation and need to use your
            personal information in order to comply with that obligation.
          </p>

          <p className="mb-2">We may use your personal data to:</p>
          <ul className="list-disc pl-8 mb-4">
            <li className="mb-1">
              operate a safe and lawful business or comply with another legal
              obligation; and
            </li>
            <li className="mb-1">
              comply with our policies and procedures or enforce our legal
              rights, or to protect the rights, property or safety of our
              employees.
            </li>
          </ul>

          <h2 className="text-xl font-semibold mt-6 mb-3">
            Who can see your personal data
          </h2>
          <p className="mb-4">
            Your information may be processed by our staff or by the staff of
            third parties we work with to deliver our business. Processing of
            personal data can mean any activity that involves the use of
            information about someone that can identify them. All uses, for
            example, obtaining, recording, storing, disclosing, organising,
            retrieving, deleting and destroying are types of data processing. We
            take measures to ensure that third parties processing your
            information on our behalf are acting lawfully in accordance with our
            instructions and are subject to appropriate confidentiality
            requirements. We also have appropriate technical and organisational
            safeguards in place in our company and with third party processors
            to protect your information.
          </p>

          <p className="mb-2">
            Third party processors of your information include:
          </p>
          <ul className="list-disc pl-8 mb-4">
            <li className="mb-1">
              our digital platform hosts and operators, IT support providers,
              database operators, website analytics providers and software
              developers;
            </li>
            <li className="mb-1">
              our marketing or publicity service providers;
            </li>
            <li className="mb-1">
              our editorial and production service providers, peer-reviewers,
              third-party manuscript readers;
            </li>
            <li className="mb-1">
              Nexonetics which manages our customer relationship system to
              ensure up to date and accurate contact details for teachers,
              schools and customers and purchase records are maintained;
            </li>
            <li className="mb-1">
              our royalty system software and royalty statements printing
              providers;
            </li>
            <li className="mb-1">specialist education research partners;</li>
            <li className="mb-1">
              our financial services and payment service provider;
            </li>
            <li className="mb-1">
              our warehousing and delivery service providers;
            </li>
            <li className="mb-1">
              our auditors, technical consultants and legal advisors; and
            </li>
            <li className="mb-1">our fraud detection service providers.</li>
          </ul>

          <h2 className="text-xl font-semibold mt-6 mb-3">
            When we share your personal data
          </h2>
          <p className="mb-4">
            Where we share your information with third parties it is subject to
            usual requirements listed in earlier sections of this Privacy
            policy.
          </p>

          <p className="mb-2">
            We will share your information with the following third parties:
          </p>
          <ul className="list-disc pl-8 mb-4">
            <li className="mb-1">
              selected authors, speakers, event organisers, marketing or
              publicity partners and/or market research partners;
            </li>
            <li className="mb-1">
              specialist education research partners to help us create our
              educational analysis tools and products;
            </li>
            <li className="mb-1">
              any prospective seller or buyer of businesses or assets, only in
              the event that we decide to acquire, transfer or sell any business
              or assets; and
            </li>
            <li className="mb-1">
              any other third parties (including legal or other advisors,
              regulatory authorities, courts and government agencies) where
              necessary to enable us to enforce our legal rights, or to protect
              the rights, property or safety of our employees or where such
              disclosure may be permitted or required by law or where we have a
              legal obligation to do so.
            </li>
          </ul>

          <h2 className="text-xl font-semibold mt-6 mb-3">
            How we look after your personal data
          </h2>
          <p className="mb-4">
            We look for opportunities to minimise the amount of personal
            information we hold about you. Where appropriate we de-identify your
            information. We use appropriate technological and operational
            security measures to protect your information against any
            unauthorised access or unlawful use, such as:
          </p>

          <ul className="list-disc pl-8 mb-4">
            <li className="mb-1">
              ensuring the physical security of our offices, warehouses or other
              sites;
            </li>
            <li className="mb-1">
              ensuring the physical and digital security of our equipment and
              devices by using appropriate password protection and encryption;
            </li>
            <li className="mb-1">
              maintaining data protection policies for, and delivering data
              protection training to, our employees;
            </li>
            <li className="mb-1">
              limiting access to your personal information to those in our
              company who need to use it in the course of their work and ensure
              it is kept behind a user password verification system; and
            </li>
            <li className="mb-1">
              completing due diligence on our third party processors and any
              other third parties with which we share personal data, and
              ensuring that there are appropriate contractual protections in
              place in our contracts with these third parties.
            </li>
          </ul>

          <h3 className="text-lg font-medium mt-4 mb-2">
            Protection of Personal Data
          </h3>
          <p className="mb-4">
            You are responsible for ensuring that personal data is transferred
            to us securely. We will take all reasonable precautions to ensure
            that all the personal data that is submitted to us for the purposes
            above remains secure and have procedures to protect the storage and
            disclosure of personal data. You must ensure your log-in details and
            passwords are not shared and are kept securely.
          </p>

          <p className="mb-4">
            You must keep your password and any other authentication information
            for our website confidential. If you know or suspect that anyone
            other than you knows your password or any other authentication
            information, you must promptly notify us using the contact details
            in Who We Are.
          </p>

          <h2 className="text-xl font-semibold mt-6 mb-3">
            How long we keep your personal data
          </h2>
          <p className="mb-4">
            We will retain your information for as long as is necessary to
            provide you with the Services that you have requested from us or for
            as long as we reasonably need to retain the information for our
            legitimate interests, such as for the purposes of exercising our
            legal rights or where we are permitted to do so for academic,
            literary expression or research purposes. After this period, it will
            be deleted or in some cases de-identified.
          </p>

          <p className="mb-4">
            We operate a data retention policy and data retention procedures,
            and look to find ways to reduce the amount of information we hold
            about you and the length of time that we need to keep it. For
            example:
          </p>

          <ul className="list-disc pl-8 mb-4">
            <li className="mb-1">
              we archive our email and paper correspondence regularly and
              destroy unnecessary information;
            </li>
            <li className="mb-1">
              we operate a best practice email retention policy requiring
              password protected folders and departmental shared drives to
              provide restricted access to information;
            </li>
            <li className="mb-1">
              we conduct regular access reviews to keep access profiles and
              policies up to date;
            </li>
            <li className="mb-1">
              we delete information collected through marketing channels and
              stored in shared drives every month provided it is no longer in
              use;
            </li>
            <li className="mb-1">
              we conduct periodic reviews and purge cycles of documents in
              accordance with our document retention policy;
            </li>
            <li className="mb-1">
              we retain information relating to orders, refunds and customer
              queries for approximately 7 years;
            </li>
            <li className="mb-1">
              we retain information relating to commercial contracts for
              approximately 7 years after expiration or termination, whichever
              is the sooner;
            </li>
            <li className="mb-1">
              we maintain a suppression list of email addresses of individuals
              who no longer wish to be contacted by us (so that we can comply
              with their wishes we must store this information permanently);
            </li>
            <li className="mb-1">
              we hold test data processed via our digital products with your
              account for the period of your subscription or as long as you
              continue to add test data (unless you ask us to delete it
              earlier). Thereafter, personal data is held for a further 2 or 3
              year period depending on the specific digital product. After that
              it is de-identified unless you have instructed us to delete it;
            </li>
            <li className="mb-1">
              we securely destroy assessment test trialling papers after
              recording learners' scores and returning results to schools and we
              retain de-identified test data in aggregated form for as long as
              we continue to publish the relevant product;
            </li>
            <li className="mb-1">
              we delete questionnaires for our SNAP and Wellbeing products 2
              years after the expiry of your subscription;
            </li>
            <li className="mb-1">
              we delete personal data held on eLibrary at the end of your
              subscription period or free trial; and
            </li>
            <li className="mb-1">
              we destroy CVs and related information from job applicants who
              have not been shortlisted for interview once the recruitment
              process is complete.
            </li>
            <li className="mb-1">
              The shortlisted candidates' CVs and related information are kept
              until after the successful candidate's probation period, which can
              be 6 months from their start date.
            </li>
          </ul>

          <h2 className="text-xl font-semibold mt-6 mb-3">
            International transfers of your information
          </h2>
          <p className="mb-4">Our company is located in the UK.</p>

          <p className="mb-4">
            Whenever we transfer your personal information outside of the UK or
            the European Economic Area (EEA) we will take all steps reasonably
            necessary to ensure that such personal data is transferred in
            compliance with applicable data protection laws. If you are located
            in the UK or the EEA, you may contact us for a copy of the
            safeguards which we have put in place to protect your personal
            information in these circumstances.
          </p>

          <h2 className="text-xl font-semibold mt-6 mb-3">
            Automated decision making
          </h2>
          <p className="mb-4">
            Automated decision-making occurs when your information is used by an
            electronic system to make a decision without human intervention.
            Generally, we do not make automated decisions about you that have a
            significant impact on you. However, some of our Services involve the
            automated marking of tests as described below.
          </p>

          <p className="mb-4">
            Some of our digital platforms use technology to automatically score
            tests. This scoring activity is carried out according to a strict
            marking criteria, set by humans. Depending on the item type, the
            marking criteria will be a binary correct and/or incorrect, for
            instance a multiple-choice question. If a user needs to type a
            fuller free text answer in response to a test question, then the
            scoring will still be based on binary criteria, which will be set
            with a number of rules that indicate an answer is correct (e.g.
            spelling, key words, punctuation). All rules will be set by a human
            and based on how a human would score the answer and may be
            extensive.
          </p>

          <h2 className="text-xl font-semibold mt-6 mb-3">
            Your rights and choices
          </h2>
          <p className="mb-4">
            You have a number of rights under data protection laws in relation
            to your personal data. We set out more details about these rights
            below:
          </p>

          <h3 className="text-lg font-medium mt-4 mb-2">
            Your right to object
          </h3>
          <p className="mb-4">
            You have the right to object to our using your information for
            direct marketing and on the basis of our legitimate interests (refer
            to How We Use Your Personal Data above to see when we are relying on
            our legitimate interests).
          </p>

          <h3 className="text-lg font-medium mt-4 mb-2">
            Your right to withdraw consent
          </h3>
          <p className="mb-4">
            The right to withdraw your consent for our use of your information
            in reliance of your consent (refer to How We Use Your Personal Data
            to see when we are relying on your consent), which you can do by
            contacting us using any of the details in Who We Are.
          </p>

          <h3 className="text-lg font-medium mt-4 mb-2">
            Your other rights and choices
          </h3>
          <p className="mb-4">
            You also have other choices and rights in respect of the information
            that we hold about you, including:
          </p>

          <ul className="list-disc pl-8 mb-4">
            <li className="mb-1">
              the right to request access to the information that we hold about
              you to check that we are acting lawfully;
            </li>
            <li className="mb-1">
              the right to receive a copy of any information we hold about you
              in a structured, commonly-used, machine readable format or in
              another format of your choice;
            </li>
            <li className="mb-1">
              the right to request that we transfer your information to another
              service provider in a structured, commonly used, machine-readable
              format;
            </li>
            <li className="mb-1">
              the right to ask us to correct information we hold about you if it
              is inaccurate or incomplete;
            </li>
            <li className="mb-1">
              the right to ask us, in certain circumstances, to delete
              information we hold about you;
            </li>
            <li className="mb-1">
              the right to ask us, in certain circumstances, to restrict
              processing of your information.
            </li>
          </ul>

          <p className="mb-4">
            You may exercise your rights and choices by contacting us. You can
            also prevent processing for marketing activities by checking certain
            boxes on forms that we use to collect your data to tell us that you
            don't want to be involved in marketing.
          </p>

          <h3 className="text-lg font-medium mt-4 mb-2">
            Your right to complain
          </h3>
          <p className="mb-4">
            Please contact us if you have any questions or are unhappy about the
            way your information is used. We hope we will be able to resolve any
            problems or issues you may have.
          </p>

          <p className="mb-4">
            You also have the right to lodge a complaint about us and our use of
            your information to the UK Information Commissioner's Office
            (https://ico.org.uk/) or the relevant authority in your country of
            work or residence.
          </p>

          <h2 className="text-xl font-semibold mt-6 mb-3">
            Privacy Policy for Job Applicants
          </h2>
          <p className="mb-4">
            This policy applies to people applying for jobs ("you" "your") with
            Learnogic Limited ("company" "we" "us "our"). It sets out the ways
            in which we collect, use and share the personal data (your Personal
            Information) of candidates who apply for jobs with us. It also
            explains what rights you have to access or change your Personal
            Information.
          </p>

          <p className="mb-4">
            We are your Data Controller which means that we decide how your
            Personal Information is held, used and protected. We are required
            under data protection laws to notify you of the information set out
            in this Privacy Notice.
          </p>

          <p className="mb-4">
            This Notice applies to Personal Information of job candidates and
            potential candidates for employment and any recruitment events or
            recruitment programs. It does not apply to any Personal Information
            of employees or independent contractors or authors or customers of
            the Company.
          </p>

          <p className="mb-4">
            It applies to any Personal Information submitted in an application
            by post or in an on-line application such as an application tracking
            system or video interview system or via a third party recruitment
            agency or via any other recruitment solutions, partners and methods
            e.g. LinkedIn.
          </p>

          <p className="mb-4">
            Please read this policy and keep it together with any other privacy
            policy you may be provided with when we collect or process your
            Personal Information.
          </p>

          <p className="mb-4">
            This Privacy policy sets out your rights under all applicable data
            protection and privacy legislation in force from time to time in the
            UK including the retained EU law version of the General Data
            Protection Regulation ((EU) 2016/679) (UK GDPR); the Data Protection
            Act 2018 (DPA 2018) (and regulations made thereunder) and the
            Privacy and Electronic Communications Regulations 2003 (SI
            2003/2426) as amended and the guidance and codes of practice issued
            by the Information Commissioner.
          </p>

          <p className="mb-4">
            We take your privacy and our responsibility to protect your
            information seriously.
          </p>

          <h3 className="text-lg font-medium mt-4 mb-2">
            Compliance with data protection principles
          </h3>
          <p className="mb-4">
            We will comply with all applicable data protection laws.
          </p>

          <p className="mb-4">
            Under the Data Protection Principles contained in the applicable
            data protection laws the Personal Information we hold about you must
            be:
          </p>

          <ul className="list-disc pl-8 mb-4">
            <li className="mb-1">
              used lawfully, fairly and in a transparent way
            </li>
            <li className="mb-1">
              collected only for a valid purpose that has been clearly explained
              to you and not used in a way that is incompatible with these
              purposes
            </li>
            <li className="mb-1">
              relevant to the purposes you have been informed about and limited
              to these purposes
            </li>
            <li className="mb-1">accurate and kept up to date</li>
            <li className="mb-1">
              kept only as long as necessary for the purposes you have been
              informed of
            </li>
            <li className="mb-1">kept securely</li>
          </ul>

          <h3 className="text-lg font-medium mt-4 mb-2">
            What information we collect about you
          </h3>
          <h4 className="text-base font-medium mt-3 mb-2">
            Personal Information
          </h4>
          <p className="mb-4">
            Personal Information means any information about a living individual
            from which that individual can be identified. The information you
            provide to us might include:
          </p>

          <ul className="list-disc pl-8 mb-4">
            <li className="mb-1">
              your name, postal address, email address, phone number, contact
              details;
            </li>
            <li className="mb-1">age range</li>
            <li className="mb-1">nationality</li>
            <li className="mb-1">ethnicity</li>
            <li className="mb-1">religion or belief</li>
            <li className="mb-1">sexual orientation</li>
            <li className="mb-1">caring responsibilities</li>
            <li className="mb-1">
              disability/long-term health condition and their affect
            </li>
            <li className="mb-1">neurodiversity</li>
            <li className="mb-1">gender</li>
            <li className="mb-1">trans status</li>
            <li className="mb-1">socioeconomic diversity</li>
            <li className="mb-1">secondary school education</li>
            <li className="mb-1">highest level of education</li>
            <li className="mb-1">university attended (if applicable)</li>
            <li className="mb-1">parents level of education</li>
            <li className="mb-1">
              occupation of main household earner when you were 14
            </li>
            <li className="mb-1">access to free school meals</li>
            <li className="mb-1">regionality</li>
            <li className="mb-1">where you grew up</li>
            <li className="mb-1">
              details of your educational history, qualifications, skills,
              experience and employment history;
            </li>
            <li className="mb-1">
              test results or psychometric profiles of any assessments you agree
              to take in relation to the application for a job with our company
            </li>
            <li className="mb-1">bank account details;</li>
            <li className="mb-1">
              your current salary or remuneration and benefits, leaving date and
              reason for leaving (where provided by candidate directly or
              indirectly via a third party recruiter or previous employer);
            </li>
            <li className="mb-1">
              proof of and documents showing your entitlement to work in the UK;
            </li>
            <li className="mb-1">
              your status in relation to the EU Settlement Scheme.
            </li>
            <li className="mb-1">
              location of previous employment and workplace;
            </li>
            <li className="mb-1">copy of passport and driving license;</li>
            <li className="mb-1">
              recruitment information, CV and cover letters, references or
              previous employers;
            </li>
            <li className="mb-1">photographs;</li>
            <li className="mb-1">
              video interviews or video profiles provided as part of your
              application.
            </li>
          </ul>

          <h4 className="text-base font-medium mt-3 mb-2">
            Special Category Information
          </h4>
          <p className="mb-4">
            You may provide us with more sensitive information, known as Special
            Category ("Sensitive") information which may include details about a
            person's racial or ethnic origin, political opinions, religious,
            philosophical or similar beliefs, trade union membership, genetics,
            biometrics, health, sexual life or sexual orientation.
          </p>

          <p className="mb-4">
            In particular, as part of the online application process you may be
            asked to voluntarily provide Sensitive information relating to your
            nationality, ethnicity, religion or belief, sexual orientation,
            disability/long-term health condition and their affect,
            neurodiversity, gender and trans status (referred to as "D&I Data").
            If you supply this kind of Sensitive information to us, it will be
            treated differently and we will explain why we are collecting it. We
            will only process Sensitive information where you have given your
            explicit consent. Where we process Sensitive data to help us ensure
            equality of opportunity or treatment in our publishing business and
            it is lawful, we may rely on our legitimate interests. See the
            section below on "How we use your information" in relation to
            Sensitive data to find out more about our use of your Sensitive
            information.
          </p>

          <h2 className="text-xl font-semibold mt-6 mb-3">
            Changes to this privacy policy
          </h2>
          <p className="mb-4">
            We may make changes to this Privacy policy from time to time. We
            will post any changes to our website.
          </p>

          <p className="mb-4">This Privacy Policy was updated in March 2025.</p>

          <h2 className="text-xl font-semibold mt-6 mb-3">Cookie policy</h2>
          <h3 className="text-lg font-medium mt-4 mb-2">
            Cookie management on our website
          </h3>
          <p className="mb-4">
            A cookie is a small file of letters and numbers that websites place
            on your computer, mobile phone, table or other device. You can
            manage your cookie preferences for this website at any time by
            choosing "manage preferences".
          </p>

          <h3 className="text-lg font-medium mt-4 mb-2">How we use cookies</h3>
          <p className="mb-4">
            Our websites and apps use cookies to distinguish you from other
            users on our websites. This helps us to provide you with a good
            experience when you browse our websites and apps and allows us to
            improve our sites. We use other cookies for gathering statistics and
            for marketing purposes. We are providing this information to enable
            visitors to the site to understand the different types of cookies
            and how we use them.
          </p>

          <h3 className="text-lg font-medium mt-4 mb-2">Cookies we use</h3>
          <p className="mb-4">
            You can see descriptions of the cookies used on this site and how
            long they last by pressing or clicking on the cookie icon in the
            bottom-right corner of the page and reading the cookie notices for
            each category of cookie.
          </p>

          <p className="mb-4">We use the following cookies:</p>

          <h4 className="text-base font-medium mt-3 mb-2">Essential cookies</h4>
          <p className="mb-4">
            These are required for us to operate our websites and apps. They
            include cookies that permit you to store items in an online shopping
            cart; allow you to log into secure parts of our site or make use of
            e-payment services.
          </p>

          <h4 className="text-base font-medium mt-3 mb-2">
            Analytical or performance cookies
          </h4>
          <p className="mb-4">
            They allow us to recognise you and to count the number of visitors
            to our websites and apps and to see how visitors move around our
            websites and apps. They improve the performance of our websites and
            apps by ensuring that visitors can find what they are searching for
            easily and to speed up your searches.
          </p>

          <p className="mb-4">
            Our websites and apps use third party technologies to help us gather
            aggregate information about the user experience. The information we
            collect allows us to better tailor our web experience to the
            interests of our visitors.
          </p>

          <h4 className="text-base font-medium mt-3 mb-2">
            Google enhanced tracking
          </h4>
          <p className="mb-4">
            To understand better how successful our advertising is in terms of
            our sales, we use enhanced Google tracking. It helps improve the
            accuracy of conversion measurement and may also be used for
            personalising our advertising. It works by sending data from our
            website to Google in a privacy-safe way. It is used for reporting
            and to adjust our marketing to make it more relevant and useful to
            our audience.
          </p>

          <p className="mb-4">
            We may use an email address or phone number, which will is hashed
            locally in your browser meaning your data is never sent over the
            internet in a way others can use it. Hashing is a one-way process,
            once the input is hashed, it cannot be feasibly reversed to obtain
            the original input. Google may use performance and targeting cookies
            in the process, this is something you can control. To opt out of
            being tracked by Google Analytics across all websites, visit
            http://tools.google.com/dlpage/gaoptout.
          </p>

          <h4 className="text-base font-medium mt-3 mb-2">
            Functionality or personalisation cookies
          </h4>
          <p className="mb-4">
            These allow us to recognise you when you return to the website or
            app after your first visit. It helps us to personalise content, to
            greet you by name and remember your preferences (such as language or
            country). They are used for statistical purposes.
          </p>

          <h4 className="text-base font-medium mt-3 mb-2">
            Marketing or targeting cookies
          </h4>
          <p className="mb-4">
            These cookies record your visit to the websites and apps, links you
            have followed and pages you have visited. We will use this
            information to target information on our websites and apps and
            advertisements displayed on the websites and apps more relevant to
            your interests. We may share this information with third parties and
            other social media such as Meta for this purpose. Cookie profiling,
            also called web profiling, is the use of persistent or permanent
            cookies to track a user's overall activity online. When used in
            conjunction with our advertising services, information collected by
            third party cookies may be linked with information about visits to
            multiple sites.
          </p>

          <p className="mb-4">
            In the case of Meta, X and LinkedIn pixels these cookies allow us to
            track user behaviour on our website after being redirected there by
            clicking on one of our advertisements on Facebook, Instagram, X or
            LinkedIn. We will use this information to track and understand the
            actions you take which in turn helps us improve our marketing and
            monitor sales resulting from our advertising. We may share this
            information with third parties such Meta to allow us to send you and
            others targeted marketing via their platforms. For instance, the
            Meta pixel enables us to use our website as a data source for
            creating custom and/or lookalike audiences that can be retargeted
            with advertising across Meta platforms. This type of custom audience
            is made up of Meta users who have viewed a product on our website. A
            lookalike audience is made up of Meta users who are similar to other
            Meta users that have made a purchase on our website.
          </p>

          <p className="mb-4">
            LinkedIn's Insight Tag let us analyse ad performance and understand
            how professionals interact with our site in order to tailor
            campaigns that resonate with our audiences. We may use LinkedIn data
            to target ads more effectively to business professionals or
            audiences based on their profiles or prior site interactions. X's
            tracking pixel enables us to measure interactions that occur after a
            user clicks on an ad from our account. This data helps us monitor
            conversions, such as sales or sign-ups, and optimise future
            campaigns to better target users based on behaviour and engagement.
          </p>

          <h4 className="text-base font-medium mt-3 mb-2">
            Third party cookies
          </h4>
          <p className="mb-4">
            Our websites and apps may include links to third party websites,
            plug-ins and applications. By clicking on those links or enabling
            these connections you may allow third parties to collect or share
            data about you. These third party cookies are likely to be
            analytical, performance or targeting cookies. We do not control
            these websites and are not responsible for their privacy policies –
            we have no control over how third parties use your data. Please
            ensure that you read the privacy policies on any such external
            websites.
          </p>

          <p className="mb-4">
            To deactivate the use of third-party cookies, visit the Storage
            Preference centre (see above for more information about the Storage
            Preference centre).
          </p>

          <h3 className="text-lg font-medium mt-4 mb-2">
            Cookie choices and disabling cookies in browser(s)
          </h3>
          <p className="mb-4">
            You can set your browser to refuse all or some browser cookies or to
            alert you when a website sets or uses cookies. You can disable
            cookies from our site at any time – all modern browsers allow you to
            change your cookies settings. Settings are usually found in the
            'options' or 'preferences' menu of your browser. For further
            guidance see your browser "Help". Disabling cookies may prevent
            areas of a website from working as intended.
          </p>

          <p className="mb-4">
            Most browsers are set to accept cookies by default. However, you can
            remove or reject cookies in your browser's settings. Please be aware
            that such action could affect the availability and functionality of
            the website. Please be aware that these controls are distinct from
            the controls offered on this website.
          </p>
        </div>
      </section>
      <GoToTopButton />
    </div>
  );
};

export default PrivacyPolicy;
