import React, { useEffect, useState } from "react";
import "./style.css";
import Box from "../Dashboard/Box";
import { useNavigate } from "react-router-dom";
import GoToTopButton from "../GotoTop";
import img from "../../Assets/Images/P3. Students-min.jpg";

const Course = () => {
  const [show, setShow] = useState(false);
  const history = useNavigate();
  const handleChange = () => {
    setShow(!show);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const handleRegister = () => {
    window.location.href = "https://demo.nexonetics.com/register/";
  };

  const handleLogin = () => {
    window.location.href = "https://demo.nexonetics.com/my-account/";
  };

  return (
    <div>
      <section className="c-pageBanner c-pageBanner--students sectionContainer1">
        <div className="c-pageBanner__backgroundOverlay"></div>
        <div className="c-pageBanner__container responsivePadding">
          <div className="c-pageBanner__content">
            <h1 className="c-pageBanner__heading">Students</h1>
          </div>
          <img src={img} alt="" className="c-pageBanner__img" />
        </div>
      </section>

      <section style={{}} class="sectionContainer1">
        <div class="c-text c-text--aboutUs responsivePadding">
          <h2 class="c-text__title">
            <span class="first-line">
              Designed by learners. Contributed by senior examiners.
            </span>
            <br />
            Tailored to you.
          </h2>
          <div class="c-text__textContainer">
            <p class="c-text__text">
              At Learnogic, we recognise that learning content is not enough to
              achieve the highest levels and grades. Writing to the demands of,
              and meeting the assessment criteria of, exam boards is often
              challenging. There remains a considerable gap between what the
              students write and what the examiners expect in answers.
            </p>
            <p class="c-text__text">
              With this in mind, we've collaborated with senior examiners and
              created a comprehensive bank of model answers and other valuable
              resources. This allows each student to bridge the gap whilst
              benefitting from greater productivity. It improves written work,
              raises assessment levels, and thus produces best outcomes.
            </p>
            <p class="c-text__text">
              Our platform, therefore, aims to drive student engagement in a
              hybrid learning environment. Every student desires the right tools
              to enhance academic excellence, with the key objective of gaining
              outstanding results. There is a strong demand for accessibility,
              and time-saving solutions, which only Learnogic offers.
            </p>
          </div>
        </div>
      </section>

      <div className="sectionContainer1">
        <section className="c-course responsivePadding">
          <div className="c-course__intro">
            <div className="c-course__content">
              <h2
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
                className="c-course__title"
              >
                GCE Edexcel Economics A{" "}
                <div className="flex gap-4">
                  {/* <button
                    onClick={handleRegister}
                    className="px-6 py-2 border border-yellow-500 text-yellow-500 rounded-md hover:bg-yellow-50 transition-colors"
                  >
                    Register
                  </button> */}
                  <button
                    onClick={handleLogin}
                    style={{ fontSize: 17 }}
                    className="px-4   bg-yellow-500 text-white rounded-md hover:bg-yellow-600 transition-colors"
                  >
                    Get started today
                  </button>
                </div>
              </h2>
              <div className="c-pageBanner__intro">
                Access a range of essays, past exam questions, multiple-choice
                questions and diagrams.
                <br /> <br /> Use our search functionality to directly retrieve
                topics and reveal mark schemes and model answers with one-click.
              </div>
              <div className="c-course__actions">
                {/* <button
                  type="button"
                  className="c-course__button c-course__button--close toggle-button"
                  onClick={handleChange}
                >
                  <img
                    src="https://dilepex-lms.kau.agency/wp-content/themes/wp-dilepex-website/assets/plus-solid.svg"
                    alt="toggle icon"
                    className="c-course__buttonIcon"
                  />
                </button> */}
              </div>
            </div>
          </div>

          <div className="c-course__details expanded">
            {/* <div className="c-course__detailsLine"></div> */}
            <h5 className="c-course__detailsTitle">Course Details</h5>
            {/* <p className="c-course__detailsText">
                This course includes the following key learning elements:
              </p> */}
            <ul className="c-course__list">
              <li>
                Essays provide an in-depth understanding of how to structure
                answers and integrate all the assessment objectives to attain
                top grades.
              </li>
              <li>
                Past exam questions allow self-assessment of written work
                against our exemplars to ensure all the details in the level
                descriptors are covered.
              </li>
              <li>
                Multiple choice questions, through regular retrieval practice,
                enhance learning by checking for understanding and identifying
                misconceptions.
              </li>
              <li>
                Diagrams, if accurately drawn and integrated, enable the
                development of arguments and elevate them to a higher level;
                learn to draw step-by-step.
              </li>
            </ul>
          </div>
        </section>
      </div>
      {/* <section className="sectionContainer">
        <div className="c-iconblks c-iconblks--teal responsivePadding">
          <h2 className="c-iconblks__title c-iconblks__title--blue">
            Coming soon
          </h2>
          <div className="c-iconblks__container c-iconblks__container--quad">
            {[
              {
                src: "https://dilepex-lms.kau.agency/wp-content/themes/wp-dilepex-website/assets/svg/computerTime.svg",
                text: "AQA Business",
              },
              {
                src: "https://dilepex-lms.kau.agency/wp-content/themes/wp-dilepex-website/assets/svg/computerSeminar.svg",
                text: "AQA Economics",
              },
              {
                src: "https://dilepex-lms.kau.agency/wp-content/themes/wp-dilepex-website/assets/svg/atom.svg",
                text: "AQA Psychology",
              },
              {
                src: "https://dilepex-lms.kau.agency/wp-content/themes/wp-dilepex-website/assets/svg/jigsaw.svg",
                text: "AQA Sociology",
              },

              {
                src: "https://dilepex-lms.kau.agency/wp-content/themes/wp-dilepex-website/assets/svg/atom.svg",
                text: "Edexcel Business",
              },
              {
                src: "https://dilepex-lms.kau.agency/wp-content/themes/wp-dilepex-website/assets/svg/jigsaw.svg",
                text: "Edexcel Politics",
              },
              {
                src: "https://dilepex-lms.kau.agency/wp-content/themes/wp-dilepex-website/assets/svg/computerSeminar.svg",
                text: "OCR Religious Studies",
              },
            ].map((item, index) => (
              <div key={index} className="c-iconblks__iconblk">
                <img src={item.src} alt="" />
                <p className="c-iconblks__fixedText">{item.text}</p>
              </div>
            ))}
          </div>
        </div>
      </section> */}
      <section className="sectionContainer1 bgPureWhite">
        <div className="c-iconblks c-iconblks--blue responsivePadding">
          <h2 className="c-iconblks__title c-iconblks__title--teal">
            Learn with us
          </h2>
          <div className="c-iconblks__container c-iconblks__container--triple">
            {[
              {
                src: "https://dilepex-lms.kau.agency/wp-content/themes/wp-dilepex-website/assets/svg/professionalCertification.svg",
                title: "Top level model answers",
              },
              {
                src: "https://dilepex-lms.kau.agency/wp-content/themes/wp-dilepex-website/assets/svg/expertTrainingProviders.svg",
                title: "Senior examiners",
              },
              {
                src: "https://dilepex-lms.kau.agency/wp-content/themes/wp-dilepex-website/assets/svg/subjectSpecificFocus.svg",
                title: "Board specific  focus",
              },
              {
                src: "https://dilepex-lms.kau.agency/wp-content/themes/wp-dilepex-website/assets/svg/learnAtYourOwnPace.svg",
                title: "Learn at your own pace",
              },
              {
                src: "https://dilepex-lms.kau.agency/wp-content/themes/wp-dilepex-website/assets/svg/computerTime.svg",
                title: "Time-saving learning",
              },
              {
                src: "https://dilepex-lms.kau.agency/wp-content/themes/wp-dilepex-website/assets/svg/sharingGoodPractice.svg",
                title: "Sharing good practice",
              },
            ].map((item, index) => (
              <div key={index} className="c-iconblks__iconblk">
                <img src={item.src} alt={item.title} />
                <p className="c-iconblks__contentTitle">{item.title}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      <div className="bgContainer linearBG">
        <Box />
        <div className="sectionContainer buttons-div">
          <section className="sectionContainer">
            <div className="c-cta">
              <div className="c-cta__title">Get in touch</div>
              <p className="c-cta__intro">
                If you require additional information or have any queries,
                please feel free to send us a message.
              </p>
              <button
                className="c-button ctaOrange"
                style={{
                  background: "rgb(66 66 66)",
                  border: "none",
                  color: "#fff",
                }}
                onClick={() => {
                  history("/contact");
                }}
              >
                Contact us
              </button>
            </div>
          </section>
          {/* <div className="div-style">
            {" "}
            <a
              className="c-button ctaOrange"
              style={{
                background: "rgb(32 123 191)",
                border: "none",
                color: "#fff",
                width: "220px",
                borderRadius: "10px",
                marginRight: "35px",
                textDecoration: "none",
              }}
              href="https://demo.nexonetics.com/register/"
            >
              Register
            </a>
            <a
              className="c-button ctaOrange"
              style={{
                background: "rgb(32 123 191)",
                border: "none",
                color: "#fff",
                width: "220px",
                borderRadius: "10px",
                textDecoration: "none",
              }}
              href="https://demo.nexonetics.com/upgrade"
            >
              Login
            </a>
          </div> */}
        </div>
      </div>
      <GoToTopButton />
    </div>
  );
};

export default Course;
