import React, { useState, useEffect } from "react";
import { useCart } from "./CartProvider";
import { useNavigate } from "react-router-dom";
import { CreditCard, ArrowLeft } from "lucide-react";

const CheckoutPage = () => {
  const { cartItems, calculateTotal, clearCart, isLoading } = useCart();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    email: "",
    firstName: "",
    lastName: "",
    address: "",
    city: "",
    postcode: "",
    country: "United Kingdom",
    cardNumber: "",
    expiryDate: "",
    cvv: "",
    cardName: "",
  });
  const [errors, setErrors] = useState({});
  const [isProcessing, setIsProcessing] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);

    // Check authentication
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/login", {
        state: {
          redirectAfterLogin: "/checkout",
          message: "Please log in to complete your purchase",
        },
      });
      return;
    }

    // Check if cart is empty
    if (!isLoading && cartItems.length === 0) {
      navigate("/cart");
    }
  }, [cartItems, navigate, isLoading]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
    // Clear error when user starts typing
    if (errors[name]) {
      setErrors((prev) => ({
        ...prev,
        [name]: "",
      }));
    }
  };

  const validateForm = () => {
    const newErrors = {};

    // Email validation
    if (!formData.email || !/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = "Valid email is required";
    }

    // Required field validation
    ["firstName", "lastName", "address", "city", "postcode"].forEach(
      (field) => {
        if (!formData[field].trim()) {
          newErrors[field] = "This field is required";
        }
      }
    );

    // Card number validation (simple 16 digit check)
    if (
      !formData.cardNumber ||
      !/^\d{16}$/.test(formData.cardNumber.replace(/\s/g, ""))
    ) {
      newErrors.cardNumber = "Valid 16-digit card number required";
    }

    // Expiry date validation (MM/YY format)
    if (
      !formData.expiryDate ||
      !/^(0[1-9]|1[0-2])\/([0-9]{2})$/.test(formData.expiryDate)
    ) {
      newErrors.expiryDate = "Valid expiry date (MM/YY) required";
    }

    // CVV validation (3 or 4 digits)
    if (!formData.cvv || !/^\d{3,4}$/.test(formData.cvv)) {
      newErrors.cvv = "Valid CVV required";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const processPayment = async () => {
    try {
      // Implement API call to process payment
      // For now, we'll just simulate a payment process
      const token = localStorage.getItem("token");

      const response = await fetch("https://api2.made2tech.com/orders/create", {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          payment_details: {
            cardName: formData.cardName,
            cardNumberLast4: formData.cardNumber.slice(-4),
            billingAddress: {
              firstName: formData.firstName,
              lastName: formData.lastName,
              address: formData.address,
              city: formData.city,
              postcode: formData.postcode,
              country: formData.country,
            },
          },
        }),
      });

      if (!response.ok) {
        throw new Error("Payment processing failed");
      }

      await clearCart();
      return true;
    } catch (error) {
      console.error("Payment processing error:", error);
      return false;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    setIsProcessing(true);

    try {
      // Process payment
      const success = await processPayment();

      if (success) {
        // Clear cart and redirect to success page
        navigate("/order-success");
      } else {
        setErrors({
          submit: "Payment processing failed. Please try again.",
        });
      }
    } catch (error) {
      setErrors({
        submit: "Payment processing failed. Please try again.",
      });
    } finally {
      setIsProcessing(false);
    }
  };

  if (isLoading) {
    return (
      <div className="min-h-screen bg-gray-50 py-56">
        <div className="max-w-7xl mx-auto px-4 text-center">
          <div className="flex justify-center">
            <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-yellow-500"></div>
          </div>
          <p className="mt-4 text-gray-600">Loading your order details...</p>
        </div>
      </div>
    );
  }

  return (
    <div
      style={{ paddingTop: "12rem" }}
      className="min-h-screen bg-gray-50 py-9"
    >
      <div className="max-w-7xl mx-auto px-4">
        <button
          onClick={() => navigate("/cart")}
          className="flex items-center text-gray-600 hover:text-gray-900 mb-8"
        >
          <ArrowLeft className="w-4 h-4 mr-2" />
          Return to cart
        </button>

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-12">
          {/* Checkout Form */}
          <div className="space-y-8">
            <div className="bg-white rounded-lg shadow-sm p-6">
              <h2 className="text-2xl font-bold mb-6">Contact information</h2>
              <div className="space-y-4">
                <div>
                  <label className="block text-left text-sm font-medium text-gray-700 mb-1">
                    Email
                  </label>
                  <input
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleInputChange}
                    className={`w-full px-4 py-2 border rounded-lg ${
                      errors.email ? "border-red-500" : "border-gray-300"
                    }`}
                    placeholder="your@email.com"
                  />
                  {errors.email && (
                    <p className="text-red-500 text-sm mt-1">{errors.email}</p>
                  )}
                </div>
              </div>
            </div>

            <div className="bg-white rounded-lg shadow-sm p-6">
              <h2 className="text-2xl font-bold mb-6">Billing Information</h2>
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <label className="block text-left text-sm font-medium text-gray-700 mb-1">
                    First Name
                  </label>
                  <input
                    type="text"
                    name="firstName"
                    value={formData.firstName}
                    onChange={handleInputChange}
                    className={`w-full px-4 py-2 border rounded-lg ${
                      errors.firstName ? "border-red-500" : "border-gray-300"
                    }`}
                  />
                  {errors.firstName && (
                    <p className="text-red-500 text-sm mt-1">
                      {errors.firstName}
                    </p>
                  )}
                </div>
                <div>
                  <label className="block text-left text-sm font-medium text-gray-700 mb-1">
                    Last Name
                  </label>
                  <input
                    type="text"
                    name="lastName"
                    value={formData.lastName}
                    onChange={handleInputChange}
                    className={`w-full px-4 py-2 border rounded-lg ${
                      errors.lastName ? "border-red-500" : "border-gray-300"
                    }`}
                  />
                  {errors.lastName && (
                    <p className="text-red-500 text-sm mt-1">
                      {errors.lastName}
                    </p>
                  )}
                </div>
                <div className="col-span-2">
                  <label className="block text-left text-sm font-medium text-gray-700 mb-1">
                    Address
                  </label>
                  <input
                    type="text"
                    name="address"
                    value={formData.address}
                    onChange={handleInputChange}
                    className={`w-full px-4 py-2 border rounded-lg ${
                      errors.address ? "border-red-500" : "border-gray-300"
                    }`}
                  />
                  {errors.address && (
                    <p className="text-red-500 text-sm mt-1">
                      {errors.address}
                    </p>
                  )}
                </div>
                <div>
                  <label className="block text-left text-sm font-medium text-gray-700 mb-1">
                    City
                  </label>
                  <input
                    type="text"
                    name="city"
                    value={formData.city}
                    onChange={handleInputChange}
                    className={`w-full px-4 py-2 border rounded-lg ${
                      errors.city ? "border-red-500" : "border-gray-300"
                    }`}
                  />
                  {errors.city && (
                    <p className="text-red-500 text-sm mt-1">{errors.city}</p>
                  )}
                </div>
                <div>
                  <label className="block text-left text-sm font-medium text-gray-700 mb-1">
                    Postcode
                  </label>
                  <input
                    type="text"
                    name="postcode"
                    value={formData.postcode}
                    onChange={handleInputChange}
                    className={`w-full px-4 py-2 border rounded-lg ${
                      errors.postcode ? "border-red-500" : "border-gray-300"
                    }`}
                  />
                  {errors.postcode && (
                    <p className="text-red-500 text-sm mt-1">
                      {errors.postcode}
                    </p>
                  )}
                </div>
                <div className="col-span-2">
                  <label className="block text-left text-sm font-medium text-gray-700 mb-1">
                    Country
                  </label>
                  <select
                    name="country"
                    value={formData.country}
                    onChange={handleInputChange}
                    className="w-full px-4 py-2 border border-gray-300 rounded-lg"
                  >
                    <option value="United Kingdom">United Kingdom</option>
                    <option value="United States">United States</option>
                    <option value="Canada">Canada</option>
                  </select>
                </div>
              </div>
            </div>

            <div className="bg-white rounded-lg shadow-sm p-6">
              <h2 className="text-2xl font-bold mb-6">Payment information</h2>
              <div className="space-y-4">
                <div>
                  <label className="block text-left text-sm font-medium text-gray-700 mb-1">
                    Card Number
                  </label>
                  <div className="relative">
                    <input
                      type="text"
                      name="cardNumber"
                      value={formData.cardNumber}
                      onChange={handleInputChange}
                      className={`w-full px-4 py-2 border rounded-lg pl-10 ${
                        errors.cardNumber ? "border-red-500" : "border-gray-300"
                      }`}
                      placeholder="1234 5678 9012 3456"
                      maxLength="16"
                    />
                    <CreditCard className="w-5 h-5 text-gray-400 absolute left-3 top-1/2 transform -translate-y-1/2" />
                  </div>
                  {errors.cardNumber && (
                    <p className="text-red-500 text-sm mt-1">
                      {errors.cardNumber}
                    </p>
                  )}
                </div>
                <div className="grid grid-cols-2 gap-4">
                  <div>
                    <label className="block text-left text-sm font-medium text-gray-700 mb-1">
                      Expiry Date
                    </label>
                    <input
                      type="text"
                      name="expiryDate"
                      value={formData.expiryDate}
                      onChange={handleInputChange}
                      className={`w-full px-4 py-2 border rounded-lg ${
                        errors.expiryDate ? "border-red-500" : "border-gray-300"
                      }`}
                      placeholder="MM/YY"
                    />
                    {errors.expiryDate && (
                      <p className="text-red-500 text-sm mt-1">
                        {errors.expiryDate}
                      </p>
                    )}
                  </div>
                  <div>
                    <label className="block text-left text-sm font-medium text-gray-700 mb-1">
                      CVV
                    </label>
                    <input
                      type="text"
                      name="cvv"
                      value={formData.cvv}
                      onChange={handleInputChange}
                      className={`w-full px-4 py-2 border rounded-lg ${
                        errors.cvv ? "border-red-500" : "border-gray-300"
                      }`}
                      placeholder="123"
                      maxLength="4"
                    />
                    {errors.cvv && (
                      <p className="text-red-500 text-sm mt-1">{errors.cvv}</p>
                    )}
                  </div>
                </div>
                <div>
                  <label className="block text-left text-sm font-medium text-gray-700 mb-1">
                    Name on Card
                  </label>
                  <input
                    type="text"
                    name="cardName"
                    value={formData.cardName}
                    onChange={handleInputChange}
                    className="w-full px-4 py-2 border border-gray-300 rounded-lg"
                    placeholder="Name as shown on card"
                  />
                </div>
              </div>
            </div>
          </div>

          {/* Order Summary */}
          <div className="bg-white rounded-lg shadow-sm p-6 sticky top-4">
            <h2 className="text-2xl font-bold mb-6">Order Summary</h2>
            <div className="space-y-4">
              {cartItems.map((item) => (
                <div
                  key={item.id}
                  className="flex justify-between py-4 border-b gap-12"
                >
                  <div className="flex-grow">
                    <h3 className="text-left font-medium">{item.title}</h3>
                    {item.date && item.time && (
                      <p className="text-left text-sm text-gray-500">
                        {item.date}, {item.time}
                      </p>
                    )}
                    {item.location && (
                      <p className="text-left text-sm text-gray-500">
                        {item.location}
                      </p>
                    )}
                  </div>

                  <div className="w-48 text-right min-w-24 flex flex-col items-end">
                    <p className="text-left font-medium">
                      {item.price === "Free" || item.price === "free"
                        ? "Free"
                        : item.price}
                    </p>
                    {item.type === "resource" && (
                      <p className="text-left text-sm text-gray-500">
                        Quantity: {item.quantity}
                      </p>
                    )}
                    {item.type === "event" && (
                      <p className="text-left text-sm text-gray-500">
                        Attendees: {item.attendees}
                      </p>
                    )}
                    {item.price !== "Free" && item.price !== "free" && (
                      <p className="text-sm text-gray-500">
                        Subtotal: £
                        {(
                          parseFloat(item.price.replace(/[^0-9.-]+/g, "")) *
                          (item.type === "resource"
                            ? item.quantity
                            : item.attendees)
                        ).toFixed(2)}
                      </p>
                    )}
                  </div>
                </div>
              ))}

              <div className="flex justify-between pt-4 border-t">
                <span className="text-lg font-medium">Total</span>
                <span className="text-2xl font-bold text-yellow-600">
                  £{calculateTotal().toFixed(2)}
                </span>
              </div>

              <button
                onClick={handleSubmit}
                disabled={isProcessing}
                className={`w-full py-4 rounded-lg text-white font-medium mt-6 ${
                  isProcessing
                    ? "bg-gray-400 cursor-not-allowed"
                    : "bg-yellow-600 hover:bg-yellow-700"
                }`}
              >
                {isProcessing ? "Processing..." : "Complete Purchase"}
              </button>

              {errors.submit && (
                <p className="text-red-500 text-sm text-center mt-2">
                  {errors.submit}
                </p>
              )}

              <p className="text-sm text-gray-500 text-center mt-4">
                Your payment is secured and encrypted
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

// Order Success Page Component
// const OrderSuccessPage = () => {
//   const navigate = useNavigate();

//   useEffect(() => {
//     window.scrollTo(0, 0);
//   }, []);

//   return (
//     <div className="min-h-screen bg-gray-50 py-12">
//       <div className="max-w-2xl mx-auto px-4">
//         <div className="bg-white rounded-lg shadow-sm p-8 text-center">
//           <div className="w-16 h-16 bg-green-100 rounded-full flex items-center justify-center mx-auto mb-6">
//             <svg
//               className="w-8 h-8 text-green-500"
//               fill="none"
//               strokeLinecap="round"
//               strokeLinejoin="round"
//               strokeWidth="2"
//               viewBox="0 0 24 24"
//               stroke="currentColor"
//             >
//               <path d="M5 13l4 4L19 7"></path>
//             </svg>
//           </div>
//           <h1 className="text-3xl font-bold text-gray-900 mb-4">
//             Order Successful!
//           </h1>
//           <p className="text-gray-600 mb-8">
//             Thank you for your purchase. We have sent a confirmation email with
//             your order details.
//           </p>
//           <div className="space-y-4">
//             <button
//               onClick={() => navigate("/events")}
//               className="w-full py-3 px-4 bg-yellow-600 text-white rounded-lg hover:bg-yellow-700 transition"
//             >
//               Browse More Events
//             </button>
//             <button
//               onClick={() => navigate("/resources")}
//               className="w-full py-3 px-4 bg-gray-100 text-gray-700 rounded-lg hover:bg-gray-200 transition"
//             >
//               View Resources
//             </button>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

export { CheckoutPage };
