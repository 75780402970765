import React, { useEffect } from "react";
import aboutus from "../../Assets/Images/aboutUs.jpg";
import "./style.css";
import { useNavigate } from "react-router-dom";
import Box from "../Dashboard/Box";
import GoToTopButton from "../GotoTop";
import img from "../../Assets/Images/P5. About-min.jpg";
const About = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top-left corner
  }, []);
  const history = useNavigate();
  return (
    <div>
      <div class="headerSpacing"></div>
      <section class="c-pageBanner c-pageBanner--about sectionContainer1">
        <div class="c-pageBanner__backgroundOverlay"></div>
        <div class="c-pageBanner__container responsivePadding">
          <div class="c-pageBanner__content">
            <h1 class="c-pageBanner__heading">About us</h1>
          </div>
          <img src={img} alt="" class="c-pageBanner__img" />
        </div>
      </section>

      <section class="sectionContainer1">
        <div class="c-text c-text--aboutUs responsivePadding">
          <h2 class="c-text__title">
            <span class="first-line">
              Designed by teachers. Contributed by senior examiners.
            </span>
            <br />
            Tailored to you.
          </h2>
          <div class="c-text__textContainer">
            <p class="c-text__text">
              We genuinely value the significance of teacher collaboration in
              enhancing T&L experiences. Be it smaller or larger departments, it
              is imperative to receive the right support and not feel left
              behind. Learnogic aims to be the sounding board that offers you
              in-depth CPDs, events, and resources that fully corroborate with
              your exam boards. Departments strive to find innovative solutions
              through board-specific training and events, but without success.
            </p>
            <p class="c-text__text">
              Keeping this in mind, we collaborate with senior examiners, Ofqual
              subject specialists, content authors, revisers, highly experienced
              Head of Departments, and Academic Deputy Heads to offer an
              extensive range of high-quality CPDs, student events, and other
              valuable resources. We meticulously select sessions to operate at
              various times of the year to facilitate the continuous growth and
              development of all the teachers in your department.
            </p>
            <p class="c-text__text">
              Our tailored CPDs and events ensure that your department is
              well-equipped to drive student engagement by implementing our
              strategies for ‘Teaching to the Top’ and ‘Adaptive Teaching’.
              Every student desires the tools to enhance academic excellence
              with the aim of gaining outstanding results, and we are here to
              support you. There is a strong demand for time-saving solutions,
              which only Learnogic offers by sharing good practice all year
              round.
            </p>
          </div>
        </div>
      </section>
      {/* <div class="spacing-48px"></div>
      <section class="sectionContainer">
        <div class="c-cardContent responsivePadding">
          <div class="c-cardContent__container">
            <h2 class="c-cardContent__title title--h2" style={{ margin: 40 }}>
              Digital Pedagogy
            </h2>
            <div class="c-cardContent__textGroup">
              <div class="c-cardContent__textContainer">
                <p class="c-cardContent__text">
                  Education has evolved.Students connecting remotely, learning
                  in distinct approaches and demanding more from technology.In
                  current learning environments, teachers must look to advance
                  from conventional classrooms to the application of digital
                  pedagogy.And all of this must be done on stretched/reduced
                  budgets.
                </p>
                <p class="c-cardContent__text">
                  Education has evolved.Students connecting remotely, learning
                  in distinct approaches and demanding more from technology.In
                  current learning environments, teachers must look to advance
                  from conventional classrooms to the application of digital
                  pedagogy.And all of this must be done on stretched/reduced
                  budgets.
                </p>
                <p class="c-cardContent__text">
                  Education has evolved.Students connecting remotely, learning
                  in distinct approaches and demanding more from technology.In
                  current learning environments, teachers must look to advance
                  from conventional classrooms to the application of digital
                  pedagogy.And all of this must be done on stretched/reduced
                  budgets.
                </p>
              </div>
            </div>
            <div class="c-cardContent__cardGroup">
              <h3 class="c-cardContent__title title--h3">The benefits</h3>
              <div class="c-cardContent__cards">
                <div class="c-cardContent__card">
                  <p class="c-cardContent__cardTitle text--b">
                    <strong>Students</strong>
                  </p>
                  <p class="c-cardContent__cardText text--m">
                    Digital intelligence ensures that students have the
                    essential skills and ingenuity to satisfy demands of an
                    ever-evolving digital world. <br></br>
                    <br></br>
                    They know how to effectively use technology, giving them
                    greater confidence to navigate around it.
                  </p>
                </div>
                <div class="c-cardContent__card">
                  <p class="c-cardContent__cardTitle text--b">
                    {" "}
                    <strong>Teacher</strong>
                  </p>
                  <p class="c-cardContent__cardText text--m">
                    The application of technology helps save time and provides
                    much higher standards of teaching and learning.<br></br>
                    <br></br>
                    It enables every teacher to deliver exciting and engaging
                    lessons and inspire students to make the most progress
                    possible.
                  </p>
                </div>
                <div class="c-cardContent__card">
                  <p class="c-cardContent__cardTitle text--b">
                    <strong>Schools</strong>
                  </p>
                  <p class="c-cardContent__cardText text--m">
                    Technology offers an agile platform for innovation whilst
                    supporting the efficient running of your school.
                    <br></br>
                    <br></br>
                    It provides key insights to students academic progress and
                    their well-being through tracking, monitoring and
                    evaluation.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      <section class="sectionContainer1 bgPureWhite">
        <div class="c-iconblks responsivePadding">
          <h2 class="c-iconblks__title c-iconblks__title--teal">
            Learn with us
          </h2>
          <div class="c-iconblks__container c-iconblks__container--triple">
            <div class="c-iconblks__iconblk">
              <img src="https://dilepex-lms.kau.agency/wp-content/themes/wp-dilepex-website/assets/svg/professionalCertification.svg" />
              <p class="c-iconblks__contentTitle">CPDs and events</p>
            </div>
            <div class="c-iconblks__iconblk">
              <img src="https://dilepex-lms.kau.agency/wp-content/themes/wp-dilepex-website/assets/svg/expertTrainingProviders.svg" />
              <p class="c-iconblks__contentTitle">Senior examiners</p>
            </div>
            <div class="c-iconblks__iconblk">
              <img src="https://dilepex-lms.kau.agency/wp-content/themes/wp-dilepex-website/assets/svg/subjectSpecificFocus.svg" />
              <p class="c-iconblks__contentTitle">Board specific focus</p>
            </div>
            <div class="c-iconblks__iconblk">
              <img src="https://dilepex-lms.kau.agency/wp-content/themes/wp-dilepex-website/assets/svg/learnAtYourOwnPace.svg" />
              <p class="c-iconblks__contentTitle">Train at a steady pace</p>
            </div>
            <div class="c-iconblks__iconblk">
              <img src="https://dilepex-lms.kau.agency/wp-content/themes/wp-dilepex-website/assets/svg/computerTime.svg" />
              <p class="c-iconblks__contentTitle"> Time-saving sessions</p>
            </div>
            <div class="c-iconblks__iconblk">
              <img src="https://dilepex-lms.kau.agency/wp-content/themes/wp-dilepex-website/assets/svg/sharingGoodPractice.svg" />
              <p class="c-iconblks__contentTitle">Sharing good practice</p>
            </div>
          </div>
          {/* <h2 class="c-iconblks__title c-iconblks__title--blue">
            ... and address the challenges
          </h2>
          <div class="c-iconblks__container c-iconblks__container--quad">
            <div class="c-iconblks__iconblk">
              <img src="https://dilepex-lms.kau.agency/wp-content/themes/wp-dilepex-website/assets/svg/computerTime.svg" />
              <p class="c-iconblks__fixedText">
                Offer excellent time-saving and collaborative training, despite
                stretched budgets
              </p>
            </div>
            <div class="c-iconblks__iconblk">
              <img src="https://dilepex-lms.kau.agency/wp-content/themes/wp-dilepex-website/assets/svg/computerSeminar.svg" />
              <p class="c-iconblks__fixedText">
                Improve digital learning and enable your students to gain the
                necessary digital skills
              </p>
            </div>
            <div class="c-iconblks__iconblk">
              <img src="https://dilepex-lms.kau.agency/wp-content/themes/wp-dilepex-website/assets/svg/atom.svg" />
              <p class="c-iconblks__fixedText">
                Enable diversity, accessibility and inclusion within all areas
                and aspects of school life
              </p>
            </div>
            <div class="c-iconblks__iconblk">
              <img src="https://dilepex-lms.kau.agency/wp-content/themes/wp-dilepex-website/assets/svg/jigsaw.svg" />
              <p class="c-iconblks__fixedText">
                Adapt to all the curriculum updates and the pressure for grade
                improvements
              </p>
            </div>
          </div> */}
        </div>
      </section>

      <section class="sectionContainer1">
        <div class="c-text c-text--ourEthos responsivePadding">
          <h2 class="c-text__title">Our ethos</h2>
          <div class="c-text__textContainer">
            <p class="c-text__text">
              With years of combined experience in education, examining work and
              delivering extensive training for awarding organisations, our
              extensive team's insight into assessments is unparalleled. We
              empower you to master the art of assessment delivery and elevate
              your students' responses to the next level.
            </p>
            <p class="c-text__text">
              The Learnogic team is meticulously selected for our knowledge,
              expertise, passion, drive and personality. This enables us to
              excel in high-pressure teaching and learning environments.
              Ingenuity and hard work are at the heart and soul of each team
              member, and in everything that we do.
            </p>
            <p class="c-text__text">
              In our collaborative environments, where creative and strategic
              minds align, we continuously train to stay updated with the very
              best practices in delivering assessments. Our primary goal is to
              enhance students' learning experiences and free up your schedule,
              allowing you to focus on what truly matters most.
            </p>
          </div>
        </div>
      </section>

      {/* <section class="sectionContainer">
          <div class="c-blogTb responsivePadding">
            <div class="c-blogTb__header">
              <h2 class="c-blogTb__headerHeading">The Executive team</h2>
              <button class="c-button c-button--whiteOutline c-button--desktop">
                Read more
              </button>
            </div>
            <div class="c-blogTb__thumbnails">
              <div class="c-blogTb__thumbnail">
                <img
                  src="https://dilepex-lms.kau.agency/wp-content/themes/wp-dilepex-website/assets/blog/interview.jpg"
                  alt=""
                  class="c-blogTb__thumbnailImg"
                />
                <div class="c-tag c-tag--tips">Tips</div>
                <div class="c-blogTb__thumbnailTitle">
                  Sue Standen
                  <div class="c-blogSubtitle">
                    <div class="c-blogSubtitle__tag">CEO</div>
                  </div>
                </div>
              </div>
              <div class="c-blogTb__thumbnail">
                <img
                  src="https://dilepex-lms.kau.agency/wp-content/themes/wp-dilepex-website/assets/blog/teacher.jpg"
                  alt=""
                  class="c-blogTb__thumbnailImg"
                />
                <div class="c-tag c-tag--updates">Updates</div>
                <div class="c-blogTb__thumbnailTitle">
                  Morne Hoffman
                  <div class="c-blogSubtitle">
                    <div class="c-blogSubtitle__tag">COO</div>
                  </div>
                </div>
              </div>
              <div class="c-blogTb__thumbnail">
                <img
                  src="https://dilepex-lms.kau.agency/wp-content/themes/wp-dilepex-website/assets/blog/childVR.jpg"
                  alt=""
                  class="c-blogTb__thumbnailImg"
                />
                <div class="c-tag c-tag--news">News</div>
                <div class="c-blogTb__thumbnailTitle">
                  Maaz Khan
                  <div class="c-blogSubtitle">
                    <div class="c-blogSubtitle__tag">Founder</div>
                  </div>
                </div>
              </div>
            </div>
            <button class="c-button c-button--whiteOutline c-button--mobile">
              Read more
            </button>
          </div>
        </section> */}

      {/* <section class="sectionContainer">
          <div className="c-cta">
            <div className="c-cta__title">Get in touch</div>
            <p className="c-cta__intro">
              For any further queries regarding CPDs, student events or
              purchasing resources, send us a message.
            </p>
            <button
              className="c-button ctaOrange"
              style={{
                background: "rgb(66 66 66)",
                border: "none",
                color: "#fff",
              }}
              onClick={() => {
                history("/contact");
              }}
            >
              Contact us
            </button>
          </div>
        </section> */}

      <div className="bgContainer linearBG">
        <Box />
      </div>
      <div className="sectionContainer buttons-div">
        <section
          style={{ backgroundColor: "#eea80b" }}
          className="sectionContainer"
        >
          <div className="c-cta">
            <div className="c-cta__title">Get in touch</div>
            <p className="c-cta__intro">
              If you require additional information or have any queries, please
              feel free to send us a message.
            </p>
            <button
              className="c-button ctaOrange"
              style={{
                background: "rgb(66 66 66)",
                border: "none",
                color: "#fff",
              }}
              onClick={() => {
                history("/contact");
              }}
            >
              Contact us
            </button>
          </div>
        </section>
        {/* <div className="div-style">
            {" "}
            <a
              className="c-button ctaOrange"
              style={{
                background: "rgb(32 123 191)",
                border: "none",
                color: "#fff",
                width: "220px",
                borderRadius: "10px",
                marginRight: "35px",
                textDecoration: "none",
              }}
              href="https://demo.nexonetics.com/register/"
            >
              Register
            </a>
            <a
              className="c-button ctaOrange"
              style={{
                background: "rgb(32 123 191)",
                border: "none",
                color: "#fff",
                width: "220px",
                borderRadius: "10px",
                textDecoration: "none",
              }}
              href="https://demo.nexonetics.com/upgrade"
            >
              Login
            </a>
          </div> */}
      </div>
      <GoToTopButton />
    </div>
  );
};

export default About;
