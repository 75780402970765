import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import { Helmet } from "react-helmet"; // Add this import
import "./App.css";
import Dashboard from "./Components/Dashboard/Dashboard";
import Footer from "./Components/Footer";
import NewNavbar from "./Components/Navbar/NewNavbar";
import About from "./Components/About";
import ContactForm from "./Components/contact";
import Course from "./Components/Course";
import Tutors from "./Components/Tutors";
import Teachers from "./Components/Teachers";
import AuthPage from "./Components/view/login";
import NewCourses from "./Components/Course/NewCourses";
import Resources from "./Components/Resources/Resources";
import EventDetailsPage from "./Components/Course/EvenrDetailsPage";
import ExamBoardSelector from "./Components/view/ExamBoardSelector";
import SignupForm from "./Components/view/SignupForm";
import Dashboard1 from "./Components/Course/Dashboard";
import Payment from "./Components/Course/Payment";
import LoginSelection from "./Components/view/LoginSelection.js";

import { CartPage, CartProvider } from "./Components/cart/CartProvider.jsx";
import { CheckoutPage } from "./Components/cart/CheckoutPage.jsx";
import AdminLogin from "./Admin/AdminLogin.jsx";
import Testimonials from "./Admin/Testimonials.jsx";
import Users from "./Admin/Users.jsx";
import AdminLayout from "./Admin/AdminLayout.jsx";
import AdminResources from "./Admin/AdminResources.jsx";
import ResourceThemes from "./Admin/ResourceThemes.jsx";
import AdminEvents from "./Admin/AdminEvents.jsx";
import DashboardLayout from "./Components/cart/DashboardLayout.jsx";
import PrivacyPolicy from "./Components/view/privacyPolicy.js";
import TermsAndConditions from "./Components/view/TermsAndConditions.js";
import EditProfile from "./Components/cart/editpProfile.jsx";
import OrderSuccessPage from "./Components/cart/OrderSuccessPage.jsx";

// Layout wrapper component to conditionally render Navbar and Footer
const AppLayout = ({ children }) => {
  const location = useLocation();
  const isAdminRoute = location.pathname.startsWith("/admin");

  return (
    <div className="App">
      {/* Add Helmet component for dynamic meta tags */}
      <Helmet>
        <meta name="robots" content="noindex, nofollow" />
        <meta name="googlebot" content="noindex, nofollow" />
      </Helmet>
      {!isAdminRoute && <NewNavbar />}
      {children}
      {!isAdminRoute && <Footer />}
    </div>
  );
};

function App() {
  return (
    <Router>
      <CartProvider>
        <AppLayout>
          <Routes>
            <Route path="/login-selection" element={<LoginSelection />} />
            <Route path="/login" element={<AuthPage />} />
            <Route path="/" element={<Dashboard />} />
            <Route path="/cart" element={<CartPage />} />
            <Route path="/checkout" element={<CheckoutPage />} />
            <Route path="/order-success" element={<OrderSuccessPage />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route
              path="/terms-and-conditions"
              element={<TermsAndConditions />}
            />

            <Route path="/profile/edit" element={<EditProfile />} />
            <Route path="/dashboard" element={<DashboardLayout />} />
            <Route path="/payment" element={<Payment />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<ContactForm />} />
            <Route path="/subjects" element={<Course />} />
            <Route path="/tutors" element={<Tutors />} />
            <Route path="/teachers-and-examiners" element={<Teachers />} />
            <Route path="/events" element={<NewCourses />} />
            <Route path="/resources" element={<Resources />} />
            <Route path="/event/:id" element={<EventDetailsPage />} />
            <Route
              path="/exam-board-registration"
              element={<ExamBoardSelector />}
            />
            <Route path="/admin-login" element={<AdminLogin />} />
            <Route path="/sign-up" element={<SignupForm />} />
            {/* Admin Routes */}
            <Route path="/admin" element={<AdminLayout />}>
              <Route
                path="resources/:resourceId/themes"
                element={<ResourceThemes />}
              />
              <Route path="users" element={<Users />} />
              <Route path="events" element={<AdminEvents />} />
              <Route path="resources" element={<AdminResources />} />
              <Route path="testimonials" element={<Testimonials />} />
            </Route>
          </Routes>
        </AppLayout>
      </CartProvider>
    </Router>
  );
}

export default App;
