import React, { useState, useEffect, useCallback } from "react";
import logo from "../../Assets/Images/Logo-Bird-2.png";
import logo1 from "../../Assets/Images/topicons.png";
import logo2 from "../../Assets/Images/topicons (1).png";

import "./style.css";
import { Link, Route, useNavigate } from "react-router-dom";
import { Search } from "lucide-react";

const NewNavbar = () => {
  const [hang, setHang] = useState(false);
  const [showProfileDropdown, setShowProfileDropdown] = useState(false);
  const [dropdownTimer, setDropdownTimer] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const history = useNavigate();

  // Check login status function to be reused
  const checkLoginStatus = useCallback(() => {
    const token = localStorage.getItem("token");
    setIsLoggedIn(!!token);
  }, []);

  // Check if the user is logged in using the token in localStorage
  useEffect(() => {
    // Initial check
    checkLoginStatus();

    // Add event listener to detect localStorage changes in other tabs/windows
    const handleStorageChange = (e) => {
      if (e.key === "token") {
        checkLoginStatus();
      }
    };

    window.addEventListener("storage", handleStorageChange);

    // Custom event for local changes within the same window
    const handleTokenChange = () => {
      checkLoginStatus();
    };

    window.addEventListener("tokenChanged", handleTokenChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
      window.removeEventListener("tokenChanged", handleTokenChange);
    };
  }, [checkLoginStatus]);

  const hangBurger = () => {
    setHang(!hang);
  };

  const handleMouseEnter = () => {
    // Only show dropdown if user is logged in
    if (!isLoggedIn) return;

    // Clear any existing timer
    if (dropdownTimer) clearTimeout(dropdownTimer);
    // Set a small delay before showing the dropdown
    const timer = setTimeout(() => {
      setShowProfileDropdown(true);
    }, 300); // 300ms delay
    setDropdownTimer(timer);
  };

  const handleMouseLeave = () => {
    // Clear any existing timer
    if (dropdownTimer) clearTimeout(dropdownTimer);
    // Set a small delay before hiding the dropdown
    const timer = setTimeout(() => {
      setShowProfileDropdown(false);
    }, 300); // 300ms delay
    setDropdownTimer(timer);
  };

  const navigateToDashboard = () => {
    history("/dashboard");
    setShowProfileDropdown(false);
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    setIsLoggedIn(false);
    setShowProfileDropdown(false);

    // Dispatch custom event to notify about token change
    window.dispatchEvent(new Event("tokenChanged"));

    history("/");
  };

  const handleProfileClick = () => {
    if (!isLoggedIn) {
      window.location.href = "https://demo.nexonetics.com/my-account/";
    }
  };

  return (
    <div>
      <header className="c-nav sectionContainer bgBlueDark">
        <div
          onClick={() => {
            history("/");
          }}
          style={{
            display: "flex",
            textAlign: "justify",
            justifyContent: "space-between",
            padding: 13,
            // background: "#ffd787d1",
            borderRadius: 12,
          }}
        >
          <div>
            <a href="">
              <img src={logo} alt="" className="c-nav__logo" />
            </a>
          </div>
          <div style={{ marginLeft: 16, cursor: "pointer" }}>
            <div
              style={{
                fontFamily: "Krona One",
                fontSize: 30,
                fontWeight: 700,
                letterSpacing: 2,
                color: "white",
              }}
            >
              Learnogic
            </div>
            <div
              style={{
                fontFamily: "montserrat",
                fontSize: 13,
                fontWeight: 500,
                letterSpacing: 2,
                color: "#fff  ",
                textAlign: "center",
              }}
            >
              excel with flying colours
            </div>
          </div>
        </div>{" "}
        <div className="c-nav__menu">
          <div className="c-nav__links">
            <Link to="/events" className="c-nav__link">
              Events
            </Link>
            <Link to="/resources" className="c-nav__link">
              Resources
            </Link>

            <div
              style={{ cursor: "pointer" }}
              onClick={() => {
                history("/subjects");
              }}
              className="c-nav__link"
            >
              Students
            </div>
            <Link to="/tutors" className="c-nav__link">
              Tutors
            </Link>
            <Link to="/about" className="c-nav__link">
              About
            </Link>
            <Link to="/contact" className="c-nav__link">
              Contact
            </Link>
          </div>
          <div className="c-nav__buttons" style={{ alignItems: "center" }}>
            <Link to="/cart" style={{ maxWidth: "80%" }}>
              <svg
                width="30"
                height="30"
                viewBox="0 0 227 226"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M68.5473 37.7876V52.8114H211.273V82.5514L194.606 135.442H63.8205L45.0408 0.209961H0.940445V15.2337H31.9589L50.7385 150.466H205.624L226.297 84.8622V37.7876H68.5473Z"
                  fill="white"
                  stroke="white"
                  strokeWidth="8"
                />
                <path
                  d="M76.5212 165.633C68.5549 165.642 60.9174 168.811 55.2844 174.444C49.6514 180.077 46.4827 187.714 46.4737 195.68C46.4737 203.649 49.6394 211.292 55.2744 216.927C60.9094 222.562 68.5521 225.728 76.5212 225.728C84.4903 225.728 92.133 222.562 97.7679 216.927C103.403 211.292 106.569 203.649 106.569 195.68C106.56 187.714 103.391 180.076 97.7581 174.443C92.125 168.81 84.4875 165.642 76.5212 165.633ZM76.5212 210.704C73.5497 210.704 70.6451 209.823 68.1744 208.172C65.7038 206.521 63.7781 204.175 62.641 201.43C61.5039 198.684 61.2064 195.664 61.7861 192.749C62.3658 189.835 63.7967 187.158 65.8978 185.057C67.9989 182.956 70.6758 181.525 73.5902 180.945C76.5045 180.366 79.5253 180.663 82.2705 181.8C85.0157 182.937 87.3621 184.863 89.013 187.334C90.6638 189.804 91.5449 192.709 91.5449 195.68C91.5402 199.663 89.9558 203.482 87.1393 206.298C84.3229 209.115 80.5043 210.699 76.5212 210.704Z"
                  fill="white"
                  stroke="white"
                  strokeWidth="8"
                />
                <path
                  d="M181.687 165.633C173.721 165.642 166.084 168.811 160.451 174.444C154.818 180.077 151.649 187.714 151.64 195.68C151.64 203.649 154.806 211.292 160.441 216.927C166.076 222.562 173.718 225.728 181.687 225.728C189.657 225.728 197.299 222.562 202.934 216.927C208.569 211.292 211.735 203.649 211.735 195.68C211.726 187.714 208.557 180.076 202.924 174.443C197.291 168.81 189.654 165.642 181.687 165.633ZM181.687 210.704C178.716 210.704 175.811 209.823 173.341 208.172C170.87 206.521 168.944 204.175 167.807 201.43C166.67 198.684 166.373 195.664 166.952 192.749C167.532 189.835 168.963 187.158 171.064 185.057C173.165 182.956 175.842 181.525 178.756 180.945C181.671 180.366 184.692 180.663 187.437 181.8C190.182 182.937 192.528 184.863 194.179 187.334C195.83 189.804 196.711 192.709 196.711 195.68C196.706 199.663 195.122 203.482 192.306 206.298C189.489 209.115 185.671 210.699 181.687 210.704Z"
                  fill="white"
                  stroke="white"
                  strokeWidth="8"
                />
              </svg>
            </Link>

            <div className="relative" style={{ maxWidth: "80%" }}>
              <div
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                onClick={handleProfileClick}
                style={{ cursor: "pointer", position: "relative" }}
              >
                <svg
                  width="30"
                  height="30"
                  viewBox="0 0 254 272"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle
                    cx="123.5"
                    cy="60.5"
                    r="54.5"
                    stroke="white"
                    strokeWidth="25"
                  />
                  <path
                    d="M247.5 226.5C247.5 240.19 244.204 248.383 239.299 253.564C234.305 258.84 226.677 262.026 215.852 263.627C205.02 265.228 191.882 265.121 176.675 264.557C172.807 264.413 168.808 264.24 164.701 264.063C152.87 263.551 140.135 263 127 263C113.865 263 101.13 263.551 89.2992 264.063C85.1917 264.24 81.1932 264.413 77.3248 264.557C62.1183 265.121 48.9803 265.228 38.1481 263.627C27.3228 262.026 19.6951 258.84 14.7006 253.564C9.79556 248.383 6.5 240.19 6.5 226.5C6.5 172.296 59.3308 126.5 127 126.5C194.669 126.5 247.5 172.296 247.5 226.5Z"
                    stroke="white"
                    strokeWidth="25"
                  />
                </svg>
                {/* Dropdown Menu - Only show if logged in */}
                {isLoggedIn && (
                  <div
                    className={`absolute right-0 mt-2 w-40 bg-white rounded-md shadow-lg z-10 transition-all duration-300 ${
                      showProfileDropdown
                        ? "opacity-100 visible"
                        : "opacity-0 invisible"
                    }`}
                    style={{
                      borderRadius: "8px",
                      boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                      transform: showProfileDropdown
                        ? "translateY(0)"
                        : "translateY(-10px)",
                    }}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                  >
                    <div
                      className="py-2 px-4 text-gray-800 hover:bg-gray-100 cursor-pointer rounded-md"
                      onClick={navigateToDashboard}
                      style={{ borderRadius: "8px" }}
                    >
                      Dashboard
                    </div>
                    <div
                      className="py-2 px-4 text-gray-800 hover:bg-gray-100 cursor-pointer rounded-md"
                      onClick={handleLogout}
                      style={{ borderRadius: "8px" }}
                    >
                      Logout
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="c-nav__hamburger" onClick={hangBurger}>
          <img
            src="https://dilepex-lms.kau.agency/wp-content/themes/wp-dilepex-website/assets/svg/hamburgerClosed.svg"
            alt=""
            className="c-nav__hamburgerImg"
          />
        </div>
        {hang && (
          <div className="c-nav__menu--mobile bgBlueDark">
            <div className="c-nav__links--mobile">
              <Link to="/" className="c-nav__link--mobile">
                Home
              </Link>
              <Link to="/subjects" className="c-nav__link--mobile">
                Subjects
              </Link>
              <Link to="/tutors" className="c-nav__link--mobile">
                Tutors
              </Link>
              <Link to="/about" className="c-nav__link--mobile">
                About
              </Link>
              <Link to="/contact" className="c-nav__link--mobile">
                Contact
              </Link>
              {isLoggedIn && (
                <Link to="/dashboard" className="c-nav__link--mobile">
                  Dashboard
                </Link>
              )}
              {isLoggedIn && (
                <div className="c-nav__link--mobile" onClick={handleLogout}>
                  Logout
                </div>
              )}
            </div>
            <div className="c-nav__buttons--mobile">
              {!isLoggedIn && (
                <a
                  type="button"
                  className="c-button1 c-button--whiteOutline c-button--span"
                  href="/login-selection"
                >
                  Login
                </a>
              )}
              <a
                type="button"
                className="c-button1 ctaOrange c-button--span"
                style={{
                  background: "rgb(66 66 66)",
                  border: "none",
                  textDecoration: "none",
                }}
                href="https://demo.nexonetics.com/"
              >
                Get started today
              </a>
            </div>
          </div>
        )}
      </header>
    </div>
  );
};

export default NewNavbar;
