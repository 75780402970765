import React, { useEffect, useState } from "react";
import { Eye, EyeOff } from "lucide-react";
import { useLocation, useNavigate } from "react-router-dom";

const Authpage = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [apiError, setApiError] = useState("");
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const location = useLocation();
  const navigate = useNavigate();
  const redirectPath = location.state?.redirectAfterLogin || "/dashboard";
  const message = location.state?.message || "";

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
    // Clear any existing errors when user types
    if (apiError) setApiError("");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setApiError("");

    try {
      // Create URLSearchParams object for x-www-form-urlencoded format
      const formBody = new URLSearchParams();
      formBody.append("username", formData.email);
      formBody.append("password", formData.password);

      const response = await fetch("https://api2.made2tech.com/token", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: formBody.toString(),
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(
          data.message || "Login failed. Please check your credentials."
        );
      }

      // Handle successful login
      if (data.access_token) {
        // Store the token
        localStorage.setItem("token", data.access_token);
        localStorage.setItem("user_id", data.user_id);

        // Redirect to original destination page or dashboard
        navigate(redirectPath);
      } else {
        throw new Error("Login successful but no token received");
      }
    } catch (error) {
      setApiError(
        error.message || "An error occurred during login. Please try again."
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleForgotPassword = () => {
    window.location.href = "/forgot-password";
  };

  const handleSignUp = () => {
    window.location.href = "/sign-up";
  };

  return (
    <div className="max-w-xl mx-auto p-8 mt-40 mb-28">
      <h1 className="text-3xl font-bold text-center mb-8">Teacher login</h1>

      {message && (
        <div className="mb-6 p-4 bg-blue-50 border border-blue-200 text-blue-700 rounded-lg">
          {message}
        </div>
      )}

      <div className="bg-white rounded-lg shadow-lg p-6">
        {apiError && (
          <div className="mb-4 p-3 bg-red-100 border border-red-400 text-red-700 rounded">
            {apiError}
          </div>
        )}

        <form onSubmit={handleSubmit} className="space-y-6">
          <div className="space-y-2">
            <label className="block text-left text-sm font-medium text-gray-900">
              Username / Email address
            </label>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              placeholder="Please enter your email address"
              className="w-full px-3 py-2 border border-gray-300 rounded-md text-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
              required
            />
          </div>

          <div className="space-y-2">
            <label className="block text-left text-sm font-medium text-gray-900">
              Password
            </label>
            <div className="relative">
              <input
                type={showPassword ? "text" : "password"}
                name="password"
                value={formData.password}
                onChange={handleChange}
                placeholder="Please enter your password"
                className="w-full px-3 py-2 border border-gray-300 rounded-md text-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                required
              />
              <button
                type="button"
                onClick={() => setShowPassword(!showPassword)}
                className="absolute right-3 top-1/2 -translate-y-1/2"
              >
                {showPassword ? (
                  <EyeOff className="h-4 w-4 text-gray-500" />
                ) : (
                  <Eye className="h-4 w-4 text-gray-500" />
                )}
              </button>
            </div>
          </div>

          <button
            type="submit"
            disabled={isLoading}
            className={`w-36 py-2 px-4 bg-yellow-500 text-white rounded-md 
              ${
                isLoading
                  ? "opacity-50 cursor-not-allowed"
                  : "hover:bg-yellow-300"
              } 
              transition-colors focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500`}
          >
            {isLoading ? "Signing in..." : "Sign in"}
          </button>
        </form>

        <div className="mt-6 text-center">
          <a
            onClick={handleForgotPassword}
            style={{ fontSize: "12px", fontWeight: 500 }}
            className="  text-yellow-500 hover:text-yellow-300 cursor-pointer text-sm"
          >
            Forgotten your log in details? →
          </a>
        </div>

        <div className="mt-4 text-center space-x-1 text-sm">
          <span
            style={{ fontWeight: 500, fontSize: ".875rem" }}
            className=" text-gray-600"
          >
            Don't have an account?
          </span>
          <a
            onClick={handleSignUp}
            className="text-yellow-500 hover:text-yellow-300 cursor-pointer"
          >
            Create an account →
          </a>
        </div>
      </div>
    </div>
  );
};

export default Authpage;
