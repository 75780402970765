import React, { useEffect, useState } from "react";
import "./style.css";
import HeroSection from "./HeroSection";
import Box from "./Box";
import { useNavigate } from "react-router-dom";
import GoToTopButton from "../GotoTop";
import img1 from "../../Assets/Images/Raise teacher productivity-min.jpg";
import img2 from "../../Assets/Images/Raise student outcomes-min.jpg";
import img3 from "../../Assets/Images/intro-image3.png";

import inperson from "../../Assets/Images/EV1. In-person CPD-min.jpg";
import online from "../../Assets/Images/EV2. Online CPD-min.jpg";
import bespoke from "../../Assets/Images/EV3. Bespoke CPD-min.jpg";
import student from "../../Assets/Images/EV4. Student events-min.jpg";

const Dashboard = () => {
  const history = useNavigate();
  const heroData = [
    {
      text: " teacher productivity ",
      sectionClass: "c-hero",
      img: img1,
    },
    {
      text: " assessment levels",
      sectionClass: "c-hero1",
      img: "https://dilepex-lms.kau.agency/wp-content/themes/wp-dilepex-website/assets/hero/hero3.jpg",
    },
    {
      text: " student attainment",
      sectionClass: "c-hero2",
      img: img2,
    },
  ];

  const [currentIndex, setCurrentIndex] = useState(0);
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % heroData.length);
    }, 6000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="dashboard-div">
      <HeroSection
        text={heroData[currentIndex].text}
        sectionClass={heroData[currentIndex].sectionClass}
        img={heroData[currentIndex].img}
      />

      <section className="sectionContainer1">
        <div className="c-intro">
          <div className="c-intro__contentGroup c-intro__contentGroup--double">
            <div className="c-intro__content">
              <div style={{ fontSize: 43 }} className="c-intro__title">
                Who are we?
              </div>
              <p className="c-intro__text">
                Learnogic was designed to provide a platform for sharing best
                practices. Be it support for schools, departments or students,
                we offer comprehensive in-person teacher CPD sessions and
                student events to enhance teaching and learning.
              </p>
            </div>
            <div className="c-intro__content">
              <div style={{ fontSize: 43 }} className="c-intro__title">
                What's the logic?
              </div>
              <p className="c-intro__text">
                Our CPD sessions develop teachers' understanding of course
                delivery and assessments. Our student events provide a clear
                insight into exam technique essential for assessments and
                improve quality of their written work.
              </p>
            </div>
          </div>
          <div className="c-intro__contentGroup c-intro__contentGroup--left">
            <img src={img1} alt="" className="c-intro__img" />
            <div className="c-intro__content">
              <div style={{ fontSize: 43 }} className="c-intro__title">
                Raise teacher productivity
              </div>
              <p className="c-intro__text">
                Maximise efficacy by making optimum use of time. The aim of our
                in-depth teacher CPD sessions is to equip teachers with the most
                effective strategies that inspire learning across all groups. By
                quickly adapting to the needs of every student, teachers can
                focus on things that matter most.
              </p>
            </div>
          </div>
          <div className="c-intro__contentGroup c-intro__contentGroup--right">
            <img src={img3} alt="" className="c-intro__img" />
            <div className="c-intro__content">
              <div style={{ fontSize: 43 }} className="c-intro__title">
                Raise assessment levels
              </div>
              <div className="c-intro__text">
                To achieve the highest level, it is essential to address the
                demands of each exam question by incorporating all the
                assessment objectives (AOs) into written responses. Our teacher
                CPD sessions and student events act as a guide to gaining a
                stronger understanding of how to approach different questions.
              </div>
            </div>
          </div>
          <div className="c-intro__contentGroup c-intro__contentGroup--left">
            <img
              style={{ height: "100%" }}
              src={img2}
              alt=""
              className="c-intro__img"
            />
            <div className="c-intro__content">
              <div style={{ fontSize: 43 }} className="c-intro__title">
                Raise student attainment
              </div>
              <div className="c-intro__text">
                While learning content is vital, writing to the necessary
                standard is imperative. Our teacher CPD sessions and student
                events focus on how to meet the requirements of, and answer,
                different questions. This will significantly increase the
                quality of written work, and hence, better outcomes.
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="sectionContainer1 bgPureWhite">
        <div className="c-coursesTb responsivePadding">
          <div className="c-coursesTb__header">
            <h2 style={{ marginTop: 0 }} className="c-coursesTb__headerHeading">
              Learning with us
            </h2>
          </div>
          <div className="c-coursesTb__thumbnails">
            <div className="c-coursesTb__thumbnail">
              <img
                src={inperson}
                alt=""
                className="c-coursesTb__thumbnailImg"
              />
              {/* <div className="c-tag c-tag--bestSeller">Best seller</div> */}
              <div className="c-coursesTb__thumbnailTitle">
                In-person CPD
                <p className="thumbnail-description">
                  Attend our key in-person training sessions and learn from our
                  senior examiners. Take the opportunity to network with fellow
                  teachers. Our courses are predominantly held in London, with
                  additional sessions available in various cities in the UK and
                  around the world.
                </p>
              </div>
            </div>

            <div className="c-coursesTb__thumbnail">
              <img src={online} alt="" className="c-coursesTb__thumbnailImg" />
              {/* <div className="c-tag c-tag--bestSeller">Best seller</div> */}
              <div className="c-coursesTb__thumbnailTitle">
                Online CPD
                <p className="thumbnail-description">
                  Engage in extensive professional development from any part of
                  the world. We provide precise training across qualifications
                  that cover a range of challenging content and assessment. Our
                  short focussed sessions keep staff engaged at all times.
                </p>
              </div>
            </div>

            <div className="c-coursesTb__thumbnail">
              <img src={bespoke} alt="" className="c-coursesTb__thumbnailImg" />
              {/* <div className="c-tag c-tag--bestSeller">Best seller</div> */}

              <div className="c-coursesTb__thumbnailTitle">
                Bespoke CPD
                <p className="thumbnail-description">
                  We offer customised in-house training tailored to your
                  specific needs. You select the focus area. Our senior
                  examiners will design and run a bespoke programme that
                  seamlessly aligns with your department's targets and
                  institution's priorities.
                </p>
              </div>
            </div>

            <div className="c-coursesTb__thumbnail">
              <img src={student} alt="" className="c-coursesTb__thumbnailImg" />
              {/* <div className="c-tag c-tag--bestSeller">Best Seller</div> */}
              <div className="c-coursesTb__thumbnailTitle">
                Student events
                <p className="thumbnail-description">
                  The main objective of our in-house events is to enhance pupil
                  learning. Conducted by senior examiners, these key events
                  provide effective learning strategies and guidance on
                  improving the quality of written work. The outcome? Higher
                  overall pupil grades.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="bgContainer linearBG">
        <Box />
        <section className="sectionContainer">
          <div className="c-cta">
            <div className="c-cta__title">Get in touch</div>
            <p className="c-cta__intro">
              If you require additional information or have any queries, please
              feel free to send us a message.
            </p>
            <button
              className="c-button ctaOrange"
              style={{
                background: "rgb(66 66 66)",
                border: "none",
                color: "#fff",
              }}
              onClick={() => {
                history("/contact");
              }}
            >
              Contact us
            </button>
          </div>
        </section>
      </div>
      <GoToTopButton />
    </div>
  );
};

export default Dashboard;
