import React from "react";
import "./style.css";
import logo from "../../Assets/Images/Logo-Bird-2.png";
import { useNavigate } from "react-router-dom";
import Twiiter from "../../Assets/Images/x-twitter-brands-solid.svg";

const Footer = () => {
  const history = useNavigate();

  return (
    <div>
      <footer class="sectionContainer bgBlueDark" style={{ display: "block" }}>
        <div
          class="c-footer responsivePadding"
          // style={{ padding: "80px 180px 80px 80px" }}
        >
          <div class="c-footer__topGroup">
            <div
              class="c-footer__group c-footer__group--left"
              style={{
                width: 200,
                display: "block",
                textAlign: "justify",
              }}
            >
              <h3 style={{ fontFamily: "Krona One", margin: 0, fontSize: 35 }}>
                cultivating excellence
              </h3>
              <div className="cultivate">
                {" "}
                We empower you to master the art of assessment delivery and
                elevate your students' responses to the next level.
              </div>
            </div>
            <div class="c-footer__group c-footer__group--right">
              <div
                style={{
                  display: "flex",
                  width: "500px",
                  justifyContent: "space-between",
                  gap: 10,
                  // background: "#ffd787d1",
                  borderRadius: 12,
                }}
              >
                {/* <a href="">
            <img src={logo} alt="" class="c-nav__logo" />
          </a> */}
                <div>
                  <a href="">
                    <img
                      src={logo}
                      alt=""
                      style={{ height: 100 }}
                      class="c-nav__logo"
                    />
                  </a>
                </div>
                <div style={{ marginLeft: 5, cursor: "pointer" }}>
                  <div
                    style={{
                      fontFamily: "Krona One",
                      fontSize: 46,
                      fontWeight: 700,
                      letterSpacing: 2,
                    }}
                  >
                    Learnogic
                  </div>
                  <div
                    style={{
                      fontFamily: "montserrat",
                      fontSize: 21,
                      fontWeight: 500,
                      letterSpacing: 2,
                      color: "#fff  ",
                      textAlign: "center",
                    }}
                  >
                    excel with flying colours
                  </div>
                </div>
              </div>{" "}
              <ul class="c-footer__links">
                <a
                  onClick={() => {
                    history("/events");
                  }}
                  class="c-footer__link"
                >
                  Events
                </a>
                <a
                  onClick={() => {
                    history("/resources");
                  }}
                  class="c-footer__link"
                >
                  Resources
                </a>
                <a
                  onClick={() => {
                    history("/subjects");
                  }}
                  class="c-footer__link"
                >
                  Students
                </a>
                <a
                  onClick={() => {
                    history("/tutors");
                  }}
                  class="c-footer__link"
                >
                  Tutors
                </a>
                <a
                  onClick={() => {
                    history("/teachers-and-examiners");
                  }}
                  class="c-footer__link"
                >
                  Careers
                </a>
                <a
                  onClick={() => {
                    history("/about");
                  }}
                  class="c-footer__link"
                >
                  About
                </a>
              </ul>
            </div>
          </div>
          <div class="c-footer__tnc">
            <div class="c-socialIcons">
              <a href="">
                <i class="fa-brands fa-facebook"></i>
              </a>
              <a href="">
                <i class="fa-brands fa-instagram"></i>
              </a>
              <a href="">
                <i class="fa-brands fa-youtube"></i>
              </a>
              <a href="">
                <i class="fa-brands fa-linkedin"></i>
              </a>
              <a href="">
                <img
                  style={{ width: 40, height: 45, color: "white" }}
                  src={Twiiter}
                />
              </a>
            </div>
            <div style={{ display: "flex", gap: "9px" }}>
              {" "}
              <a
                onClick={() => {
                  history("/terms-and-conditions");
                }}
                href=""
              >
                Terms and conditions
              </a>
              |
              <a
                onClick={() => {
                  history("/privacy-policy");
                }}
                href=""
              >
                Privacy policy
              </a>
              © Learnogic 2025
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
