import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const SignupForm = () => {
  const [formData, setFormData] = useState({
    title: "Mr",
    firstName: "",
    lastName: "",
    email: "",
    jobTitle: "",
    school: "",
    password: "",
    confirmPassword: "",
    acceptTerms: false,
  });
  const navigate = useNavigate();
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [apiError, setApiError] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  const titles = ["Mr", "Mrs", "Miss", "Ms", "Dr", "Prof"];
  const jobTitles = [
    "Teacher",
    "Principal",
    "Administrator",
    "Department Head",
    "Coordinator",
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
    // Clear errors when user starts typing
    if (errors[name]) {
      setErrors((prev) => ({ ...prev, [name]: "" }));
    }
    setApiError("");
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.firstName) newErrors.firstName = "First name is required";
    if (!formData.lastName) newErrors.lastName = "Last name is required";
    if (!formData.email) {
      newErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = "Email is invalid";
    }
    if (!formData.jobTitle) newErrors.jobTitle = "Job title is required";
    if (!formData.school) newErrors.school = "School is required";
    if (!formData.password) {
      newErrors.password = "Password is required";
    } else if (formData.password.length < 8) {
      newErrors.password = "Password must be at least 8 characters";
    }
    if (!formData.confirmPassword) {
      newErrors.confirmPassword = "Please confirm your password";
    } else if (formData.password !== formData.confirmPassword) {
      newErrors.confirmPassword = "Passwords do not match";
    }
    if (!formData.acceptTerms)
      newErrors.acceptTerms = "You must accept the terms and conditions";
    return newErrors;
  };
  useEffect(() => {
    // Redirect to login after successful registration
    if (isSuccess) {
      const timer = setTimeout(() => {
        navigate("/login");
      }, 2000); // 2 second delay to show success message
      return () => clearTimeout(timer);
    }
  }, [isSuccess, navigate]);
  const handleSubmit = async (e) => {
    e.preventDefault();
    const newErrors = validateForm();

    if (Object.keys(newErrors).length === 0) {
      setIsSubmitting(true);
      setApiError("");

      // Prepare API payload
      const apiPayload = {
        email: formData.email,
        full_name: `${formData.firstName} ${formData.lastName}`,
        user_type: "teacher",
        school: formData.school,
        title: formData.title,
        job_title: formData.jobTitle,
        password: formData.password,
      };

      try {
        const response = await fetch("https://api2.made2tech.com/users/", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(apiPayload),
        });

        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(
            errorData.message || "Registration failed. Please try again."
          );
        }

        // Handle successful registration
        const data = await response.json();
        console.log("Registration successful:", data);
        setIsSuccess(true);
      } catch (error) {
        setApiError(
          error.message ||
            "An error occurred during registration. Please try again."
        );
      } finally {
        setIsSubmitting(false);
      }
    } else {
      setErrors(newErrors);
    }
  };

  const RequiredLabel = ({ children }) => (
    <span className="block text-left text-sm mb-1">
      {children} <span className="text-green-500">*</span>
    </span>
  );

  return (
    <div className="max-w-3xl mx-auto p-8 mt-36 mb-9">
      <h1 className="text-3xl font-bold text-center mb-8">
        Teacher Registration
      </h1>
      <div className="bg-white rounded-lg shadow-lg p-6">
        {apiError && (
          <div className="mb-4 p-3 bg-red-100 border border-red-400 text-red-700 rounded">
            {apiError}
          </div>
        )}

        <form onSubmit={handleSubmit}>
          <div className="flex flex-wrap -mx-2 mb-4">
            <div className="w-full md:w-1/3 px-2 mb-4 md:mb-0">
              <label className="block text-left text-sm mb-1">Title</label>
              <select
                name="title"
                value={formData.title}
                onChange={handleChange}
                className="w-full rounded border border-gray-300 px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
              >
                {titles.map((title) => (
                  <option key={title} value={title}>
                    {title}
                  </option>
                ))}
              </select>
            </div>

            <div className="w-full md:w-1/3 px-2 mb-4 md:mb-0">
              <RequiredLabel>First name</RequiredLabel>
              <input
                type="text"
                name="firstName"
                value={formData.firstName}
                onChange={handleChange}
                className={`w-full rounded border px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500 ${
                  errors.firstName ? "border-red-500" : "border-gray-300"
                }`}
              />
              {errors.firstName && (
                <p className="mt-1 text-sm text-red-600">{errors.firstName}</p>
              )}
            </div>

            <div className="w-full md:w-1/3 px-2 mb-4 md:mb-0">
              <RequiredLabel>Last name</RequiredLabel>
              <input
                type="text"
                name="lastName"
                value={formData.lastName}
                onChange={handleChange}
                className={`w-full rounded border px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500 ${
                  errors.lastName ? "border-red-500" : "border-gray-300"
                }`}
              />
              {errors.lastName && (
                <p className="mt-1 text-sm text-red-600">{errors.lastName}</p>
              )}
            </div>
          </div>

          <div className="flex flex-wrap -mx-2 mb-4">
            <div className="w-full md:w-1/2 px-2 mb-4 md:mb-0">
              <RequiredLabel>Email address</RequiredLabel>
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                className={`w-full rounded border px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500 ${
                  errors.email ? "border-red-500" : "border-gray-300"
                }`}
              />
              {errors.email && (
                <p className="mt-1 text-sm text-red-600">{errors.email}</p>
              )}
            </div>

            <div className="w-full md:w-1/2 px-2 mb-4 md:mb-0">
              <RequiredLabel>Job title</RequiredLabel>
              <select
                name="jobTitle"
                value={formData.jobTitle}
                onChange={handleChange}
                className={`w-full rounded border px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500 ${
                  errors.jobTitle ? "border-red-500" : "border-gray-300"
                }`}
              >
                <option value="">Please select...</option>
                {jobTitles.map((title) => (
                  <option key={title} value={title}>
                    {title}
                  </option>
                ))}
              </select>
              {errors.jobTitle && (
                <p className="mt-1 text-sm text-red-600">{errors.jobTitle}</p>
              )}
            </div>
          </div>

          <div className="mb-4">
            <RequiredLabel>School</RequiredLabel>
            <input
              type="text"
              name="school"
              value={formData.school}
              onChange={handleChange}
              className={`w-full rounded border px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500 ${
                errors.school ? "border-red-500" : "border-gray-300"
              }`}
            />
            {errors.school && (
              <p className="mt-1 text-sm text-red-600">{errors.school}</p>
            )}
          </div>

          <div className="flex flex-wrap -mx-2 mb-4">
            <div className="w-full md:w-1/2 px-2 mb-4 md:mb-0">
              <RequiredLabel>Password</RequiredLabel>
              <input
                type="password"
                name="password"
                value={formData.password}
                onChange={handleChange}
                className={`w-full rounded border px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500 ${
                  errors.password ? "border-red-500" : "border-gray-300"
                }`}
              />
              {errors.password && (
                <p className="mt-1 text-sm text-red-600">{errors.password}</p>
              )}
            </div>

            <div className="w-full md:w-1/2 px-2 mb-4 md:mb-0">
              <RequiredLabel>Confirm password</RequiredLabel>
              <input
                type="password"
                name="confirmPassword"
                value={formData.confirmPassword}
                onChange={handleChange}
                className={`w-full rounded border px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500 ${
                  errors.confirmPassword ? "border-red-500" : "border-gray-300"
                }`}
              />
              {errors.confirmPassword && (
                <p className="mt-1 text-sm text-red-600">
                  {errors.confirmPassword}
                </p>
              )}
            </div>
          </div>

          <div className="mb-6">
            <label className="flex items-center">
              <input
                type="checkbox"
                name="acceptTerms"
                checked={formData.acceptTerms}
                onChange={handleChange}
                className="mr-2 h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
              />
              <span className="text-sm">
                I have read and accept the{" "}
                <a href="/privacy-policy" className="text-blue-500 underline">
                  privacy notice
                </a>{" "}
                and the{" "}
                <a href="/terms" className="text-blue-500 underline">
                  terms and conditions
                </a>{" "}
                of Use.
              </span>
            </label>
            {errors.acceptTerms && (
              <p className="mt-1 text-sm text-red-600">{errors.acceptTerms}</p>
            )}
          </div>

          <div className="flex justify-between">
            <button
              type="submit"
              disabled={isSubmitting}
              className={`px-6 py-2 bg-yellow-500 border border-transparent rounded text-sm font-medium text-white 
                ${
                  isSubmitting
                    ? "opacity-50 cursor-not-allowed"
                    : "hover:bg-yellow-300"
                } 
                focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500`}
            >
              {isSubmitting ? "Registering..." : "Continue"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SignupForm;
