import React from "react";
import img from "../../Assets/Images/Terms and conditions-min.jpg";
import "./style.css";
import GoToTopButton from "../GotoTop";

const TermsAndConditions = () => {
  return (
    <div>
      <section className="c-pageBanner c-pageBanner--privacy sectionContainer1">
        <div className="c-pageBanner__backgroundOverlay"></div>
        <div className="c-pageBanner__container responsivePadding">
          <div className="c-pageBanner__content">
            <h1 className="c-pageBanner__heading">Terms and conditions</h1>
          </div>
          <img
            src={img}
            alt="Terms and conditions banner"
            className="c-pageBanner__img"
          />
        </div>
      </section>
      <section className="sectionContainer1">
        <div className="text-left ">
          {/* <h1 className="text-2xl font-bold mb-6">Terms and conditions</h1> */}

          <p className="mb-4">
            LEARNOGIC LIMITED (us, we and our) provide website features and
            other products and services to you when you visit or shop at
            www.learnogic.com (the website or our website). We provide our
            services and sell our products to you subject to the terms and
            conditions set out below. Please also see our Privacy policy to
            understand how we collect and process your personal information.
          </p>

          <p className="mb-4">
            If you do not agree to any of the terms and conditions below
            (including any amendments), you must not use our website or purchase
            or products and services. We reserve the right to amend any of the
            terms and conditions at any time without notice.
          </p>

          {/* Events Terms section */}
          <h2 className="text-xl font-bold mt-8 mb-4">(i) Events Terms</h2>

          <h3 className="text-lg font-semibold mt-6 mb-3">Introduction</h3>
          <p className="mb-3">
            1.1 These terms and conditions govern your participation in our
            events including: CPD Workshops, Teacher/Revision Webinars, Student
            Revision Days and Bespoke in School Training (each an "Event" and
            together the "Events").
          </p>
          <p className="mb-3">
            1.2 Any contract formed is made between us and the person, company
            or other entity specified on your booking form (you or your).
          </p>
          <p className="mb-3">
            1.3 By registering for the Event, you are agreeing to be bound by
            the following terms and conditions ("Event Terms").
          </p>
          <p className="mb-3">
            1.4 You agree that you are 18 years or older. If you are not 18
            years old, we will ask your parent or legal guardian to confirm your
            booking on your behalf. If you are booking on behalf of your
            educational establishment, you also confirm that you have the
            necessary authority to bind your educational establishment and you
            will procure that any participants in the Event ("Participant") will
            adhere to these terms and conditions.
          </p>
          <p className="mb-3">
            1.5 Where tickets are issued for an Event, you shall be responsible
            for any such tickets, and we shall not be responsible in the event
            of any loss, theft, and/or destruction of tickets.
          </p>

          <h3 className="text-lg font-semibold mt-6 mb-3">Bookings</h3>
          <p className="mb-3">
            2.1 It is your responsibility to ensure that the information you
            provide to us during the booking process is complete and accurate.
          </p>
          <p className="mb-3">
            2.2 Spaces at an Event may be restricted and are allocated on a
            first come, first served basis. Your place at the Event is not
            guaranteed until you receive an email from us stating that your
            booking is confirmed (your "Booking Confirmation" and a "Confirmed
            Booking").
          </p>
          <p className="mb-3">
            2.3 If you are booking a CPD Event on behalf of your educational
            establishment, we will ask you to confirm the total number of
            Participants at the time of booking. If you are booking any other
            Event on behalf of your education establishment, we will ask you to
            provide a provisional number of expected Participants in the Event.
            This number must be confirmed no later than 28 days prior to the
            Event and if you are making a booking within 28 days of the Event,
            you must confirm the total number of Participants at the time of
            booking. In the event that you fail to confirm the total number of
            Participants in accordance with this condition 2.3, we reserve the
            right to release your provisional booking.
          </p>
          <p className="mb-3">
            2.4 Your Booking Confirmation shall include:
            <ul className="list-disc ml-8 mt-2">
              <li>access to the Event at a specified time and date;</li>
              <li>
                if applicable, a set of notes to compliment the Event for each
                Participant (the "Materials");
              </li>
              <li>
                if the event is a webinar, may include a recording of the Event
                once it has concluded; and
              </li>
              <li>
                if the Event is a CPD Event, details of lunch and refreshments.
              </li>
            </ul>
          </p>

          <h3 className="text-lg font-semibold mt-6 mb-3">Payment</h3>
          <p className="mb-3">
            3.1 Fees for each Event, where applicable, are correct at the time
            of publication. We reserve the right to change the fee at any time
            but such changes will not affect registrations which have already
            been confirmed by us.
          </p>
          <p className="mb-3">
            3.2 When registering for an Event, you agree to pay the fee
            associated with the Event. Unless otherwise agreed, we will send an
            invoice for the Event fee to the email address used when booking no
            later than 30 days after the Event. The invoice will reflect the
            agreed fee (including any agreed discounts). You shall pay to us the
            fee (plus VAT) for the Event within 30 days from receipt by you of
            an invoice from us.
          </p>

          <h3 className="text-lg font-semibold mt-6 mb-3">
            Changes to your booking
          </h3>
          <p className="mb-3">
            4.1 If you wish to cancel your booking, you will need to notify us
            as soon as possible.
          </p>
          <p className="mb-3">
            4.2 To cover the costs of processing your cancellation and to
            compensate us for the risk that we may not be able to resell your
            place(s), we may make a cancellation charge based on the scale shown
            below. The cancellation charges will be effective from the date of
            receipt of written communication. The full cost of the booking is
            payable if you do not cancel your places(s) and do not attend the
            Event. If cancellation of confirmed In-person CPDs, Online CPDs,
            Bespoke CPDs and Student Events is within 14 days, there will be a
            full charge.
          </p>
          <p className="mb-3">
            4.3 If you wish to amend your booking, please notify our Events Team
            as soon as possible and we will let you know if any changes are
            possible. If it is possible, we will let you know about any changes
            to the price or anything else which would be necessary as a result
            of your requested change and ask you to confirm whether you wish to
            proceed with the change.
          </p>
          <p className="mb-3">
            4.4 For the avoidance of doubt, there will be no reduction in the
            Event fee if you reduce the number of Participants of a Confirmed
            Booking.
          </p>
          <p className="mb-3">
            4.5 We shall use all reasonable endeavours to provide the Events
            described on our Site, but we reserve the right to cancel or change
            the Event for any reason, including, but not limited to:
          </p>
          <p className="mb-3 ml-8">
            4.5.1 the circumstances set out in condition 9 (Unforeseen
            circumstances); and
          </p>
          <p className="mb-3 ml-8">
            4.5.2 if before the Event we have not, in our sole discretion,
            received a sufficient number of applications to ensure that there
            are enough Participants to make the Event economically viable.
          </p>
          <p className="mb-3">
            4.6 We may at our sole discretion, change the format, speakers,
            participants, content, venue location and programme or any other
            aspect of the Event at any time.
          </p>
          <p className="mb-3">
            4.7 If you have a Confirmed Booking, in the event of the
            cancellation of the Event by us, we may offer you a place at an
            alternative Event (at our sole discretion). In the event that a
            suitable alternative Event is not available, you will not be liable
            to pay the Event fee.
          </p>
          <p className="mb-3">
            4.8 Travel expenses shall not be reimbursed. You are responsible for
            booking and paying for your travel to and from the Event.
          </p>

          <h3 className="text-lg font-semibold mt-6 mb-3">Events</h3>
          <p className="mb-3">
            5.1 It is your responsibility to comply, and ensure all Participants
            comply (in the event that you are booking on behalf of an
            educational establishment), with all instructions given at the Event
            and to comply with all applicable laws, including health and safety
            laws, fire regulations and any Event venue's codes of conduct, terms
            and conditions or wi-fi access terms.
          </p>
          <p className="mb-3">
            5.2 You are responsible for your behaviour during the Event and the
            behaviour of all Participants (in the event that you are booking on
            behalf of an educational establishment). We may, at our sole
            discretion, refuse access or remove you or a Participant immediately
            from the Event if we believe:
            <ul className="list-disc ml-8 mt-2">
              <li>
                you or such Participant has disobeyed and/or breached, as
                relevant, any applicable terms and conditions, directions,
                instructions, decisions, or laws;
              </li>
              <li>
                you or such Participant's behaviour endangers safety or may
                damage or harm the environment, cause offence, otherwise cause a
                risk or potential risk to health and safety, or is otherwise
                inappropriate for the Event or the venue where the Event is
                taking place (the "Venue");
              </li>
              <li>
                you or such Participant failed to arrive at the Event at the
                specified time or location;
              </li>
              <li>
                you or such Participant is unfit to attend/participate in the
                Event due to the excessive consumption of alcohol or drugs;
              </li>
              <li>
                you or such Participant enters the Venue with contraband goods
                and/or prohibited articles including but not limited to illegal
                drugs, knives, guns and other types of weapons;
              </li>
              <li>
                you or such Participant's behaviour poses, or could pose, a
                security risk to the Venue Event, Participants or otherwise; or
              </li>
              <li>
                you or such Participant's behaviour affects, or is likely to
                affect, the enjoyment of other Participants or anyone connected
                to the Event.
              </li>
            </ul>
          </p>
          <p className="mb-3">
            5.3 If you are refused entry to or are removed from an Event, you
            will not receive a refund of the Event fee (whether total or
            partial) or any travel expenses.
          </p>
          <p className="mb-3">
            5.4 All Events may be recorded and videos, photographs and other
            recordings may be taken, where you may be captured participating in
            the Event. You agree to the publication of such photographs, videos,
            recordings, and/or likeness of you (whether edited, adapted,
            modified or copied) and the use by us and those authorised by us,
            without prior notice or compensation, in any way which we may see
            fit now or in the future including but not limited to film,
            broadcast, radio, TV, publications, future events and publicity. You
            acknowledge that we have the full right to sell and/or profit from
            the commercial use of such photographs, motion pictures, recordings,
            and/or likeness of you.
          </p>
          <p className="mb-3">
            5.5 Any audio, visual, or audio-visual recordings that you take of
            the Event or any part of it are for personal use only and cannot be
            published or used for any commercial purpose without our prior
            written consent. In the event that you are booking on behalf of an
            educational establishment, it is your responsibility to ensure that
            the Participants are aware of this condition and the condition
            above.
          </p>
          <p className="mb-3">
            5.6 Unless otherwise stated, (a) lunch and refreshments will be
            provided at any CPD Event, and (b) at any other Event, you are
            responsible of providing your own food and refreshments. We cannot
            guarantee we will be able to fulfil all dietary requirements, and
            shall not be liable in any case.
          </p>

          <h3 className="text-lg font-semibold mt-6 mb-3">Webinars</h3>
          <p className="mb-3">
            6.1 Joining details for a Webinar Event shall be sent to your email
            address which was used for the booking around 3 business days before
            the Event takes place. If you have not received these details 3 days
            before the Event, please contact us.
          </p>
          <p className="mb-3">
            6.2 We may record Webinar Events and, if so, we may send a recording
            of the Event to your email.
          </p>
          <p className="mb-3">
            6.3 Access to webinar Events is for yourself only (and to display
            the Event in a classroom environment). You may not share the unique
            access link given to you with any third party. Access to the Event
            may require you to configure the settings on your device, which may
            require that you download software for your operating system. We
            will not be responsible to you if you fail to verify your device
            configuration or download the necessary software before the webinar
            Event occurs. In such circumstances, you will still be liable to pay
            for the Event and will not be entitled to a refund.
          </p>
          <p className="mb-3">
            6.4 Zoom (or any other required) application must be downloaded
            prior to the webinar. We are not liable for your use of any third
            party application.
          </p>

          <h3 className="text-lg font-semibold mt-6 mb-3">
            Intellectual Property
          </h3>
          <p className="mb-3">
            Copyright in the Materials or any content related to the Event
            (including but not limited to recordings) ("Event Materials")
            belongs to us exclusively or is used under licence from a third
            party. You may not copy, distribute, publish, sell or disseminate
            the Event Materials in any form via any channel without our prior
            written consent.
          </p>

          <h3 className="text-lg font-semibold mt-6 mb-3">Liability</h3>
          <p className="mb-3">
            8.1 Neither party excludes or limits its liability to the other
            party for:
            <ul className="list-disc ml-8 mt-2">
              <li>
                death or personal injury caused by its own negligence, or the
                negligence of its employees, agents, or subcontractors (as
                applicable);
              </li>
              <li>fraud or fraudulent misrepresentation; and</li>
              <li>
                any other matter for which it would be unlawful to exclude or
                restrict liability.
              </li>
            </ul>
          </p>
          <p className="mb-3">
            8.2 Subject to condition 8.1 above, our total liability to you in
            respect of any and all claims, whether in contract, delict, tort,
            breach of statutory duty, or otherwise in connection with these
            Event Terms is (to the extent permitted by law) limited in respect
            of each event or series of connected events to the Event fee paid or
            payable by you.
          </p>
          <p className="mb-3">
            8.3 Subject to condition 8.1, we will not be liable to you, or any
            other person making a booking or attending an Event in your place,
            in respect of any and all claims, whether in contract, delict, tort
            (including negligence), breach of statutory duty, or otherwise, even
            if foreseeable, arising under or in connection with these Event
            Terms for:
            <ul className="list-disc ml-8 mt-2">
              <li>loss of profits, sales, business, contracts or revenue;</li>
              <li>loss of anticipated savings;</li>
              <li>loss or corruption of software, data, or information;</li>
              <li>waste of management, office, study or teaching time;</li>
              <li>business interruption; or</li>
              <li>any indirect or consequential loss or damage.</li>
            </ul>
          </p>
          <p className="mb-3">
            8.4 In addition to condition 8.3 above, we accept no responsibility
            for the loss of, theft, or damage to any property owned by or under
            your control which you bring to the Venue. You are solely and
            personally responsible for the safety of your belongings.
          </p>

          <h3 className="text-lg font-semibold mt-6 mb-3">
            Unforeseen circumstances
          </h3>
          <p className="mb-3">
            9.1 We shall have the right to cancel or change the Event and shall
            under no circumstances be responsible for any such cancellation or
            change if this is due to any cause or circumstance beyond our
            reasonable control.
          </p>
          <p className="mb-3">
            9.2 Such cause or circumstances shall include, but shall not be
            limited to, fire, flood, riot, terrorism, strike, freight embargoes
            or transportation delays, shortage of labour, inability to procure
            or secure fuel, material, supplies or power at current prices or on
            account of shortages thereof, pandemic, epidemic or other health
            emergency, acts of God or of a public enemy, or any existing or
            future laws or acts of H.M. Government or the government of any
            other state or territory (including specifically but not exclusively
            any orders, rules or regulations issued by any official or agency of
            H.M. Government or of any other such government) affecting the
            conduct of our business with which we, in our sole discretion, deems
            it advisable to comply as a legal duty.
          </p>

          <h3 className="text-lg font-semibold mt-6 mb-3">General</h3>
          <p className="mb-3">
            10.1 Attendance at any Event is not guaranteed to meet any CPD
            requirements you may have.
          </p>
          <p className="mb-3">
            10.2 You are not entitled to assign or transfer any of your rights
            or purport to assign any of your obligations under these Event Terms
            without our prior written consent. We may, at any time, assign,
            sublicense, transfer or otherwise dispose of all or any of our
            rights or obligations under the Event Terms.
          </p>
          <p className="mb-3">
            10.3 You acknowledge and agree that these Event Terms and any
            business, operational, financial or sensitive information disclosed
            to you pursuant to these Event Terms is confidential and you shall
            not disclose such confidential information to any third party
            without our written consent.
          </p>
          <p className="mb-3">
            10.4 If all or any provisions in these Event Terms shall be or
            become illegal, invalid or unenforceable in any respect, then the
            remainder of such provision and/or all other provisions shall remain
            valid and enforceable, and the remaining liabilities of the parties
            shall not be affected or impaired.
          </p>
          <p className="mb-3">
            10.5 No delay in exercising or non-exercise of any right, power or
            remedy provided by law or under these Event Terms shall impair or
            otherwise operate as a waiver or release of that right, power or
            remedy. Any single or partial exercise of any right, power or remedy
            provided by law or under these Event Terms shall not preclude any
            other or further exercise of that right or the exercise of any other
            right, power or remedy.
          </p>
          <p className="mb-3">
            10.6 These Event Terms set out the entire agreement and
            understanding between us for any of our Events and supersedes all
            proposals and prior agreements, arrangements, and understanding
            between us in relation to its subject matter.
          </p>
          <p className="mb-3">
            10.7 Please refer to our Privacy policy to know how we use and store
            your personal data.
          </p>
          <p className="mb-3">
            10.8 These Event Terms do not give rise to any rights under the
            Contracts (Rights of Third Parties) Act 1999 to enforce any terms of
            these Event Terms.
          </p>
          <p className="mb-3">
            10.9 We reserve the right to vary these Event Terms without notice
            at our sole discretion.
          </p>
          <p className="mb-3">
            10.10 Nothing contained in these Event Terms shall constitute or
            shall be construed as constituting a partnership, joint venture, or
            contract of employment between us.
          </p>
          <p className="mb-3">
            10.11 These Event Terms and any matters arising from them shall be
            governed by and construed in accordance with the laws of England and
            all disputes shall be submitted to the exclusive jurisdiction of the
            English courts.
          </p>

          {/* Sale Terms section */}
          <h2 className="text-xl font-bold mt-8 mb-4">(ii) Sale Terms</h2>

          <h3 className="text-lg font-semibold mt-6 mb-3">Introduction</h3>
          <p className="mb-3">
            1.1 These terms and conditions of sale ("Sale Terms") govern the
            sale of goods ("Goods") and digital content ("Digital Content")
            (together referred to as "Products") to you (the customer purchasing
            the Products, either a business or an individual).
          </p>
          <p className="mb-3">
            1.2 Please note, we do not give businesses customers the same rights
            as individual consumers. For example, business customers cannot
            cancel their orders without our consent, they have different rights
            where there is a problem with a product, and we do not compensate
            them in the same way for losses caused by us or our products. Where
            a term applies just to business customers or just to individual
            consumers, this is clearly stated in the Sale Terms. You are a
            business customer if you are buying products wholly or mainly for
            use in connection with your trade, business, craft or profession,
            even if you are an individual.
          </p>
          <p className="mb-3">
            1.3 The Sale Terms constitute the entire agreement between us and
            you in relation to your purchase ("Sale Contract"). You acknowledge
            that you have not relied on any statement, promise or representation
            or assurance or warranty that is not set out in the Sale Terms.
          </p>
          <p className="mb-3">
            1.4 Please read the Sale Terms carefully before placing an order
            with us. By placing an order with us, you signify your agreement to
            be bound by the Sale Terms.
          </p>

          <h3 className="text-lg font-semibold mt-6 mb-3">Order placement</h3>
          <p className="mb-3">
            2.1 Please follow the onscreen prompts to place an order. Each order
            is an offer by you to buy the Products specific to that order,
            whether that be Goods or Digital Content, subject to the Sale Terms.
          </p>
          <p className="mb-3">
            2.2 After you place an order, you will receive an email from us
            acknowledging that we have received it, but please note that this
            does not mean that your order has been accepted. We will confirm our
            acceptance of your order by sending an email to you that confirms
            that the Goods have been dispatched, or when we make the Digital
            Content available for you to download or access ("Dispatch"). The
            Sales Contract between you and us will only be formed when the
            Dispatch occurs.
          </p>
          <p className="mb-3">
            2.3 Sometimes we reject orders, for example, because a Product is
            out of stock, because we have identified an error in the price or
            description of the Product, or because we are unable to meet a
            delivery deadline you have specified. When this happens, we will let
            you know as soon as possible and refund any sums you have paid.
          </p>
          <p className="mb-3">
            2.4 You can buy our Products from most countries throughout the
            world. If we are unable to accept your order or deliver to your
            required delivery address we will tell you and will not charge you
            for the Product. There may be additional import or customs charges
            in some territories. These are outside our control and must be borne
            by you.
          </p>

          <h3 className="text-lg font-semibold mt-6 mb-3">Our products</h3>
          <p className="mb-3">
            3.1 Any images of Products are for illustrative purposes only. For
            example, the colour of the Products may vary slightly from the
            images that are displayed on the screen of your device and the
            packaging or cover of the Goods may vary from that shown on our
            Site.
          </p>
          <p className="mb-3">
            3.2 We may change a Product to:
            <ul className="list-disc ml-8 mt-2">
              <li>
                reflect changes in relevant laws and regulatory requirements;
                and
              </li>
              <li>
                to implement technical adjustments and improvements, for
                example, to address a security threat.
              </li>
            </ul>
            These changes will not affect your use of the Product.
          </p>
          <p className="mb-3">
            3.3 We may update or require you to update Digital Content, provided
            that the Digital Content shall always match the description of what
            we provided to you before you purchased this.
          </p>
          <p className="mb-3">
            3.4 If you place an order for Digital Content and we accept that
            order we will license to you the non-exclusive right to download and
            view a copy of the Digital Content for your personal, non-commercial
            use only. Any other use of Digital Content is strictly prohibited.
            You may not modify, transmit, publish, participate in the transfer
            or sale of, reproduce, create derivative works from, distribute,
            perform, display, or in any way exploit, any of the content of any
            Digital Content, in whole or in part.
          </p>

          <h3 className="text-lg font-semibold mt-6 mb-3">
            Delivery, transfer of title and risk
          </h3>
          <p className="mb-3">
            4.1 The costs of delivery will be displayed on our website. We will
            contact you with an estimated delivery date unless you have
            pre-ordered the Goods. Any delivery dates given by us are estimates
            only and time shall not be of the essence. If you have pre-ordered
            Goods, the date the Goods are available will be displayed on our
            Site and your order will be dispatched within a reasonable time of
            that date. Delivery is complete once the Goods have been delivered
            or unloaded at the address for delivery set out in your order and
            the Goods will be at your risk from that time.
          </p>
          <p className="mb-3">
            4.2 We will make the Digital Content available for download by you
            as soon as we accept your order. If you have pre-ordered Digital
            Content, the date it is available will be displayed on our website
            and the Digital Content will be available for you to download after
            that date.
          </p>
          <p className="mb-3">
            4.3 Notwithstanding any other provisions contained in the Sale
            Terms, legal and beneficial title in all Goods supplied pursuant to
            the Sale Terms shall remain vested in us until the later of:
            <ul className="list-disc ml-8 mt-2">
              <li>
                we receive payment in full of all debts (including value added
                tax any interest payable) owed by you to us; and
              </li>
              <li>time of delivery of the Goods to you.</li>
            </ul>
          </p>
          <p className="mb-3">
            4.4 If our supply of your Product is delayed by an event outside of
            our control, we will contact you as soon as possible to let you know
            and do what we can to reduce the delay. We won't compensate you for
            any delay, but if the delay is likely to be substantial, you can
            contact our Customer Service Team to receive a refund for any
            Products you have paid for, but not yet received.
          </p>

          <h3 className="text-lg font-semibold mt-6 mb-3">
            Pricing and payment
          </h3>
          <p className="mb-3">
            5.1 The price of Products will be as quoted on our website at the
            time you submit your order. We take all reasonable care to ensure
            that the prices of Products are correct. It is possible that,
            despite our reasonable efforts, some of the Products on our website
            may be incorrectly priced. If we discover an error in the pricing of
            the Products you have ordered, we will contact you to inform you of
            this error and we will give you the option of continuing with the
            purchase at the correct price or cancelling your order. If we are
            unable to contact you using the contact details you provided during
            the order process, we will treat the order as cancelled and notify
            you in writing. If we mistakenly accept and process your order where
            a pricing error is obvious and could reasonably have been recognised
            by you as a mispricing, we may cancel the order and refund you any
            sums you have paid.
          </p>
          <p className="mb-3">
            5.2 The price for Products shall be exclusive of value added tax (if
            applicable) and does not include delivery charges. We reserve the
            right to charge you, in addition to the price for the Products, for
            all costs or charges, if applicable, in relation to packaging,
            loading, unloading, carriage, insurance and storage. We reserve the
            right to charge to you any extra or increased costs incurred by us
            in meeting any specific order requirement.
          </p>
          <p className="mb-3">
            5.3 For individual consumers, we accept payment by the means
            specified on our website. Payment must be made at the time of
            ordering (subject to condition 5.4 below). Payment of the total
            purchase price (including VAT and delivery charges) must be made in
            full before Dispatch and/or Download. If you are purchasing a
            subscription from us, you shall pay for this at the point of
            purchase on our website.
          </p>
          <p className="mb-3">
            5.4 For business customers, payment terms are as agreed between you
            and us in writing. Time for payment shall be of the essence. You
            must pay all amounts due to us in full without any set-off,
            counterclaim, deduction or withholding (other than any deduction or
            withholding of tax as required by law). If we're unable to collect
            any payments you owe to us, we shall charge interest on the overdue
            amount at the rate of 7% above Tide PLC's base rate from time to
            time in force. The interest accrues on a daily basis from the due
            date until the date of actual payment of the overdue amount, whether
            before or after judgement.
          </p>

          <h3 className="text-lg font-semibold mt-6 mb-3">
            Returns and refunds (Individuals)
          </h3>
          <p className="mb-3">
            6.1 For most of our Products bought online, individual consumers
            have a legal right to change their mind about a purchase within 14
            days from the day on which the Goods are received, or from the day
            of the conclusion of the contract, in the case of services or
            Digital Content not supplied in a tangible medium (please see
            condition 6.3 for further information). Any refund to you may
            account for deductions to reflect the reduction in the value of the
            Products if this has been caused by your handling them in a way
            which would not be permitted in a shop.
          </p>
          <p className="mb-3">
            6.2 To let us know you want to change your mind. Please do one of
            the following:
            <ul className="list-disc ml-8 mt-2">
              <li>
                By post: Print and complete the cancellation form annexed to
                these Sale Terms and send this to us along with the relevant
                Products; or
              </li>
              <li>
                Contact us page on our website: For Digital Products, please
                provide your name, home address, your order number, details of
                the order and, where available, your phone number and email
                address.
              </li>
            </ul>
          </p>
          <p className="mb-3">
            6.3 If you change your mind about a Product, you must let us know no
            later than 14 days after:
            <ul className="list-disc ml-8 mt-2">
              <li>
                the day of delivery, if its Goods. If the Goods are for regular
                delivery (for example, a subscription), you can only change your
                mind after the first delivery. If the Goods are split into
                several deliveries over different days, the period runs from the
                date after the last delivery;
              </li>
              <li>
                the day we confirm that we have accepted your order, if it is
                for a service; or
              </li>
              <li>
                the day we confirm we have accepted your order, if it is for
                Digital Content for download or streaming, although you cannot
                change your mind about Digital Content once we have started
                providing it.
              </li>
            </ul>
          </p>
          <p className="mb-3">
            6.4 If you are exercising your right to change your mind, the
            following shall apply to your refund:
            <ul className="list-disc ml-8 mt-2">
              <li>
                if the Products are Goods, your refund will be made within 14
                days from the date on which we receive the Goods back from you;
                and
              </li>
              <li>
                in all other cases, your refund will be made within 14 days of
                you telling us you have changed your mind.
              </li>
            </ul>
          </p>
          <p className="mb-3">
            6.5 The right of cancellation does not apply to:
            <ul className="list-disc ml-8 mt-2">
              <li>
                Digital Content, after you have started to download or stream
                these, or if you accepted when you placed your order that we
                could start to deliver it, and that you could not cancel it once
                delivery started;
              </li>
              <li>services, once these have been completed;</li>
              <li>
                personalised, bespoke or sealed (once unsealed) Goods; and
              </li>
              <li>
                Goods which become mixed inseparably with other items after
                their delivery.
              </li>
            </ul>
          </p>
          <p className="mb-3">
            6.6 We will pay the costs of return:
            <ul className="list-disc ml-8 mt-2">
              <li>if the Goods are faulty or misdescribed; or</li>
              <li>
                if you are ending the Sales Contract because we have informed
                you of an upcoming change to the Product or the Sales Terms, an
                error in pricing or description, a delay in delivery due to
                events outside of our control, or because you have a legal right
                to do so as a result of something we have done wrong.
              </li>
            </ul>
            In all other cases, returns are made at your expense.
          </p>
          <p className="mb-3">
            6.7 If a Product is faulty or misdescribed, you may have a legal
            right to end the Sales Contract and receive a refund. If you think
            there is something wrong with your Product, you must contact our
            Customer Service Team.
          </p>
          <p className="mb-3">
            6.8 You have the right to end the Sales Contract for the reasons set
            out below. In such circumstances, the Sales Contract will immediacy
            end and we will refund you in full for any Products which have not
            been provided. The reasons are:
            <ul className="list-disc ml-8 mt-2">
              <li>
                we have told you about an error in the price or description of
                the Product you ordered and you do not wish to proceed;
              </li>
              <li>
                there is a risk that the supply of the Products may be
                significantly delayed because of events outside our control; or
              </li>
              <li>
                you have a legal right to end the Sales Contract because of
                something we have done wrong.
              </li>
            </ul>
          </p>

          <h3 className="text-lg font-semibold mt-6 mb-3">
            Returns and refunds (Businesses)
          </h3>
          <p className="mb-3">
            7.1 Unless otherwise agreed, Products are supplied on a firm sale
            basis. Where we have agreed to supply physical Products on a sale or
            return basis, condition 7.2 to condition 7.5 inclusive shall, unless
            we agree otherwise, apply to such return.
          </p>
          <p className="mb-3">
            7.2 Prior written authorisation for returned Products must be
            obtained from us by business customers either in writing or
            electronically under the Industry Returns Initiative. Prior written
            authorisation does not confer automatic credit for return if you
            fail to comply with remaining provisions of our return policy. For
            authorisation requests placed by electronic means under the Returns
            Initiative, the rules of that Initiative will apply. These rules are
            published on the Book Industry Communication website
            (http://www.bic.org.uk).
          </p>
          <p className="mb-3">
            7.3 Returned Goods will not be credited against your invoice or
            account unless they are in perfect/unused condition and are
            accompanied by a signed returns authorisation note that corresponds
            to the Goods returned. ISBN and full details of the Goods proposed
            to be returned must be provided in writing. The relevant invoice
            numbers for the returned Goods must be quoted wherever possible.
          </p>
          <p className="mb-3">
            7.4 Unauthorised returns will not be sent back to you and will not
            be credited.
          </p>
          <p className="mb-3">
            7.5 All returns are made at your expense and we will not accept any
            charges levied by you or any shipping or transport agents. We are
            not liable for any returns lost in transit. Returns remain the
            responsibility and property of you until receipt by us in our
            warehouse. You are liable for any shortages in or damages to the
            Goods during transit based on our sole determination.
          </p>

          <h3 className="text-lg font-semibold mt-6 mb-3">
            Contract termination (Businesses)
          </h3>
          <p className="mb-3">
            8.1 Without limiting any other rights contained in these Sale Terms,
            we may suspend the supply or delivery or the Products to you, or
            terminate the Sales Contract with immediate effect by giving notice
            in writing if you:
            <ul className="list-disc ml-8 mt-2">
              <li>
                commit a material breach of any term in the Sale Terms and (if
                such breach is remediable) fail to remedy that breach within 30
                days of you being notified to do so;
              </li>
              <li>
                you fail to pay any amount due under the Sales Contract on the
                due date for payment;
              </li>
              <li>
                you suspend, threaten to suspend, cease or threaten to cease to
                carry on all or a substantial part of your business; or
              </li>
              <li>
                your financial position deteriorates to such an extent that in
                our reasonable opinion your capability to adequality fulfil your
                obligations under the Sales Contract has been placed in
                jeopardy.
              </li>
            </ul>
          </p>

          <h3 className="text-lg font-semibold mt-6 mb-3">
            Subscription cancellation
          </h3>
          <p className="mb-3">
            9.1 We will tell you when and how you can end a subscription
            contract with us during the order process and we will confirm this
            information to you in writing after we've accepted your order. If
            you have any questions, please contact us.
          </p>
          <p className="mb-3">
            9.2 If you end a subscription contract for any reason after Products
            have been dispatched to you or you have received them, you must
            return the Products to us at your own expense.
          </p>

          <h3 className="text-lg font-semibold mt-6 mb-3">Liability</h3>
          <p className="mb-3">
            10.1 No party excludes or limits its liability to the other for:
            <ul className="list-disc ml-8 mt-2">
              <li>
                death or personal injury caused by its own negligence, or the
                negligence of its employees, agents, or subcontractors (as
                applicable);
              </li>
              <li>fraud or fraudulent misrepresentation; and</li>
              <li>
                any other matter for which it would be unlawful to exclude or
                restrict liability.
              </li>
            </ul>
          </p>
          <p className="mb-3">
            10.2 Subject to condition 10.1 above, our liability to you in
            respect of any and all claims, whether in contract, delict, tort,
            breach of statutory duty, or otherwise in connection with these Sale
            Terms is (to the extent permitted by law) limited to and shall not
            exceed the price of the Products.
          </p>
          <p className="mb-3">
            10.3 Subject to condition 10.1, we will not be liable to any
            customer, in respect of any and all claims, whether in contract,
            delict. tort (including negligence), breach of statutory duty or
            otherwise, arising under or in connection with any Sales Contract
            between us for:
            <ul className="list-disc ml-8 mt-2">
              <li>loss of profits, sales, business, contracts or revenue;</li>
              <li>loss of anticipated savings;</li>
              <li>loss or corruption of software, data or information;</li>
              <li>business interruption; or</li>
              <li>any indirect or consequential loss.</li>
            </ul>
          </p>
          <p className="mb-3">
            10.4 Only for business customers, we do not make or give any
            warranty, representation or undertaking as to the quality of the
            Products, their correspondence with description or fitness for a
            particular purpose, that the Products are not defamatory, injurious,
            obscene, unlawful or in breach of copyright or in any other manner
            whatsoever. Except to the extent expressly stated in these Sale
            Terms, we exclude all terms implied by sections 13 to 15 of the
            Sales of Goods Act 1979 and sections 3 to 5 of the Supply of Goods
            and Services Act 1982.
          </p>
          <p className="mb-3">
            10.5 Only for individual consumers, subject to provisions contained
            in this condition 10, we will be responsible for losses you suffer
            caused by us breaking this Sales Contract unless the loss is
            avoidable. We shall not be responsible if the loss was something you
            could have avoided by taking reasonable action. For example, damage
            to your own Digital Content or device which could have been avoided
            by following our advice to apply a free update or by correctly
            following the installation instructions or having the minimum system
            requirements advised by us.
          </p>

          <h3 className="text-lg font-semibold mt-6 mb-3">Claims</h3>
          <p className="mb-3">
            Any claims for damage to Goods in transit, or shortage in Goods
            delivered, must be notified to both the relevant carrier and us
            within seven 7 days after the date of delivery (packaging and
            contents to be held for inspection). Any other claims for credit
            must be notified to us within twenty-eight 28 days after the date of
            delivery. On no account will claims be considered if notified
            outside these periods. You must afford us or our agents opportunity
            to verify any shortage and/or inspect any damaged Goods as
            delivered.
          </p>

          <h3 className="text-lg font-semibold mt-6 mb-3">
            Withdrawal of products
          </h3>
          <p className="mb-3">
            We may write to you to let you know that we are going to stop
            providing a Product. We will refund you any sums you have paid in
            advance for Products which will not be provided.
          </p>

          <h3 className="text-lg font-semibold mt-6 mb-3">
            Third party rights
          </h3>
          <p className="mb-3">
            13.1 Neither us nor you intend any term of the Sales Contract to be
            enforceable pursuant to the Contracts (Rights of Third Parties) Act
            1999.
          </p>

          <h3 className="text-lg font-semibold mt-6 mb-3">Communications</h3>
          <p className="mb-3">
            14.1 When we refer to "in writing", this includes email.
          </p>
          <p className="mb-3">
            14.2 Any notice given under or in connection with the Sales Contract
            must be in writing and be delivered by hand, sent by pre-paid first
            class post or other next working day delivery service, or email. Any
            notice or communication shall take effect:
            <ul className="list-disc ml-8 mt-2">
              <li>if delivered, upon delivery;</li>
              <li>
                if sent by first class registered post, at 10am on the second
                business day after posting; and
              </li>
              <li>
                if sent by facsimile or electronic mail, when a complete and
                legible copy of the communication, received at the appropriate
                address.
              </li>
            </ul>
          </p>

          <h3 className="text-lg font-semibold mt-6 mb-3">
            Unforeseeable circumstances
          </h3>
          <p className="mb-3">
            15.1 We shall have the right to cancel or delay deliveries or
            performance or to reduce the quantity of Goods delivered and shall
            under no circumstances be responsible for failure or delay in
            performing or fulfilling any relevant Sales Contract or otherwise
            failing to implement its obligations to you if such failure or delay
            shall be due to any cause or circumstance beyond our reasonable
            control.
          </p>
          <p className="mb-3">
            15.2 Such cause or circumstance shall include, but shall not be
            limited to, fire, flood, riot, terrorism, strike, freight embargoes
            or transportation delays, shortage of labour, inability to procure
            or secure fuel, material, supplies or power at current prices or on
            account of shortages thereof, pandemic, epidemic and/or other health
            related emergencies, acts of God or of a public enemy, or any
            existing or future laws or acts of H.M. Government or the government
            of any other state or territory (including specifically but not
            exclusively any orders, rules or regulations issued by any official
            or agency of H.M. Government or of any other such government)
            affecting the conduct of our business with which we, in our sole
            discretion, deems it advisable to comply as a legal duty.
          </p>

          <h3 className="text-lg font-semibold mt-6 mb-3">General</h3>
          <p className="mb-3">
            16.1 You are not entitled to assign or transfer any of your rights
            or purport to assign any of your obligations under these Sale Terms
            without our prior written consent. We may, at any time, assign,
            sublicense, transfer or otherwise dispose of all or any of our
            rights or obligations under the Sale Terms.
          </p>
          <p className="mb-3">
            16.2 You acknowledge and agree that the Sales Contract and any
            business, operational, financial or sensitive information disclosed
            to you pursuant to the Sales Contract or these Sale Terms is
            confidential and you shall not disclose such confidential
            information to any third party without our written consent.
          </p>
          <p className="mb-3">
            16.3 If all or any provisions in these Sale Terms shall be or become
            illegal, invalid or unenforceable in any respect, then the remainder
            of such provision and/or all other provisions shall remain valid and
            enforceable and the remaining liabilities of the parties shall not
            be affected or impaired.
          </p>
          <p className="mb-3">
            16.4 No delay in exercising or non-exercise of any right, power or
            remedy provided by law or under these Sale Terms shall impair or
            otherwise operate as a waiver or release of that right, power or
            remedy. Any single or partial exercise of any right, power or remedy
            provided by law or under these Sale Terms shall not preclude any
            other or further exercise of that right or the exercise of any other
            right, power or remedy.
          </p>
          <p className="mb-3">
            16.5 Please refer to our Privacy policy to know how we use and store
            your personal data.
          </p>
          <p className="mb-3">
            16.6 We have the right to end our Sales Contract with you at any
            time if you are in breach of it.
          </p>
          <p className="mb-3">
            16.7 We reserve the right to vary these Sale Terms without notice at
            our sole discretion.
          </p>
          <p className="mb-3">
            16.8 Termination of the Sales Contract shall not affect your or our
            rights and remedies that have accrued as at termination.
          </p>
          <p className="mb-3">
            16.9 These Sales Terms and our Sale Contract are governed by and
            construed in accordance with the laws of England and all disputes
            shall be submitted to the exclusive jurisdiction of the English
            courts.
          </p>

          {/* Platform Terms section */}
          <h2 className="text-xl font-bold mt-8 mb-4">(iii) Platform Terms</h2>

          <h3 className="text-lg font-semibold mt-6 mb-3">Introduction</h3>
          <p className="mb-3">
            1.1 These terms and conditions set out the terms on which we make
            available each platform owned and operated by us (each a "Platform"
            and together the "Platforms"). Please read these terms of use
            carefully before you start to use any of our Platforms. By using any
            of our Platforms, you confirm that you accept and agree to abide by
            these terms ("Platform Terms"). If you are an individual using a
            Platform as part of an organisation, you represent that you have
            authority to bind the organisation to these Platform Terms. If you
            do not agree to these Platform Terms, please refrain from using our
            Platforms. We may revise the Platform Terms from time to time. Such
            variation will be notified to you, either via the Platform or by
            email.
          </p>
          <p className="mb-3">
            1.2 These Platform Terms should be read in conjunction with our
            Privacy policy.
          </p>
          <p className="mb-3">
            1.3 Acceptance of these Platform Terms shall form a binding
            agreement between us and you. For the purposes of these Platform
            Terms, you (meaning the local authority, academy trust, educational
            establishment, other institution or the individual that accesses the
            Platform) shall be referred to as the "Customer".
          </p>

          <h3 className="text-lg font-semibold mt-6 mb-3">Access</h3>
          <p className="mb-3">
            2.1 We authorise the Customer to access the Platform and the
            material, resources and online tools available on the Platform
            ("Platform Content"), subject to the Platform Terms. This authority
            extends to everyone the Customer authorises to access the Platform
            (including any staff or students, in the case of an educational
            establishment) ("Authorised User(s)"). Unless otherwise approved by
            us in advance, Authorised Users must be part of the same educational
            establishment as the Customer.
          </p>
          <p className="mb-3">
            2.2 This authority starts when the Customer enters into this
            agreement and ends when the Customer's subscription period
            ("Subscription Period") or free trial period ("Free Trial Period")
            ends, or when the agreement is terminated in accordance with these
            Platform Terms.
          </p>

          <h3 className="text-lg font-semibold mt-6 mb-3">User information</h3>
          <p className="mb-3">
            3.1 The Customer shall ensure that Authorised users keep their
            passwords for the Platform secure.
          </p>
          <p className="mb-3">
            3.2 The Customer shall nominate an Authorised user to be the lead
            account holder ("Lead Account Holder") for the Customer and the Lead
            Account Holder will be our point of contact in relation to this
            agreement. The Customer must ensure that the Lead Account Holder has
            been informed about, and consented to, their name, title, department
            (if applicable) and email being shared with any Authorised User
            within the Customer's organisation who needs to be told the Lead
            Account Holder's name to register for the Platform.
          </p>
          <p className="mb-3">
            3.3 The Lead Account Holder(s) shall:
            <ul className="list-disc ml-8 mt-2">
              <li>
                ensure that the Customer name, address, billing and other
                contract details are correct and complete and are promptly
                updated as required;
              </li>
              <li>
                ensure that only Authorised Users have access to the Platform;
              </li>
              <li>
                promptly end an Authorised User's access to the Platform if the
                Authorised User ceases to require access to the Platform,
                including in the event that such individual ceases to be a
                member of staff, employee or student; and
              </li>
              <li>
                keep an up-to-date list of Authorised Users and take reasonable
                steps to ensure that Authorised Users keep their contact details
                up-to-date.
              </li>
            </ul>
          </p>

          <h3 className="text-lg font-semibold mt-6 mb-3">Usage</h3>
          <p className="mb-3">
            4.1 Subject to the Customer complying with all obligations under the
            Platform Terms, we shall grant to the Customer during the
            Subscription Period or the Free Trial Period (as applicable), a
            non-exclusive licence to access, and permit only the Authorised
            Users to access, the Platform and the Platform Content at any time
            for teaching and private study purposes;
          </p>
          <p className="mb-3">
            4.2 The authority granted above is limited to the Customer and,
            where stated, Authorised Users, and does not extend to any
            subsidiary or parent organisation of the Customer, or to any other
            related affiliated organisations, including affiliated schools or
            academy trusts.
          </p>

          <h3 className="text-lg font-semibold mt-6 mb-3">Information</h3>
          <p className="mb-3">
            5.1 The Platform and the Platform Content are provided for
            information purposes only and the Customer relies on the Platform
            and the Platform Content at its own risk.
          </p>
          <p className="mb-3">
            5.2 We do not make any representation, warranty or guarantee of any
            kind, either express or implied in relation to the Platform, the
            Platform Content, or any products and services provided through it
            including, without limitation, any warranties in relation to fitness
            for a particular purpose or in relation to the quality,
            completeness, accuracy or reliability of the Platform and Platform
            Content, or any products and services provided through it.
          </p>

          <h3 className="text-lg font-semibold mt-6 mb-3">Restrictions</h3>
          <p className="mb-3">
            6.1 The Customer shall not (and shall ensure each Authorised User
            shall not):
            <ul className="list-disc ml-8 mt-2">
              <li>
                use the Platform in any improper or unlawful manner or for
                improper or unlawful purposes;
              </li>
              <li>
                act in a manner that would, or may, cause damage to us, the
                Platform or any other third party;
              </li>
              <li>
                use the Platform to transmit any viruses, worms, defects, trojan
                horses or other malicious code or items of a destructive nature;
              </li>
              <li>
                otherwise use the Platform in breach of the Platform Terms; or
              </li>
              <li>
                copy, adapt, reverse engineer, decompile, disassemble or modify
                (or undertake a similar or equivalent act in respect of) the
                Platform in whole or in part.
              </li>
            </ul>
          </p>
          <p className="mb-3">
            6.2 Unless our express written consent is obtained, the Customer
            shall not (and shall ensure each Authorised User shall not):
            <ul className="list-disc ml-8 mt-2">
              <li>
                sell, distribute, license, rent or otherwise exploit the
                Platform, any element of the Platform Content, or any derivative
                work for any commercial use;
              </li>
              <li>
                make the Platform or the Platform Content available on or by,
                electronic bulletin boards, news groups, website or any other
                means of posting of transmitting material on the internet, an
                online service or a wide area network;
              </li>
              <li>
                remove or obscure our copyright notice from the Platform Content
                including, but is not limited to, hardcopy print-outs;
              </li>
              <li>
                use the Platform or the Platform Content to create any
                derivative work, product or service, or merge the Platform or
                the Platform Content with any other product, database, or
                service, except use within your virtual learning environment or
                institutional intranet, if applicable;
              </li>
              <li>
                share the Platform Content with, or disclose or make available
                the Platform Content to, anyone other than the Authorised Users;
                or
              </li>
              <li>
                alter, amend, modify, translate or change the Platform Content.
              </li>
            </ul>
          </p>

          <h3 className="text-lg font-semibold mt-6 mb-3">Obligations</h3>
          <p className="mb-3">
            7.1 The Customer shall inform Authorised Users of the user
            restrictions and other provisions set out in the Platform Terms and
            shall ensure that Authorised Users comply with the Platform Terms.
          </p>
          <p className="mb-3">
            7.2 The Customer shall notify us immediately of any infringement (or
            suspected infringements) of the Platform Terms that comes to the
            Customer's attention (whether the infringement is an act or omission
            of the Customer or an Authorised User) and the Customer agrees to
            cooperate with us as appropriate to stop further infringement should
            it occur. We may suspend or terminate a Customer's account if we
            deem appropriate (at our discretion).
          </p>
          <p className="mb-3">
            7.3 The Customer shall be liable for any breach of the Platform
            Terms by any Authorised User. The Customer undertakes to ensure that
            any breach of the Platform Terms by an Authorised User is not
            allowed to continue after the Customer has become aware of such
            breach, whether from us or otherwise.
          </p>
          <p className="mb-3">
            7.4 We shall be entitled to monitor the Customer's use of the
            Platform through our servers in order to monitor the Customer's
            compliance with these Platform Terms.
          </p>
          <p className="mb-3">
            7.5 The Customer shall maintain appropriate cybersecurity and
            general security measures and procedures in order to adequately
            protect the Platform and Platform Content and prevent any
            unauthorised access to, or disclosure thereof.
          </p>

          <h3 className="text-lg font-semibold mt-6 mb-3">
            Intellectual Property
          </h3>
          <p className="mb-3">
            8.1 We and our licensors own all intellectual property rights in and
            to the Platform (including the Platform Content and any copies of
            Platform Content made by the Customer or Authorised Users). No
            rights in or to any of the same shall be transferred to the Customer
            or any Authorised Users under these Platform Terms. The Customer
            acknowledges that any rights not expressly granted to the Customer
            under the Platform Terms are reserved by us.
          </p>
          <p className="mb-3">
            8.2 Where the Customer and/or any Authorised Users upload content
            containing their intellectual property rights to the Platform, the
            Customer and/or Authorised Users grant us a non-exclusive,
            perpetual, royalty-free, worldwide licence to copy, sublicense,
            adapt, create derivative works from, republish and in any way
            distribute in any format any such content for the following reasons:
            <ul className="list-disc ml-8 mt-2">
              <li>
                as part of, or promotion for, our services including the
                Platform;
              </li>
              <li>
                to track activity on the Platform during the Subscription Period
                or Free Trial Period (as applicable) to carry out the Usage
                Analysis; and
              </li>
            </ul>
          </p>
          <p className="mb-3">
            8.3 In addition to condition 8.2 above, if the Customer and/or any
            Authorised Users upload or submit content to our Platform, they
            agree to be bound by our Terms of Acceptable Use, as set out in the
            Website Terms of Use.
          </p>
          <p className="mb-3">
            8.4 We are under no obligation to compensate the Customer and/or any
            Authorised Users for any use we make of their intellectual property
            rights.
          </p>

          <h3 className="text-lg font-semibold mt-6 mb-3">
            Termination and Suspension
          </h3>
          <p className="mb-3">
            9.1 The agreement between us and the Customer shall continue for the
            duration of the Subscription Period, or the Free Trial Period, as
            applicable, unless terminated earlier in accordance with these
            Platform Terms.
          </p>
          <p className="mb-3">
            9.2 A Subscription Period may be renewed by the Customer on a
            rolling basis, subject to payment of the applicable subscription
            fee.
          </p>
          <p className="mb-3">
            9.3 We may suspend, terminate or restrict the Customer's access to
            the Platform with immediate effect on written notice to the
            Customer, without liability or the obligation to reimburse any
            subscription fee, if we believe that the Customer (or any Authorised
            User) is responsible for the Platform being used in a manner that is
            in breach of these Platform Terms. In these circumstances, any
            continuation of a Subscription Period or a Free Trial Period shall
            be at our sole discretion.
          </p>
          <p className="mb-3">
            9.4 We may suspend, terminate or restrict the Customer's access to
            the Platform with immediate effect where we deem it necessary,
            including, without limitation: (i) where we identify or suspect that
            the Customer's use (and/or any Authorised User's use) of the
            Platform causes or may impact on the stability or security of the
            Platform; and (ii) in cases where we suspect misuse of the Platform.
            In the event of such suspension, we may carry out further
            investigation, and, at our discretion either: (i) end the
            suspension; or (ii) terminate the Customer's rights to use.
          </p>
          <p className="mb-3">
            9.5 In addition to the above, we may terminate the Customer's access
            to the Platform, without cause:
            <ul className="list-disc ml-8 mt-2">
              <li>
                at any time during a Free Trial Period, immediately upon written
                notice to the Customer; and
              </li>
              <li>
                at any time during the Subscription Period upon 30 days' written
                notice to the Customer.
              </li>
            </ul>
          </p>
          <p className="mb-3">
            9.6 If we terminate in accordance with condition in 9.5 above, we
            shall repay to the Customer any proportion of the subscription fee
            that has been paid by the Customer in respect of any period of time
            after the date of termination.
          </p>
          <p className="mb-3">
            9.7 Upon termination for any reason:
            <ul className="list-disc ml-8 mt-2">
              <li>
                all rights and licenses granted to the Customer under the
                Platform Terms shall cease;
              </li>
              <li>
                the Customer must cease all activities authorised by the
                Platform Terms, including accessing the Platform;
              </li>
              <li>
                the Customer shall destroy, and will instruct all Authorised
                Users to destroy, all Platform Content stored on any digital
                information storage media including, but not limited to, system
                servers, hard disks, diskettes, memory sticks, recorded discs,
                and back up tapes, but excluding test and assessment results
                that have been downloaded by Authorised Users; and
              </li>
              <li>
                the Customer shall return or destroy (and will instruct all
                Authorised Users to return or destroy) any printed copies of the
                Platform Content.
              </li>
            </ul>
          </p>

          <h3 className="text-lg font-semibold mt-6 mb-3">Subscription fees</h3>
          <p className="mb-3">
            10.1 The Customer shall pay to us the subscription fee for a
            Subscription Period at the point of purchase, without which they
            will have no access to the Platform.
          </p>

          <h3 className="text-lg font-semibold mt-6 mb-3">Liability</h3>
          <p className="mb-3">
            11.1 Neither party excludes or limits its liability to the other
            party for:
            <ul className="list-disc ml-8 mt-2">
              <li>
                death or personal injury caused by its own negligence, or the
                negligence of its employees, agents, or subcontractors (as
                applicable);
              </li>
              <li>fraud or fraudulent misrepresentation; and</li>
              <li>
                any other matter for which it would be unlawful to exclude or
                restrict liability.
              </li>
            </ul>
          </p>
          <p className="mb-3">
            11.2 Subject to condition 11.1 above, our liability to the Customer
            in respect of any and all claims, whether in contract, delict, tort,
            breach of statutory duty, or otherwise in connection with these
            Platform Terms is (to the extent permitted by law) limited in
            respect of each event or series of connected events to the lesser of
            £1,000 or an amount equal to the subscription fee payable by the
            Customer in the twelve-month period immediately preceding the events
            giving rise to the claim.
          </p>
          <p className="mb-3">
            11.3 Subject to condition 11.1, we will not be liable to the
            Customer, whether in contract, tort (including negligence), breach
            of statutory duty, or otherwise, even if foreseeable, arising under
            or in connection with these Platform Terms for:
            <ul className="list-disc ml-8 mt-2">
              <li>
                any loss of profits, sales, business, contracts or revenue;
              </li>
              <li>loss of anticipated savings;</li>
              <li>loss or corruption of software, data or information;</li>
              <li>business interruption; or</li>
              <li>any other indirect or consequential loss or damage.</li>
            </ul>
          </p>
          <p className="mb-3">
            11.4 In addition to 11.3 above, we shall not be liable to the
            Customer from any claim arising from:
            <ul className="list-disc ml-8 mt-2">
              <li>
                omissions or inaccuracies in the Platform or Platform Content,
                regardless of how caused;
              </li>
              <li>
                any failure or malfunction resulting wholly or to any extent
                from the Customer's negligence, operator error, or use, other
                than in accordance with these Platform Terms or any user
                documentation provided by us from time to time, or any other
                misuse or abuse of the Platform or the Platform Content;
              </li>
              <li>
                the failure by the Customer to implement recommendations or
                solutions previously advised by us in respect of defaults in the
                Platform or Platform Content;
              </li>
              <li>
                the modification of the Platform or Platform Content or merger
                with any other program or any maintenance, repair, adjustment,
                alteration or enhancement of the Platform or Platform Content by
                any person other than us or our authorised agent(s); or
              </li>
              <li>
                the Customer or any Authorised Users being unable to use the
                Platform due to the Platform being unavailable as a result of
                any act or omission of us, provided that the period for which
                the Platform is not available shall not exceed a prior of 100
                hours (in aggregate) in any continuous period of 1000 hours.
              </li>
            </ul>
          </p>

          <h3 className="text-lg font-semibold mt-6 mb-3">Data protection</h3>
          <p className="mb-3">
            12.1 We and the Customer shall both comply with the provisions of
            any applicable data protection and privacy legislation in force from
            time to time in the United Kingdom including: (i) the retained EU
            law version of the General Data Protection Regulation ((EU)
            2016/679) (UK GDPR); (ii) the Data Protection Act 2018 (DPA 2018)
            (and regulations made thereunder); (iii) the Privacy and Electronic
            Communications Regulations 2003 (SI 2003/2426) as amended; and (iv)
            the guidance and codes of practice issued by the Information
            Commissioner (collectively, the Data Protection Legislation).
            Personal Data, Data Subject, Data Controller and Data Processor
            shall have the same meaning as under the Data Protection
            Legislation.
          </p>
          <p className="mb-3">
            12.2 Without prejudice to the generality of condition 12.1, the
            Customer will ensure that it has all necessary appropriate consents
            and notices (including but not limited to consents from Authorised
            Users (and where required, written consents from parents or
            guardians) in place to enable the lawful transfer and processing of
            Personal Data to us and any Data Partner for the duration of the
            processing (as set out below at condition 12.6), as applicable, for
            the purpose of the Customer's access to the Platform and our use of
            Pupil Data in accordance with these Terms.
          </p>
          <p className="mb-3">
            12.3 We may notify the Customer via the Platform that we and/or our
            Data Partners (defined below) are able to facilitate data syncing
            services, whereby Customer data (e.g. school, pupil and staff data)
            is synced to the Platform and/or data (including but not limited to
            pupil assessment data on the Platform) is synced to the Managed
            Information System (MIS) database, data exchange or data tracker
            platform used by a School. If the Customer elects to use any such
            synchronisation functions, the Customer agrees that we and/or our
            third party partners shall transfer the relevant data to and from
            the Platform in accordance with the Customer's instructions. "Data
            Partner" is a third party with whom we have a contractual
            arrangement to facilitate the synchronisation of Customer data
            and/or data (including but not limited to pupil assessment data on
            the Platform) to and from the Platform if requested by the Customer.
          </p>
          <p className="mb-3">
            12.4 Both parties shall obtain and maintain throughout the
            Subscription Period, or the Free Trial Period, as applicable, all
            necessary registrations and notifications that such party is obliged
            to obtain and maintain in accordance with the Data Protection
            Legislation in respect of providing or using the Platform.
          </p>
          <p className="mb-3">
            12.5 In making the Platform available to the Customer, we may
            process personal data (including "Pupil Data", meaning any
            information about, or data relating to, pupils that is entered onto
            our Platforms by Authorised Users, including, without limitation,
            assessment data and questionnaire responses) on the Customer's
            behalf. The table below sets out how we will process personal data
            for each Platform. The parties acknowledge that, save as otherwise
            provided in condition 12.10, for the purposes of the Data Protection
            Legislation, the Customer is the Data Controller and we are the Data
            Processor in respect of such Personal Data.
          </p>
          <p className="mb-3">
            12.6 We hold data processed via our Platform for the duration of the
            Subscription Period or the Free Trial Period or as long as the data
            subject continues to add test data (unless the data subject asks us
            to delete it earlier).
          </p>
          <p className="mb-3">
            12.7 when processing Personal Data as a Data Processor on behalf of
            the Customer, we shall in relation to any Personal Data processed in
            connection with the performance of our obligations under these
            Platform Terms:
            <ul className="list-disc ml-8 mt-2">
              <li>
                process all such Personal Data only in accordance with the
                written instructions given by the Customer from time to time
                unless we are required by any applicable laws to process the
                Personal Data;
              </li>
              <li>
                take all reasonable steps to ensure that any of our employees,
                agents and sub-contractors who have access to such Personal Data
                are obliged to keep the Personal Data confidential;
              </li>
              <li>
                ensure that we have in place appropriate technical and
                organisational measures, to protect against unauthorised or
                unlawful processing of Personal Data and against accidental loss
                or destruction of, or damage to, Personal Data, appropriate to
                the harm that might result from the unauthorised or unlawful
                processing or accidental loss, destruction or damage and the
                nature of the data to be protected, having regard to the state
                of technological development and the cost of implementing any
                measures;
              </li>
              <li>
                not transfer any such Personal Data to any country outside the
                European Economic Area without the prior written consent of the
                Customer (such consent not to be unreasonably withheld) or
                unless the following conditions are fulfilled:
                <ul className="list-disc ml-8 mt-2">
                  <li>
                    either we or the Customer has provided appropriate
                    safeguards in relation to the transfer;
                  </li>
                  <li>
                    the data subject has enforceable rights and effective legal
                    remedies;
                  </li>
                  <li>
                    we comply with our obligations under the Data Protection
                    Legislation by providing an adequate level of protection to
                    any Personal Data that is transferred; and
                  </li>
                  <li>
                    we comply with reasonable instructions notified to us in
                    advance by the Customer with respect to the processing of
                    the Personal Data;
                  </li>
                </ul>
              </li>
              <li>
                assist the Customer, at the Customer's request, in responding to
                any request from a data subject in respect of their rights under
                Data Protection Legislation;
              </li>
              <li>
                not use any such Personal Data for any purpose that is not
                authorised under these Platform Terms;
              </li>
              <li>
                assist the Customer in ensuring and demonstrating compliance
                under the Data Protection Legislation with respect to security,
                breach notifications, impact assessments and consultations with
                supervisory authorities or regulators (such assistance shall be
                at the reasonable cost of the Customer);
              </li>
              <li>
                notify the Customer without undue delay on becoming aware of a
                Personal Data breach;
              </li>
              <li>
                at the written direction of the Customer, delete or return
                Personal Data and copies thereof to the Customer and shall
                procure that any sub- processor shall do the same, unless
                required by any applicable laws to store the Personal Data;
              </li>
              <li>
                maintain complete and accurate records and information to
                demonstrate our compliance with Data Protection Legislation and
                allow for and contribute to audits, conducted by the Customer or
                another auditor mandated by the Customer, provided: (i) the
                audit shall not take place more than once in a calendar year;
                (ii) the Customer provides reasonable notice of their intention
                to carry out such audit; (iii) the audit takes place during
                business hours; and iv) the audit shall be limited to our
                records and information only in relation to our compliance with
                Data Protection Legislation. Unless otherwise agreed in writing,
                the Customer shall have no other audit rights.
              </li>
            </ul>
          </p>
          <p className="mb-3">
            12.8 We use and the Customer hereby approves the appointment of the
            sub- processors (powered by AWS - Data centre and cloud provider of
            virtual computer power and software services) to undertake
            processing of Personal Data on behalf of the Customer, provided that
            we have entered into with such third- party processor(s) a written
            agreement incorporating terms relating to required compliance with
            Data Protection Legislation which are substantially similar to those
            set out in these Platform Terms. As between the Customer and us, we
            shall remain liable for all acts or omissions of any third-party
            sub-processor appointed by us. We shall inform the Customer of any
            intended changes concerning the addition or replacement of any
            material sub-processors by providing 30 days' notice before such
            change takes effect, thereby giving the Customer the opportunity to
            object to such changes (such objection not to be made unreasonably).
          </p>
          <p className="mb-3">
            12.9 The Customer acknowledges and agrees that we may make the
            Personal Data of the Customer's Authorised Users available on our
            Platforms to other of the Customer's Authorised Users for the
            purposes of: (i) registering a new Authorised User; or (ii) sharing
            Customer content within a group of Customer's Authorised Users in
            the course of providing the Services.
          </p>
          <p className="mb-3">
            12.10 We may process Personal Data obtained direct from the Customer
            or Authorised Users as a Data Controller:
            <ul className="list-disc ml-8 mt-2">
              <li>
                where such processing is necessary for our legitimate business
                interests as a supplier of Platforms and where such legitimate
                interests do not endanger the rights and freedoms of Data
                Subjects as described in our privacy notice made available to
                the Customer and any Authorised User at the time of collection
                of such Personal Data;
              </li>
              <li>
                where such processing is necessary for the performance of a
                contract with the Customer; or
              </li>
              <li>
                where the Customer or Authorised User (as applicable) has
                provided consent to such processing.
              </li>
            </ul>
          </p>
          <p className="mb-3">
            12.11 We shall process personal data in accordance with our Privacy
            policy and Cookie Policy when acting as a Data Controller. For
            further information on how we handle personal information, please
            review our Privacy policy.
          </p>

          <h3 className="text-lg font-semibold mt-6 mb-3">
            Unforeseen circumstances
          </h3>
          <p className="mb-3">
            13.1 We shall under no circumstances be responsible for any failure
            or delay in performing our obligations under these Platform Terms if
            such failure or delay is due to any cause or circumstance beyond our
            reasonable control.
          </p>
          <p className="mb-3">
            13.2 Such cause or circumstance shall include, but shall not be
            limited to, fire, flood, riot, terrorism, war strikes, floods,
            governmental restrictions, power failures, or damage or destruction
            of any network facilities or services.
          </p>

          <h3 className="text-lg font-semibold mt-6 mb-3">Disclaimers</h3>
          <p className="mb-3">
            14.1 We do not guarantee that the Platform will always be error free
            or that the Platform or the server that makes it available are or
            will always be free of viruses or other harmful components.
          </p>
          <p className="mb-3">
            14.2 The Customer is responsible for configuring its information
            technology and computer programmes to access the Platform. The
            Customer should use its own virus protection software.
          </p>
          <p className="mb-3">
            14.3 We will not issue any credits or refunds against charges
            incurred by the Customer in relation to any communications network
            or service, including the internet, or those incurred in relation to
            contacting us or using the Support Services. We are not responsible
            for any technical support requirements arising from the Customer's
            use of any hardware, software or communications network or service
            used to access the Platform.
          </p>
          <p className="mb-3">
            14.4 Whilst we use reasonable endeavours to provide the Customer
            with as much notice as practicable in advance of any planned
            maintenance works in respect of the Platform by email or by posting
            a notice on the Platform, the Customer acknowledges and agrees that
            it may not always be possible for us to give notice of any unplanned
            maintenance works.
          </p>
          <p className="mb-3">
            14.5 We shall not be responsible for any default in the Platform
            which is caused by the Customer or any Authorised User when using
            the Platform contrary to our instructions.
          </p>

          <h3 className="text-lg font-semibold mt-6 mb-3">General</h3>
          <p className="mb-3">
            15.1 The Customer shall not assign or transfer any of its rights or
            purport to assign any of its obligations under these Platform Terms
            without our prior written consent. We may, at any time, assign,
            sublicense, transfer or otherwise dispose of all or any of our
            rights or obligations under the Platform Terms.
          </p>
          <p className="mb-3">
            15.2 Each party undertakes that it shall not at any time disclose to
            any person any confidential information that has been disclosed to
            it by the other party or that it has otherwise acquired in
            connection with Platform or the agreement between the parties.
          </p>
          <p className="mb-3">
            15.3 If any or all provisions in these Platform Terms shall be or
            become illegal, invalid or unenforceable in any respect, then the
            remainder of such provision and/or all other provisions shall remain
            valid and enforceable, and the remaining liabilities of the parties
            shall not be affected or impaired.
          </p>
          <p className="mb-3">
            15.4 No delay in exercising or non-exercise of any right, power or
            remedy provided by law or under these Platform Terms shall impair or
            otherwise operate as a waiver or release of that right, power or
            remedy. Any single or partial exercise of any right, power or remedy
            provided by law or under these Platform Terms shall not preclude any
            other or further exercise of that right or the exercise of any other
            right, power or remedy.
          </p>
          <p className="mb-3">
            15.5 We shall be entitled to update and change the Platform, the
            Platform Content and the means of access to the Platform at any
            time.
          </p>
          <p className="mb-3">
            15.6 These Platform Terms and our Privacy policy set out the entire
            agreement between us and replace any previous agreements and
            understandings.
          </p>
          <p className="mb-3">
            15.7 Nothing in these Platform Terms shall constitute or shall be
            construed as constituting a partnership, joint venture, or contract
            of employment between the parties.
          </p>
          <p className="mb-3">
            15.8 The Platform Terms are governed by and shall be construed in
            accordance with English law. Disputes arising in connection with the
            Platform Terms shall be subject to the non-exclusive jurisdiction of
            the English courts.
          </p>

          {/* Terms of use (Website) section */}
          <h2 className="text-xl font-bold mt-8 mb-4">
            (iv) Terms of use (Website)
          </h2>

          <h3 className="text-lg font-semibold mt-6 mb-3">Introduction</h3>
          <p className="mb-3">1.1 This website is owned and operated by us.</p>
          <p className="mb-3">
            1.2 By using this website, you confirm that you accept these terms
            of use ("Terms of Use") and that you agree to comply with them. If
            you do not agree to these Terms of Use, you must not use our
            website.
          </p>
          <p className="mb-3">
            1.3 If you purchase goods or services from our website, please
            review our Terms and Conditions of Sale for Individual Consumers and
            Business Customers before placing any order.
          </p>

          <h3 className="text-lg font-semibold mt-6 mb-3">Our website</h3>
          <p className="mb-3">
            2.1 We may amend our Terms of Use from time to time. Every time you
            wish to use our website, please check the Terms of Use to ensure you
            understand the terms that apply at that time. We may update and
            change our website from time to time to reflect changes to our
            products, our users' needs and our business priorities. We shall not
            be liable to you or any third party for any modification or
            withdrawal of our website.
          </p>
          <p className="mb-3">
            2.2 Our website is made available free of charge. Whilst we use
            reasonable endeavours to ensure that our website is generally
            available, we do not guarantee that our website, or any content on
            it, will always be available or uninterrupted. We may suspend or
            withdraw or restrict the availability of all or any part of our
            website for business and operational reasons. We will try to give
            you reasonable notice of any suspension or withdrawal.
          </p>
          <p className="mb-3">
            2.3 You are responsible for ensuring that all persons who access our
            website through your internet connection are aware of these Terms of
            Use and other applicable terms and conditions, and that they comply
            with them.
          </p>
          <p className="mb-3">
            2.4 We may transfer our rights and obligation under these Terms of
            Use to another organisation.
          </p>
          <p className="mb-3">
            2.5 We are the owner or the licensee of all intellectual property
            rights in our website, and in the material published (including but
            not limited to text, photographs, graphics, and downloads). The
            material is protected by copyright laws and treaties around the
            world. All such rights are reserved. You may print off one copy, and
            may download extracts, of any page(s) from our website for your
            personal use and you may draw the attention of others within your
            organisation to content posted on our website.
          </p>
          <p className="mb-3">
            2.6 You must not modify the paper or digital copies of any materials
            you have printed off or downloaded in any way, and you must not use
            any illustrations, photographs, video or audio sequences or any
            graphics separately from any accompanying text.
          </p>
          <p className="mb-3">
            2.7 Our status (and that of any identified contributors) as the
            authors of content on our website must always be acknowledged
            (except where the content is user- generated). All brand names,
            product names and titles used on our website are trade names, and in
            some instances trademarks, of their respective holders.
          </p>
          <p className="mb-3">
            2.8 No permission is given in respect of use of any of the above,
            and such use may constitute an infringement of the holders' rights.
            You may not use or apply in any way any of the trade marks, logos,
            or any other identifying marks appearing on the website without
            obtaining permission to do so from us.
          </p>
          <p className="mb-3">
            2.9 You must not use any part of the content on our website for
            public or commercial purposes without obtaining a licence to do so
            from us or our licensors.
          </p>
          <p className="mb-3">
            2.10 If you print off, copy, download, share or repost any part of
            our website in breach of these Terms of Use, your right to use our
            website will cease immediately and you must, at our option, return
            or destroy any copies of the materials you have made.
          </p>

          <h3 className="text-lg font-semibold mt-6 mb-3">Restrictions</h3>
          <p className="mb-3">
            3.1 You shall not conduct, facilitate, authorise or permit any text
            or data mining or web scraping in relation to our Site or any
            services provided via, or in relation to, our website. This includes
            using (or permitting, authorising or attempting the use of):
            <ul className="list-disc ml-8 mt-2">
              <li>
                any "robot", "bot", "spider", "scraper" or other automated
                device, program, tool, algorithm, code, process or methodology
                to access, obtain, copy, monitor or republish any portion of the
                website or any data, content, information or services accessed
                via the same; and
              </li>
              <li>
                any automated analytical technique aimed at analysing text and
                data in digital form to generate information which includes but
                is not limited to patterns, trends and correlations.
              </li>
            </ul>
          </p>
          <p className="mb-3">
            3.2 The provisions of this condition 3 should be treated as an
            express reservation of our rights in this regard, including for the
            purposes of Article 4(3) of Digital Copyright Directive ((EU)
            2019/790).
          </p>
          <p className="mb-3">
            3.3 This condition 3 shall not apply insofar as (but only to the
            extent that) we are unable to exclude or limit text or data mining
            or web scraping activity by contract under the laws which are
            applicable to us.
          </p>

          <h3 className="text-lg font-semibold mt-6 mb-3">Information</h3>
          <p className="mb-3">
            4.1 The content on our website is provided for general information
            only. It is not intended to amount to advice on which you should
            rely. You must obtain professional or specialist advice before
            taking, or refraining from, any action on the basis of the content
            on our website.
          </p>
          <p className="mb-3">
            4.2 Although we make reasonable efforts to update the information on
            our website, we make no representations, warranties or guarantees,
            whether express or implied, that the content on our website is
            accurate, complete or up to date.
          </p>

          <h3 className="text-lg font-semibold mt-6 mb-3">Third party</h3>
          <p className="mb-3">
            5.1 Where our website contains links to other sites and resources
            provided by third parties, these links are provided for your
            information only. Such links should not be interpreted as approval
            by us of those linked websites or information you may obtain from
            them. We have no control over the contents of those sites or
            resources.
          </p>
          <p className="mb-3">
            5.2 We do not assume any responsibility or liability for the
            actions, content, goods, suitability or otherwise of all or any part
            of the third party sites. Please read the terms and conditions of
            use and privacy policies of such sites as it is your responsibility
            to view and abide by them.
          </p>
          <p className="mb-3">
            5.3 If you click on any links or banner advertisements on this
            website, you may be transferred to another website where the data
            privacy practices may be different to those of ours. We are not
            responsible for the privacy policies or data collection policies of
            any other websites.
          </p>

          <h3 className="text-lg font-semibold mt-6 mb-3">Other content</h3>
          <p className="mb-3">
            6.1 This website may include information and materials uploaded by
            other users of the Site, including links to social media pages,
            video-sharing sites, bulletin boards and chat rooms. This
            information and these materials have not been verified or approved
            by us. The views expressed by other users on our website do not
            represent our views or values. We accept no liability in respect of
            any material submitted by any user and published by us.
          </p>
          <p className="mb-3">
            6.2 If you are uploading or submitting material to our website, you
            agree to be bound by the following terms of acceptable use ("Terms
            of Acceptable Use"):
            <ul className="list-disc ml-8 mt-2">
              <li>
                publication of any material you submit will be at our sole
                discretion. We reserve the right to make additions or deletions
                to the text or graphics prior to publication, or to refuse
                publication;
              </li>
              <li>
                you grant to us a non-exclusive, perpetual, royalty-free,
                worldwide licence to copy, sublicense, adapt, create derivative
                works from, republish and in any way distribute in any format
                any material (including, but not limited to print and electronic
                format) that you submit to us. You waive any and all moral
                rights in relation to the material you submit, including but not
                limited to your right to be identified as the author of such
                content and your right to object to derogatory treatment of it;
              </li>
              <li>
                you will not submit any material which is intended to or which
                might upset others or which is unlawful, obnoxious, offensive,
                abusive, indecent, profane, harassing, derogatory or rude;
              </li>
              <li>
                you will not submit any material that is promotional or
                commercial in nature;
              </li>
              <li>
                you will not submit personal information about another person or
                make attempts to solicit personal information from anyone;
              </li>
              <li>
                you warrant that any material that you submit will be relevant
                to the content in respect of which it is submitted;
              </li>
              <li>
                you warrant that any material that you submit to us is your own
                original work and that you own the copyright and any other
                relevant rights in it;
              </li>
              <li>
                you warrant that any material that you submit to us is not
                obscene, offensive, defamatory of any person or a misuse of
                private information, and that it does not constitute hate speech
                against an identifiable group and is not otherwise illegal;
              </li>
              <li>
                you warrant that you are aware that if you disclose personal
                information in content that you provide to public forums on the
                Site including bulletin boards and other comment or review
                functions, then that information can be collected and used by
                others and may result in unsolicited messages from third
                parties;
              </li>
              <li>
                you acknowledge that any breach of these warranties may cause us
                damage or loss and you agree to indemnify us in full and
                permanently against any third party liability, claims, costs,
                loss or damage (including indirect and consequential loss) that
                we incur as a result of publishing material that you submit to
                us; and
              </li>
              <li>
                we reserve the right to remove your access to individual
                services completely if we believe you are not complying with
                these Terms of Acceptable Use or the Terms of Use. Where
                appropriate, we reserve the right to report your details to the
                applicable law enforcement agencies.
              </li>
            </ul>
          </p>
          <p className="mb-3">
            6.3 If you believe that material has been published on our website
            that does not comply with the Terms of Acceptable Use, please
            contact us and include details of why you think the material
            breaches the Terms of Acceptable Use.
          </p>

          <h3 className="text-lg font-semibold mt-6 mb-3">Complaints</h3>
          <p className="mb-3">
            7.1 If you become aware of any material that is illegal or could
            comprise or be connected to child sexual abuse or exploitation or
            could comprise terrorist content or be connected to terrorism,
            please contact us immediately.
          </p>
          <p className="mb-3">
            7.2 If you wish to complain about any other content, please contact
            us.
          </p>

          <h3 className="text-lg font-semibold mt-6 mb-3">Warranties</h3>
          <p className="mb-3">
            8.1 This website is provided on an 'as-is' basis without any
            conditions, warranties or other terms of any kind. We provide the
            website on the basis that all representations, warranties,
            conditions and other terms (including, without limitation, the
            conditions implied by law of satisfactory quality, fitness for
            purpose and the use of reasonable care and skill) are excluded to
            the fullest extent permitted in law.
          </p>
          <p className="mb-3">
            8.2 Any content and/or software downloaded from the website is
            downloaded at your own risk. We accept no liability for damage
            caused from installing and/ or using any content and/or software.
          </p>
          <p className="mb-3">
            8.3 We do not guarantee that our website will be secure or free from
            bugs or viruses. It is solely your responsibility to virus check all
            content and/or software downloaded from the website and to check it
            is compatible with your device.
          </p>
          <p className="mb-3">
            8.4 You are responsible for configuring your information technology,
            computer programmes and platform to access our website.
          </p>

          <h3 className="text-lg font-semibold mt-6 mb-3">Liability</h3>
          <p className="mb-3">
            9.1 Different limitations and exclusions of liability will apply to
            liability arising as a result of the supply of any products and
            services to you, which will be set out in our Sale Terms.
          </p>
          <p className="mb-3">
            9.2 Neither party excludes or limits its liability to the other
            party for:
            <ul className="list-disc ml-8 mt-2">
              <li>
                death or personal injury caused by its own negligence, or the
                negligence of its employees, agents or subcontractors (as
                applicable);
              </li>
              <li>fraud or fraudulent misrepresentation; and</li>
              <li>
                any other matter for which it would be unlawful to exclude or
                restrict liability.
              </li>
            </ul>
          </p>
          <p className="mb-3">
            9.3 Subject to condition 9.2 above, our liability to you in respect
            of any and all claims, whether in contract, delict, tort, breach of
            statutory duty, or otherwise in connection with these Terms of Use
            is (to the extent permitted by law) limited to £100.
          </p>
          <p className="mb-3">
            9.4 Subject to condition 9.2, we will not be liable to you for any
            loss or damage, whether in contract, tort (including negligence),
            breach of statutory duty, or otherwise, even if foreseeable, arising
            under or in connection with:
            <ul className="list-disc ml-8 mt-2">
              <li>loss of profits, sales, business, or revenue;</li>
              <li>loss of anticipated savings;</li>
              <li>loss or corruption of software, data, or information;</li>
              <li>loss of business opportunity, goodwill or reputation;</li>
              <li>business interruption; or</li>
              <li>any indirect or consequential loss or damage.</li>
            </ul>
          </p>
          <p className="mb-3">
            9.5 In addition to condition 9.4 above, we exclude all liability and
            responsibility for any loss or damage that may in any way result to
            you or a third party in connection with the use, inability to use,
            or the results of use of the website and any third party sites
            linked to/from the website including, without limitation, any loss
            or damage due to viruses, software, data or other property on
            account of your access to, use of, or browsing the website or your
            downloading of any material from the website or any websites linked
            to/from the website.
          </p>

          <h3 className="text-lg font-semibold mt-6 mb-3">General</h3>
          <p className="mb-3">
            12.1 You are not entitled to assign or transfer any of your rights
            or purport to assign any of your obligations under these Terms of
            Use without our prior written consent. We may, at any time, assign,
            sublicense, transfer or otherwise dispose of all or any of our
            rights or obligations under the Terms of Use.
          </p>
          <p className="mb-3">
            12.2 If any or all provisions in these Terms of Use shall be or
            become illegal, invalid or unenforceable in any respect, then the
            remainder of such provision and/or all other provisions shall remain
            valid and enforceable, and the remaining liabilities of the parties
            shall not be affected or impaired.
          </p>
          <p className="mb-3">
            12.3 No delay in exercising or non-exercise of any right, power or
            remedy provided by law or under these Terms of Use shall impair or
            otherwise operate as a waiver or release of that right, power or
            remedy. Any single or partial exercise of any right, power or remedy
            provided by law or under these Terms of Use shall not preclude any
            other or further exercise of that right or the exercise of any other
            right, power or remedy.
          </p>
          <p className="mb-3">
            12.4 These Terms of Use do not give rise to any rights under the
            Contracts (Rights of Third Parties) Act 1999 to enforce any terms of
            these Event Terms.
          </p>
          <p className="mb-3">
            12.5 These Terms of Use are governed by and shall be construed in
            accordance with English law. Disputes arising in connection with the
            Terms of Use or the site shall be subject to the non-exclusive
            jurisdiction of the English courts.
          </p>
          <p className="mb-3">
            12.6 Our website is intended for the use of residents in the United
            Kingdom. Whilst we do not prohibit the use of the website by
            residents outside of the United Kingdom, we are not required to
            ensure that the website complies with any law other than the laws of
            England and Wales. If you are an international user (i.e. you are
            not resident in the United Kingdom) you will be responsible for
            complying with all local laws.
          </p>
        </div>
      </section>
      <GoToTopButton />
    </div>
  );
};

export default TermsAndConditions;
