import React, { useState, useEffect } from "react";
import { Plus, Edit, Trash2, LoaderCircle, X, Upload } from "lucide-react";

const AdminEvents = () => {
  const defaultFormData = {
    title: "",
    date_month: "",
    date_day: "",
    date_year: "",
    location: "",
    price: "",
    type: "",
    color: "#3B82F6",
    teachers: [],
    why_attend: {
      points: [],
      title: "",
      description: "",
    },
    programme: {
      schedule: [],
      title: "",
      description: "",
    },
    trainer_data: {
      items: [],
    },
    trainer_photos: null,
  };

  const [formData, setFormData] = useState(defaultFormData);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editingEvent, setEditingEvent] = useState(null);
  const [events, setEvents] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [activeTab, setActiveTab] = useState("basics");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});

  const initializeEventData = (event) => {
    return {
      ...defaultFormData, // Start with default form data to ensure all fields exist
      ...event, // Spread the event data
      // Ensure nested objects exist with their required structure
      why_attend: {
        points: [],
        title: "",
        description: "",
        ...(event.why_attend || {}),
      },
      programme: {
        schedule: [],
        title: "",
        description: "",
        ...(event.programme || {}),
      },
      trainer_data: {
        items: [],
        ...(event.trainer_data || {}),
      },
      // Ensure arrays exist
      teachers: Array.isArray(event.teachers) ? event.teachers : [],
      // Reset file input since we can't populate it
      trainer_photos: null,
    };
  };
  const parseEventData = (event) => {
    return {
      ...event,
      teachers: Array.isArray(event.teachers)
        ? event.teachers
            .map((t) => {
              try {
                return typeof t === "string" ? t : JSON.parse(t);
              } catch {
                return t;
              }
            })
            .flat()
        : [],
      why_attend: event.why_attend || {
        points: [],
        title: "",
        description: "",
      },
      programme: event.programme || {
        schedule: [],
        title: "",
        description: "",
      },
      trainer_data: event.trainer_data || { items: [] },
    };
  };

  const validateForm = () => {
    const errors = {};

    if (!formData.title?.trim()) errors.title = "Title is required";
    if (!formData.date_month?.trim()) errors.date_month = "Month is required";
    if (!formData.date_day?.trim()) errors.date_day = "Day is required";
    if (!formData.date_year?.trim()) errors.date_year = "Year is required";
    if (!formData.location?.trim()) errors.location = "Location is required";
    if (!formData.price?.trim()) errors.price = "Price is required";
    if (!formData.type?.trim()) errors.type = "Type is required";
    if (!formData.teachers?.length)
      errors.teachers = "At least one teacher is required";

    const day = parseInt(formData.date_day);
    const year = parseInt(formData.date_year);
    const price = parseFloat(formData.price);

    if (isNaN(day) || day < 1 || day > 31)
      errors.date_day = "Please enter a valid day (1-31)";
    if (isNaN(year) || year < 2024)
      errors.date_year = "Please enter a valid year (2024 or later)";
    // if (isNaN(price) || price < 0) errors.price = "Please enter a valid price";

    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleTeachersChange = (value) => {
    // Store the raw input value
    setFormData((prev) => ({
      ...prev,
      teachers: value, // Store the raw input value
    }));

    // Clear validation error if teachers are added
    if (value.trim() !== "" && validationErrors.teachers) {
      setValidationErrors((prev) => {
        const newErrors = { ...prev };
        delete newErrors.teachers;
        return newErrors;
      });
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    // Clear validation error when field is edited
    if (validationErrors[name]) {
      setValidationErrors((prev) => {
        const newErrors = { ...prev };
        delete newErrors[name];
        return newErrors;
      });
    }

    if (name === "teachers") {
      handleTeachersChange(value); // Pass the raw input value
    } else if (name.startsWith("why_attend.")) {
      const field = name.replace("why_attend.", "");
      setFormData((prev) => ({
        ...prev,
        why_attend: {
          ...prev.why_attend,
          [field]: value,
        },
      }));
    } else if (name.startsWith("programme.")) {
      const field = name.replace("programme.", "");
      handleProgrammeChange(field, value);
    } else if (name === "trainer_photos") {
      setFormData((prev) => ({
        ...prev,
        trainer_photos: e.target.files,
      }));
    } else {
      setFormData((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };
  const handleProgrammeChange = (field, value) => {
    setFormData((prev) => ({
      ...prev,
      programme: {
        ...prev.programme,
        [field]: value,
      },
    }));
  };

  const handleAddScheduleItem = () => {
    setFormData((prev) => ({
      ...prev,
      programme: {
        ...prev.programme,
        schedule: [
          ...prev.programme.schedule,
          { time: "", title: "", details: [] },
        ],
      },
    }));
  };

  const handleScheduleChange = (index, field, value) => {
    setFormData((prev) => ({
      ...prev,
      programme: {
        ...prev.programme,
        schedule: prev.programme.schedule.map((item, i) =>
          i === index ? { ...item, [field]: value } : item
        ),
      },
    }));
  };

  const handleAddScheduleDetail = (scheduleIndex) => {
    setFormData((prev) => ({
      ...prev,
      programme: {
        ...prev.programme,
        schedule: prev.programme.schedule.map((item, i) =>
          i === scheduleIndex
            ? { ...item, details: [...(item.details || []), ""] }
            : item
        ),
      },
    }));
  };

  const handleScheduleDetailChange = (scheduleIndex, detailIndex, value) => {
    setFormData((prev) => ({
      ...prev,
      programme: {
        ...prev.programme,
        schedule: prev.programme.schedule.map((item, i) =>
          i === scheduleIndex
            ? {
                ...item,
                details: item.details.map((detail, j) =>
                  j === detailIndex ? value : detail
                ),
              }
            : item
        ),
      },
    }));
  };
  const handleAddPoint = () => {
    setFormData((prev) => ({
      ...prev,
      why_attend: {
        ...prev.why_attend,
        points: [...prev.why_attend.points, ""],
      },
    }));
  };

  const handlePointChange = (index, value) => {
    setFormData((prev) => ({
      ...prev,
      why_attend: {
        ...prev.why_attend,
        points: prev.why_attend.points.map((point, i) =>
          i === index ? value : point
        ),
      },
    }));
  };
  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const token = localStorage.getItem("access_token");
        const response = await fetch("https://api2.made2tech.com/event/", {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        });

        if (!response.ok) throw new Error("Failed to fetch events");
        const data = await response.json();
        setEvents(data);
      } catch (err) {
        setError(err.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchEvents();
  }, []);
  const handleRemovePoint = (index) => {
    setFormData((prev) => ({
      ...prev,
      why_attend: {
        ...prev.why_attend,
        points: prev.why_attend.points.filter((_, i) => i !== index),
      },
    }));
  };

  const handleRemoveScheduleDetail = (scheduleIndex, detailIndex) => {
    setFormData((prev) => ({
      ...prev,
      programme: {
        ...prev.programme,
        schedule: prev.programme.schedule.map((item, i) =>
          i === scheduleIndex
            ? {
                ...item,
                details: item.details.filter((_, j) => j !== detailIndex),
              }
            : item
        ),
      },
    }));
  };

  const handleAddTrainer = () => {
    setFormData((prev) => ({
      ...prev,
      trainer_data: {
        items: [...prev.trainer_data.items, { name: "", bio: "" }],
      },
    }));
  };

  const handleTrainerChange = (index, field, value) => {
    setFormData((prev) => ({
      ...prev,
      trainer_data: {
        items: prev.trainer_data.items.map((trainer, i) =>
          i === index ? { ...trainer, [field]: value } : trainer
        ),
      },
    }));
  };

  const handleRemoveTrainer = (index) => {
    setFormData((prev) => ({
      ...prev,
      trainer_data: {
        items: prev.trainer_data.items.filter((_, i) => i !== index),
      },
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Process the teachers field before validation
    const processedFormData = {
      ...formData,
      teachers: formData.teachers
        .split(",")
        .map((t) => t.trim())
        .filter((t) => t !== ""), // Split, trim, and filter empty strings
    };

    // Use processedFormData for validation and submission
    if (!validateForm(processedFormData)) {
      setActiveTab("basics");
      return;
    }

    setIsSubmitting(true);
    try {
      const token = localStorage.getItem("access_token");
      const formDataToSend = new FormData();

      // Add basic fields
      formDataToSend.append("title", processedFormData.title);
      formDataToSend.append("date_month", processedFormData.date_month);
      formDataToSend.append("date_day", processedFormData.date_day);
      formDataToSend.append("date_year", processedFormData.date_year);
      formDataToSend.append("location", processedFormData.location);
      formDataToSend.append("price", processedFormData.price);
      formDataToSend.append("type", processedFormData.type);
      formDataToSend.append("color", processedFormData.color);

      // Handle arrays and objects
      formDataToSend.append(
        "teachers",
        JSON.stringify(processedFormData.teachers)
      );
      formDataToSend.append(
        "why_attend",
        JSON.stringify(processedFormData.why_attend)
      );
      formDataToSend.append(
        "programme",
        JSON.stringify(processedFormData.programme)
      );
      formDataToSend.append(
        "trainer_data",
        JSON.stringify(processedFormData.trainer_data)
      );

      // Handle trainer photos
      if (processedFormData.trainer_photos) {
        Array.from(processedFormData.trainer_photos).forEach((file) => {
          formDataToSend.append("trainer_photos", file);
        });
      }

      const url = editingEvent
        ? `https://api2.made2tech.com/event/${editingEvent.id}`
        : "https://api2.made2tech.com/event/";

      const response = await fetch(url, {
        method: editingEvent ? "PUT" : "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formDataToSend,
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(
          errorData.detail ||
            `Failed to ${editingEvent ? "update" : "create"} event`
        );
      }

      await fetchEvents();
      setIsModalOpen(false);
      setEditingEvent(null);
      setFormData(defaultFormData);
      setValidationErrors({});
    } catch (err) {
      setError(err.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleDelete = async (id) => {
    if (!window.confirm("Are you sure you want to delete this event?")) return;

    try {
      const token = localStorage.getItem("access_token");
      const response = await fetch(`https://api2.made2tech.com/event/${id}`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) throw new Error("Failed to delete event");
      await fetchEvents();
    } catch (err) {
      setError(err.message);
    }
  };

  // Function to fetch events from API
  const fetchEvents = async () => {
    try {
      const token = localStorage.getItem("access_token");
      const response = await fetch("https://api2.made2tech.com/event/", {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) throw new Error("Failed to fetch events");
      const data = await response.json();
      setEvents(data);
    } catch (err) {
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return (
      <div className="flex items-center justify-center h-64">
        <LoaderCircle className="w-8 h-8 animate-spin text-blue-500" />
      </div>
    );
  }

  return (
    <div className="space-y-6 p-6">
      <div className="flex justify-between items-center">
        <h2 className="text-2xl font-bold text-gray-900">Events</h2>
        <button
          onClick={() => setIsModalOpen(true)}
          className="flex items-center px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700"
        >
          <Plus className="w-4 h-4 mr-2" />
          Add Event
        </button>
      </div>

      {error && (
        <div className="bg-red-50 text-red-700 p-4 rounded-lg">
          <p>Error: {error}</p>
        </div>
      )}

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        {events.map((event) => (
          <div
            key={event.id}
            className="bg-white rounded-lg shadow-md p-6"
            style={{ borderLeft: `4px solid ${event.color}` }}
          >
            <div className="flex justify-between items-start">
              <div className="space-y-4 flex-1">
                <div>
                  <h3 className="text-lg text-left font-semibold">
                    {event.title}
                  </h3>
                  <p className="text-sm text-left text-gray-600">
                    {event.date_month} {event.date_day}, {event.date_year}
                  </p>
                  <p className="text-sm text-left text-gray-600">
                    {event.location}
                  </p>
                  <p className="text-sm  text-left font-bold text-blue-600">
                    £{event.price}
                  </p>
                </div>

                <div>
                  <span className="px-2 py-1 text-left text-xs font-semibold rounded-full bg-gray-100">
                    {event.type}
                  </span>
                </div>

                {event.teachers?.length > 0 && (
                  <div>
                    <p className="text-sm  text-left font-medium text-gray-700">
                      Teachers:
                    </p>
                    <div className="flex text-left flex-wrap gap-1 mt-1">
                      {event.teachers.map((teacher, index) => {
                        // Remove brackets and quotes if present
                        const cleanTeacherName = teacher.replace(
                          /[\[\]"]/g,
                          ""
                        );
                        return (
                          <span
                            key={index}
                            className="px-2 py-1 text-xs rounded-full bg-blue-100 text-blue-800"
                          >
                            {cleanTeacherName}
                          </span>
                        );
                      })}
                    </div>
                  </div>
                )}

                {event.programme?.title && (
                  <div className="text-left text-sm">
                    <p className="font-medium text-gray-700">Programme:</p>
                    <p className="text-gray-600 line-clamp-2">
                      {event.programme.title}
                    </p>
                  </div>
                )}
              </div>

              <div className="flex space-x-2 ml-4">
                <button
                  onClick={() => {
                    const initializedData = initializeEventData(event);
                    setEditingEvent(event);
                    setFormData(initializedData);
                    setIsModalOpen(true);
                  }}
                  className="text-blue-600 hover:text-blue-800"
                >
                  <Edit className="w-4 h-4" />
                </button>
                <button
                  onClick={() => handleDelete(event.id)}
                  className="text-red-600 hover:text-red-800"
                >
                  <Trash2 className="w-4 h-4" />
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>

      {isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
          <div className="bg-white rounded-lg w-full max-w-4xl max-h-[90vh] overflow-y-auto">
            <div className="flex justify-between items-center p-6 border-b">
              <h2 className="text-xl font-semibold">
                {editingEvent ? "Edit Event" : "Add New Event"}
              </h2>
              <button
                onClick={() => {
                  setIsModalOpen(false);
                  setEditingEvent(null);
                  setFormData(defaultFormData);
                  setValidationErrors({});
                }}
                className="text-gray-500 hover:text-gray-700"
              >
                <X className="w-6 h-6" />
              </button>
            </div>

            <div className="border-b border-gray-200">
              <nav className="flex space-x-8 px-6" aria-label="Tabs">
                {["basics", "why-attend", "programme", "trainers"].map(
                  (tab) => (
                    <button
                      key={tab}
                      onClick={() => setActiveTab(tab)}
                      className={`py-4 px-1 border-b-2 font-medium text-sm
                      ${
                        activeTab === tab
                          ? "border-blue-500 text-blue-600"
                          : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300"
                      }`}
                    >
                      {tab.charAt(0).toUpperCase() +
                        tab.slice(1).replace("-", " ")}
                    </button>
                  )
                )}
              </nav>
            </div>

            <form onSubmit={handleSubmit} className="p-6 space-y-6">
              {/* Basic Information Tab */}
              {activeTab === "basics" && (
                <div className="space-y-6">
                  <div>
                    <label className="block  text-left text-sm font-medium text-gray-700 mb-1">
                      Title *
                    </label>
                    <input
                      type="text"
                      name="title"
                      value={formData.title}
                      onChange={handleInputChange}
                      className={`w-full p-2 border rounded focus:ring-2 focus:ring-blue-500 focus:border-transparent
                        ${
                          validationErrors.title
                            ? "border-red-500"
                            : "border-gray-300"
                        }`}
                    />
                    {validationErrors.title && (
                      <p className="mt-1 text-sm text-red-600">
                        {validationErrors.title}
                      </p>
                    )}
                  </div>

                  <div className="grid grid-cols-3 gap-4">
                    <div>
                      <label className="block  text-left text-sm font-medium text-gray-700 mb-1">
                        Month *
                      </label>
                      <select
                        name="date_month"
                        value={formData.date_month}
                        onChange={handleInputChange}
                        className={`w-full p-2 border rounded focus:ring-2 focus:ring-blue-500 focus:border-transparent
                          ${
                            validationErrors.date_month
                              ? "border-red-500"
                              : "border-gray-300"
                          }`}
                      >
                        <option value="">Select month</option>
                        {[
                          "January",
                          "February",
                          "March",
                          "April",
                          "May",
                          "June",
                          "July",
                          "August",
                          "September",
                          "October",
                          "November",
                          "December",
                        ].map((month) => (
                          <option key={month} value={month}>
                            {month}
                          </option>
                        ))}
                      </select>
                      {validationErrors.date_month && (
                        <p className="mt-1 text-sm text-red-600">
                          {validationErrors.date_month}
                        </p>
                      )}
                    </div>
                    <div>
                      <label className="block  text-left text-sm font-medium text-gray-700 mb-1">
                        Day *
                      </label>
                      <input
                        type="number"
                        name="date_day"
                        min="1"
                        max="31"
                        value={formData.date_day}
                        onChange={handleInputChange}
                        className={`w-full p-2 border rounded focus:ring-2 focus:ring-blue-500 focus:border-transparent
                          ${
                            validationErrors.date_day
                              ? "border-red-500"
                              : "border-gray-300"
                          }`}
                      />
                      {validationErrors.date_day && (
                        <p className="mt-1 text-sm text-red-600">
                          {validationErrors.date_day}
                        </p>
                      )}
                    </div>
                    <div>
                      <label className="block  text-left text-sm font-medium text-gray-700 mb-1">
                        Year *
                      </label>
                      <input
                        type="number"
                        name="date_year"
                        min="2024"
                        value={formData.date_year}
                        onChange={handleInputChange}
                        className={`w-full p-2 border rounded focus:ring-2 focus:ring-blue-500 focus:border-transparent
                          ${
                            validationErrors.date_year
                              ? "border-red-500"
                              : "border-gray-300"
                          }`}
                      />
                      {validationErrors.date_year && (
                        <p className="mt-1 text-sm text-red-600">
                          {validationErrors.date_year}
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="grid grid-cols-2 gap-4">
                    <div>
                      <label className="block  text-left text-sm font-medium text-gray-700 mb-1">
                        Location *
                      </label>
                      <input
                        type="text"
                        name="location"
                        value={formData.location}
                        onChange={handleInputChange}
                        className={`w-full p-2 border rounded focus:ring-2 focus:ring-blue-500 focus:border-transparent
                          ${
                            validationErrors.location
                              ? "border-red-500"
                              : "border-gray-300"
                          }`}
                      />
                      {validationErrors.location && (
                        <p className="mt-1 text-sm text-red-600">
                          {validationErrors.location}
                        </p>
                      )}
                    </div>
                    <div>
                      <label className="block  text-left text-sm font-medium text-gray-700 mb-1">
                        Price * (£)
                      </label>
                      <input
                        // type="number"
                        name="price"
                        min="0"
                        step="0.01"
                        value={formData.price}
                        onChange={handleInputChange}
                        className={`w-full p-2 border rounded focus:ring-2 focus:ring-blue-500 focus:border-transparent
                          ${
                            validationErrors.price
                              ? "border-red-500"
                              : "border-gray-300"
                          }`}
                      />
                      {validationErrors.price && (
                        <p className="mt-1 text-sm text-red-600">
                          {validationErrors.price}
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="grid grid-cols-2 gap-4">
                    <div>
                      <label className="block  text-left text-sm font-medium text-gray-700 mb-1">
                        Type *
                      </label>
                      <select
                        name="type"
                        value={formData.type}
                        onChange={handleInputChange}
                        className={`w-full p-2 border rounded focus:ring-2 focus:ring-blue-500 focus:border-transparent
                          ${
                            validationErrors.type
                              ? "border-red-500"
                              : "border-gray-300"
                          }`}
                      >
                        <option value="">Select type</option>
                        <option value="In-person CPD">In-person CPD</option>
                        <option value="Online CPD">Online CPD</option>
                        <option value="Bespoke CPD">Bespoke CPD</option>
                        <option value="Student Events">Student Events</option>
                        <option value="Workshop">Workshop</option>
                      </select>
                      {validationErrors.type && (
                        <p className="mt-1 text-sm text-red-600">
                          {validationErrors.type}
                        </p>
                      )}
                    </div>
                    <div>
                      <label className="block  text-left text-sm font-medium text-gray-700 mb-1">
                        Color
                      </label>
                      <input
                        type="color"
                        name="color"
                        value={formData.color}
                        onChange={handleInputChange}
                        className="w-full p-1 border rounded focus:ring-2 focus:ring-blue-500 focus:border-transparent h-10"
                      />
                    </div>
                  </div>

                  <div>
                    <label className="block  text-left text-sm font-medium text-gray-700 mb-1">
                      Teachers (comma-separated) *
                    </label>

                    <input
                      type="text"
                      name="teachers"
                      value={formData.teachers} // Display the raw input value
                      onChange={handleInputChange}
                      className={`w-full p-2 border rounded focus:ring-2 focus:ring-blue-500 focus:border-transparent
    ${validationErrors.teachers ? "border-red-500" : "border-gray-300"}`}
                      placeholder="John Doe, Jane Smith"
                    />
                    {validationErrors.teachers && (
                      <p className="mt-1 text-sm text-red-600">
                        {validationErrors.teachers}
                      </p>
                    )}
                  </div>
                </div>
              )}

              {/* Programme Tab */}
              {activeTab === "programme" && (
                <div className="space-y-6">
                  <div>
                    <label className="block  text-left text-sm font-medium text-gray-700 mb-1">
                      Programme Title
                    </label>
                    <input
                      type="text"
                      name="programme.title"
                      value={formData.programme.title}
                      onChange={handleInputChange}
                      className="w-full p-2 border rounded focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                      placeholder="Enter programme title"
                    />
                  </div>

                  <div>
                    <label className="block  text-left text-sm font-medium text-gray-700 mb-1">
                      Programme Description
                    </label>
                    <textarea
                      name="programme.description"
                      value={formData.programme.description}
                      onChange={handleInputChange}
                      rows="4"
                      className="w-full p-2 border rounded focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                      placeholder="Enter programme description"
                    />
                  </div>

                  <div>
                    <label className="block  text-left text-sm font-medium text-gray-700 mb-1">
                      Schedule
                    </label>
                    <div className="space-y-4">
                      {formData.programme.schedule.map((item, index) => (
                        <div
                          key={index}
                          className="space-y-2 p-4 border rounded"
                        >
                          <div className="flex gap-2">
                            <input
                              type="text"
                              value={item.time}
                              onChange={(e) =>
                                handleScheduleChange(
                                  index,
                                  "time",
                                  e.target.value
                                )
                              }
                              className="w-32 p-2 border rounded focus:ring-2 focus:ring-blue-500"
                              placeholder="Time"
                            />
                            <input
                              type="text"
                              value={item.title}
                              onChange={(e) =>
                                handleScheduleChange(
                                  index,
                                  "title",
                                  e.target.value
                                )
                              }
                              className="flex-1 p-2 border rounded focus:ring-2 focus:ring-blue-500"
                              placeholder="Title"
                            />
                            <button
                              type="button"
                              onClick={() => handleRemoveScheduleDetail(index)}
                              className="px-2 text-red-600 hover:text-red-800"
                            >
                              <X className="w-4 h-4" />
                            </button>
                          </div>

                          <div className="pl-8 space-y-2">
                            {item.details?.map((detail, detailIndex) => (
                              <div key={detailIndex} className="flex gap-2">
                                <input
                                  type="text"
                                  value={detail}
                                  onChange={(e) =>
                                    handleScheduleDetailChange(
                                      index,
                                      detailIndex,
                                      e.target.value
                                    )
                                  }
                                  className="flex-1 p-2 border rounded focus:ring-2 focus:ring-blue-500"
                                  placeholder="Detail"
                                />
                                <button
                                  type="button"
                                  onClick={() =>
                                    handleRemoveScheduleDetail(
                                      index,
                                      detailIndex
                                    )
                                  }
                                  className="px-2 text-red-600 hover:text-red-800"
                                >
                                  <X className="w-4 h-4" />
                                </button>
                              </div>
                            ))}
                            <button
                              type="button"
                              onClick={() => handleAddScheduleDetail(index)}
                              className="text-sm text-blue-600 hover:text-blue-800"
                            >
                              Add Detail
                            </button>
                          </div>
                        </div>
                      ))}
                      <button
                        type="button"
                        onClick={handleAddScheduleItem}
                        className="text-sm text-blue-600 hover:text-blue-800"
                      >
                        Add Schedule Item
                      </button>
                    </div>
                  </div>
                </div>
              )}

              {/* Trainers Tab */}
              {activeTab === "trainers" && (
                <div className="space-y-6">
                  <div>
                    <label className="block  text-left text-sm font-medium text-gray-700 mb-1">
                      Trainers
                    </label>
                    <div className="space-y-4">
                      {formData.trainer_data.items.map((trainer, index) => (
                        <div
                          key={index}
                          className="p-4 border rounded space-y-3"
                        >
                          <div className="flex justify-between items-center">
                            <h4 className="text-sm font-medium">
                              Trainer {index + 1}
                            </h4>
                            <button
                              type="button"
                              onClick={() => handleRemoveTrainer(index)}
                              className="text-red-600 hover:text-red-800"
                            >
                              <X className="w-4 h-4" />
                            </button>
                          </div>
                          <div className="space-y-3">
                            <div>
                              <label className="block  text-left text-sm text-gray-500 mb-1">
                                Name
                              </label>
                              <input
                                type="text"
                                value={trainer.name}
                                onChange={(e) =>
                                  handleTrainerChange(
                                    index,
                                    "name",
                                    e.target.value
                                  )
                                }
                                className="w-full p-2 border rounded focus:ring-2 focus:ring-blue-500"
                                placeholder="Enter trainer name"
                              />
                            </div>
                            <div>
                              <label className="block  text-left text-sm text-gray-500 mb-1">
                                Bio
                              </label>
                              <textarea
                                value={trainer.bio}
                                onChange={(e) =>
                                  handleTrainerChange(
                                    index,
                                    "bio",
                                    e.target.value
                                  )
                                }
                                rows="3"
                                className="w-full p-2 border rounded focus:ring-2 focus:ring-blue-500"
                                placeholder="Enter trainer biography"
                              />
                            </div>
                          </div>
                        </div>
                      ))}
                      <button
                        type="button"
                        onClick={handleAddTrainer}
                        className="inline-flex items-center text-sm text-blue-600 hover:text-blue-800"
                      >
                        <Plus className="w-4 h-4 mr-1" />
                        Add Trainer
                      </button>
                    </div>
                  </div>

                  <div>
                    <label className="block  text-left text-sm font-medium text-gray-700 mb-1">
                      Trainer Photos
                    </label>
                    <div className="mt-2 space-y-2">
                      <label className="cursor-pointer flex items-center justify-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50">
                        <Upload className="w-4 h-4 mr-2" />
                        {formData.trainer_photos
                          ? `${
                              Array.from(formData.trainer_photos).length
                            } file(s) selected`
                          : "Upload Photos"}
                        <input
                          type="file"
                          name="trainer_photos"
                          onChange={handleInputChange}
                          className="hidden"
                          multiple
                          accept="image/*"
                        />
                      </label>
                      {formData.trainer_photos && (
                        <div className="text-sm text-gray-500">
                          Selected files:
                          <ul className="list-disc pl-5 mt-1">
                            {Array.from(formData.trainer_photos).map(
                              (file, index) => (
                                <li key={index}>{file.name}</li>
                              )
                            )}
                          </ul>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}

              {/* Why Attend Tab */}
              {activeTab === "why-attend" && (
                <div className="space-y-6">
                  <div>
                    <label className="block  text-left text-sm font-medium text-gray-700 mb-1">
                      Why Attend Title
                    </label>
                    <input
                      type="text"
                      name="why_attend.title"
                      value={formData.why_attend.title}
                      onChange={handleInputChange}
                      className="w-full p-2 border rounded focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                      placeholder="Enter title for why attend section"
                    />
                  </div>

                  <div>
                    <label className="block  text-left text-sm font-medium text-gray-700 mb-1">
                      Description
                    </label>
                    <textarea
                      name="why_attend.description"
                      value={formData.why_attend.description}
                      onChange={handleInputChange}
                      rows="4"
                      className="w-full p-2 border rounded focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                      placeholder="Enter description of why people should attend"
                    />
                  </div>

                  <div>
                    <label className="block  text-left text-sm font-medium text-gray-700 mb-1">
                      Key Points
                    </label>
                    <div className="space-y-2">
                      {formData.why_attend.points.map((point, index) => (
                        <div key={index} className="flex gap-2">
                          <input
                            type="text"
                            value={point}
                            onChange={(e) =>
                              handlePointChange(index, e.target.value)
                            }
                            className="flex-1 p-2 border rounded focus:ring-2 focus:ring-blue-500"
                            placeholder="Enter a key point about attending"
                          />
                          <button
                            type="button"
                            onClick={() => handleRemovePoint(index)}
                            className="px-2 text-red-600 hover:text-red-800"
                          >
                            <X className="w-4 h-4" />
                          </button>
                        </div>
                      ))}
                      <button
                        type="button"
                        onClick={handleAddPoint}
                        className="text-sm text-blue-600 hover:text-blue-800 flex items-center"
                      >
                        <Plus className="w-4 h-4 mr-1" />
                        Add Point
                      </button>
                    </div>
                  </div>
                </div>
              )}

              <div className="flex justify-end space-x-3 pt-6 border-t mt-6">
                <button
                  type="button"
                  onClick={() => {
                    setIsModalOpen(false);
                    setEditingEvent(null);
                    setFormData(defaultFormData);
                    setValidationErrors({});
                  }}
                  className="px-4 py-2 border border-gray-300 rounded-md text-gray-700 hover:bg-gray-50"
                  disabled={isSubmitting}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className={`px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 flex items-center
                    ${isSubmitting ? "opacity-75 cursor-not-allowed" : ""}`}
                  disabled={isSubmitting}
                >
                  {isSubmitting && (
                    <LoaderCircle className="w-4 h-4 mr-2 animate-spin" />
                  )}
                  {editingEvent ? "Update Event" : "Add Event"}
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default AdminEvents;
