import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { CheckCircle, ArrowRight, Mail, Layout, Calendar } from "lucide-react";

const OrderSuccessPage = () => {
  const navigate = useNavigate();

  // Scroll to top when component mounts
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div
      style={{ paddingTop: 156 }}
      className="min-h-screen bg-gradient-to-b from-yellow-50 to-white flex items-center justify-center py-16 px-4 sm:px-6 lg:px-8"
    >
      <div className="max-w-lg w-full bg-white rounded-2xl shadow-lg p-8 space-y-8 border border-yellow-100">
        {/* Success animation and header */}
        <div className="text-center">
          <div className="flex justify-center">
            <div className="relative">
              <div className="absolute -inset-1 rounded-full bg-green-100 animate-pulse"></div>
              <CheckCircle className="relative h-20 w-20 text-green-500" />
            </div>
          </div>

          <h1
            style={{ fontFamily: "Krona One" }}
            className="mt-8 text-3xl font-bold text-gray-900 tracking-tight"
          >
            Order Successful!
          </h1>

          <p className="mt-4 text-gray-600 text-lg">
            Thank you for your purchase. Your order has been successfully
            processed.
          </p>
        </div>

        {/* Divider with decoration */}
        <div className="relative">
          <div className="absolute inset-0 flex items-center">
            <div className="w-full border-t border-yellow-200"></div>
          </div>
          <div className="relative flex justify-center">
            <span className="bg-white px-4 text-yellow-500">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2" />
              </svg>
            </span>
          </div>
        </div>

        {/* What happens next section */}
        <div className="pt-2">
          <h2 className="text-xl font-semibold text-gray-900 mb-6">
            What happens next?
          </h2>
          <ul className="space-y-5">
            <li className="flex items-start bg-yellow-50 p-4 rounded-lg transition-all hover:shadow-md">
              <span className="flex-shrink-0 bg-yellow-100 p-2 rounded-full mr-4">
                <Mail className="h-5 w-5 text-yellow-700" />
              </span>
              <div>
                <p className="font-medium text-gray-800">Email Confirmation</p>
                <p className="text-gray-600 text-sm">
                  You'll receive an email confirmation shortly
                </p>
              </div>
            </li>
            <li className="flex items-start bg-yellow-50 p-4 rounded-lg transition-all hover:shadow-md">
              <span className="flex-shrink-0 bg-yellow-100 p-2 rounded-full mr-4">
                <Layout className="h-5 w-5 text-yellow-700" />
              </span>
              <div>
                <p className="font-medium text-gray-800">Resources Available</p>
                <p className="text-gray-600 text-sm">
                  Your resources will be available in your dashboard
                </p>
              </div>
            </li>
            <li className="flex items-start bg-yellow-50 p-4 rounded-lg transition-all hover:shadow-md">
              <span className="flex-shrink-0 bg-yellow-100 p-2 rounded-full mr-4">
                <Calendar className="h-5 w-5 text-yellow-700" />
              </span>
              <div>
                <p className="font-medium text-gray-800">Event Details</p>
                <p className="text-gray-600 text-sm">
                  Event details and access information are in your account
                </p>
              </div>
            </li>
          </ul>
        </div>

        {/* Button with subtle animation */}
        <div className="pt-6">
          <button
            onClick={() => navigate("/dashboard")}
            className="w-full bg-gradient-to-r from-yellow-500 to-yellow-600 hover:from-yellow-600 hover:to-yellow-700 text-white py-4 rounded-lg transition-all duration-300 flex items-center justify-center gap-2 font-medium shadow-md hover:shadow-lg transform hover:-translate-y-1"
          >
            Go to Dashboard
            <ArrowRight className="w-5 h-5 animate-pulse" />
          </button>

          <p className="text-center text-gray-500 text-sm mt-4">
            Questions? Contact our{" "}
            <span className="text-yellow-600 hover:underline cursor-pointer">
              support team
            </span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default OrderSuccessPage;
