import React, { useEffect, useState } from "react";
import { GraduationCap, BookOpen, Users } from "lucide-react";

const RegistrationSelector = () => {
  const [selectedRole, setSelectedRole] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const registrationOptions = [
    {
      id: "teacher",
      title: " Teacher",
      link: "/sign-up",
      icon: Users,
    },
    {
      id: "student",
      title: " Student",
      link: "https://demo.nexonetics.com/register/",
      icon: GraduationCap,
    },
    {
      id: "tutor",
      title: " Tutor",
      link: "https://demo.nexonetics.com/register-2/",
      icon: BookOpen,
    },
  ];

  const handleSubmit = () => {
    if (!selectedRole) {
      alert("Please select a registration type");
      return;
    }

    const selectedRoleData = registrationOptions.find(
      (role) => role.id === selectedRole
    );
    window.location.href = selectedRoleData.link;
  };

  return (
    <div className="max-w-3xl mx-auto p-6 mt-72 mb-24">
      <div className="bg-white rounded-xl shadow-lg p-8">
        <h2 className="text-3xl text-gray-600 font-medium mb-8">
          Select Registration type{""}
          <span className=" ml-3 text-green-500">{""}*</span>
        </h2>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-8">
          {registrationOptions.map((option) => {
            const IconComponent = option.icon;
            return (
              <div
                key={option.id}
                className={`
                  p-6 rounded-lg border-2 cursor-pointer transition-all
                  ${
                    selectedRole === option.id
                      ? "bg-gradient-to-br from-yellow-300 via-yellow-400 to-yellow-500 border-yellow-500"
                      : "border-gray-200"
                  }
                  hover:border-yellow-400
                `}
                onClick={() => setSelectedRole(option.id)}
              >
                <div className="flex flex-col items-center text-center h-full">
                  <IconComponent
                    size={45}
                    className={`mb-4 ${
                      selectedRole === option.id
                        ? "text-white"
                        : "text-yellow-500"
                    }`}
                  />
                  <h3
                    className={`text-xl font-medium mb-2 ${
                      selectedRole === option.id
                        ? "text-white"
                        : "text-gray-700"
                    }`}
                  >
                    {option.title}
                  </h3>
                </div>
              </div>
            );
          })}
        </div>

        <div className="flex justify-center mt-8">
          <button
            onClick={handleSubmit}
            className={`
              px-8 py-2 rounded-lg text-white font-medium
              ${
                selectedRole
                  ? "bg-gradient-to-r from-yellow-300 to-yellow-400 hover:from-yellow-400 hover:to-yellow-500"
                  : "bg-gray-300 cursor-not-allowed"
              }
            `}
            disabled={!selectedRole}
          >
            Continue
          </button>
        </div>
      </div>
    </div>
  );
};

export default RegistrationSelector;
