import React, { useState, useEffect } from "react";
import { X, Upload, Star } from "lucide-react";

const AddTestimonialForm = ({ onClose, onSubmit, initialData }) => {
  const defaultFormData = {
    stars: 5,
    matter: "",
    user_id: 1,
    picture: null,
    name: "", // Added name field to default form data
  };

  const [formData, setFormData] = useState(defaultFormData);
  const [previewUrl, setPreviewUrl] = useState(null);
  const [users, setUsers] = useState([]);
  const [error, setError] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (initialData) {
      const { picture_url, ...rest } = initialData;
      setFormData(rest);
      if (picture_url) {
        setPreviewUrl(picture_url);
      }
    }
    fetchUsers();
  }, [initialData]);

  const fetchUsers = async () => {
    try {
      const token = localStorage.getItem("access_token");
      const response = await fetch("https://api2.made2tech.com/users/", {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) throw new Error("Failed to fetch users");
      const data = await response.json();
      setUsers(data);
    } catch (err) {
      setError("Error loading users: " + err.message);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (!file.type.startsWith("image/")) {
        setError("Please upload an image file");
        return;
      }

      if (file.size > 5 * 1024 * 1024) {
        setError("File size should be less than 5MB");
        return;
      }

      setFormData((prev) => ({
        ...prev,
        picture: file,
      }));

      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewUrl(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setIsSubmitting(true);

    try {
      const formDataToSend = new FormData();
      formDataToSend.append("stars", formData.stars);
      formDataToSend.append("matter", formData.matter);
      formDataToSend.append("user_id", formData.user_id);
      formDataToSend.append("name", formData.name); // Added name to form data

      if (formData.picture instanceof File) {
        formDataToSend.append("picture", formData.picture);
      }

      await onSubmit(formDataToSend);
    } catch (err) {
      setError("Error submitting form: " + err.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  const renderStarInput = () => {
    return (
      <div className="flex items-center space-x-2">
        {[1, 2, 3, 4, 5].map((star) => (
          <button
            key={star}
            type="button"
            onClick={() => setFormData((prev) => ({ ...prev, stars: star }))}
            className="focus:outline-none"
          >
            <Star
              className={`w-6 h-6 ${
                star <= formData.stars
                  ? "fill-yellow-400 text-yellow-400"
                  : "text-gray-300"
              }`}
            />
          </button>
        ))}
      </div>
    );
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
      <div className="bg-white rounded-lg w-full max-w-2xl max-h-[90vh] overflow-y-auto">
        <div className="flex justify-between items-center p-6 border-b">
          <h2 className="text-xl font-semibold">
            {initialData ? "Edit Testimonial" : "Add New Testimonial"}
          </h2>
          <button
            onClick={onClose}
            className="text-gray-500 hover:text-gray-700"
          >
            <X className="w-6 h-6" />
          </button>
        </div>

        <form onSubmit={handleSubmit} className="p-6 space-y-6">
          {error && (
            <div className="bg-red-50 text-red-700 p-4 rounded-lg">{error}</div>
          )}

          <div>
            <label className="block text-left text-sm font-medium text-gray-700 mb-2">
              Name
            </label>
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleInputChange}
              className="w-full p-2 border rounded focus:ring-2 focus:ring-blue-500 focus:border-transparent"
              required
            />
          </div>

          <div>
            <label className="block text-left text-sm font-medium text-gray-700 mb-2">
              Rating
            </label>
            {renderStarInput()}
          </div>

          <div>
            <label className="block text-left text-sm font-medium text-gray-700 mb-2">
              Testimonial
            </label>
            <textarea
              name="matter"
              value={formData.matter}
              onChange={handleInputChange}
              rows="4"
              className="w-full p-2 border rounded focus:ring-2 focus:ring-blue-500 focus:border-transparent"
              required
            />
          </div>

          <div>
            <label className="block text-left text-sm font-medium text-gray-700 mb-2">
              Picture
            </label>
            <div className="flex items-center space-x-4">
              {previewUrl && (
                <div className="relative w-24 h-24">
                  <img
                    src={previewUrl}
                    alt="Preview"
                    className="w-full h-full object-cover rounded"
                  />
                </div>
              )}
              <label className="cursor-pointer flex items-center justify-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50">
                <Upload className="w-4 h-4 mr-2" />
                {formData.picture ? "Change Picture" : "Upload Picture"}
                <input
                  type="file"
                  className="hidden"
                  accept="image/*"
                  onChange={handleFileChange}
                  required={!initialData}
                />
              </label>
            </div>
            <p className="mt-2 text-sm text-gray-500">
              Recommended: JPG, PNG. Less than: 2MB
            </p>
          </div>

          <div className="flex justify-end space-x-3 pt-4">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 border border-gray-300 rounded-md text-gray-700 hover:bg-gray-50"
              disabled={isSubmitting}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 disabled:opacity-50"
              disabled={isSubmitting}
            >
              {isSubmitting
                ? "Saving..."
                : initialData
                ? "Update Testimonial"
                : "Add Testimonial"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddTestimonialForm;
