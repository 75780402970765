import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useCart } from "../cart/CartProvider";
import GoToTopButton from "../GotoTop";
import Box from "../Dashboard/Box";
import ModernTestimonial from "../Course/ModernTestimonial";
import img from "../../Assets/Images/P2. Resources-min.jpg";

const Resources = () => {
  const navigate = useNavigate();
  const { addToCart } = useCart();
  const [resources, setResources] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [notification, setNotification] = useState({
    show: false,
    message: "",
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchResources();
  }, []);

  // Reference to track if component is mounted
  const isMounted = useRef(true);

  // Set isMounted to false when component unmounts
  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  // Auto-hide notification after 3 seconds with safety check
  useEffect(() => {
    let timer;
    if (notification.show) {
      timer = setTimeout(() => {
        if (isMounted.current) {
          setNotification({ show: false, message: "" });
        }
      }, 3000);
    }
    return () => clearTimeout(timer);
  }, [notification.show]);

  const fetchResources = async () => {
    try {
      setIsLoading(true);
      const response = await fetch(
        "https://api2.made2tech.com/resources/resources_with_theme?skip=0&limit=100"
      );

      if (!response.ok) {
        throw new Error("Failed to fetch resources");
      }

      const data = await response.json();

      // Process each resource to sort themes by created_at date
      const processedData = data.map((resource) => {
        // Sort themes by created_at timestamp (oldest first)
        const sortedThemes = [...resource.themes].sort((a, b) => {
          return new Date(a.created_at) - new Date(b.created_at);
        });

        return {
          ...resource,
          themes: sortedThemes,
        };
      });

      setResources(processedData);
      setError(null);
    } catch (error) {
      console.error("Error fetching resources:", error);
      setError("Failed to load resources. Please try again later.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleAddToCart = (resource, theme) => {
    // Check if user is authenticated
    const isAuthenticated = !!localStorage.getItem("token");

    if (!isAuthenticated) {
      // If not authenticated, redirect to login
      navigate("/login", {
        state: {
          redirectAfterLogin: "/resources",
          message: "Please log in to add items to your cart",
        },
      });
      return;
    }

    const resourceItem = {
      title: `${resource.data.title} - ${theme.name}`,
      price: theme.price,
      exam: resource.data.exam,
      type: "resource",
      resource_id: resource.id,
      theme_id: theme.id,
    };

    // Add to cart without redirecting
    addToCart(resourceItem);

    // Force state update for notification to trigger render
    setNotification({
      show: false,
      message: "",
    });

    // Small delay to ensure state change is registered before showing notification
    setTimeout(() => {
      setNotification({
        show: true,
        message: `${resource.data.title} - ${theme.name} added successfully to cart!`,
      });

      // Log to verify notification is being triggered
      console.log(
        "Notification shown:",
        `${resource.data.title} - ${theme.name}`
      );
    }, 10);
  };

  return (
    <div className="font-[Montserrat] text-[18px] leading-[26px] font-normal">
      {/* Notification component - improved visibility */}
      {notification.show && (
        <div
          className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-yellow-500 text-white px-8 py-4 rounded-md shadow-xl z-50 flex flex-col items-center"
          style={{
            minWidth: "300px",
            animation: "fadeIn 0.3s, fadeOut 0.3s 2.7s",
            boxShadow: "0 4px 15px rgba(0, 0, 0, 0.2)",
          }}
        >
          <div className="flex items-center mb-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-8 w-8 text-white mr-2"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M5 13l4 4L19 7"
              />
            </svg>
            <span className="text-xl font-bold">Success!</span>
          </div>
          <p className="text-center mb-2">{notification.message}</p>
          <button
            onClick={() => setNotification({ show: false, message: "" })}
            className="mt-2 bg-white text-yellow-500 px-4 py-1 rounded-md hover:bg-gray-100 transition-colors"
          >
            Close
          </button>
        </div>
      )}

      {/* Add style for animation */}
      <style jsx="true">{`
        @keyframes fadeIn {
          from {
            opacity: 0;
          }
          to {
            opacity: 1;
          }
        }
        @keyframes fadeOut {
          from {
            opacity: 1;
          }
          to {
            opacity: 0;
          }
        }
      `}</style>

      <section className="c-pageBanner c-pageBanner--resourses sectionContainer1">
        <div className="c-pageBanner__backgroundOverlay"></div>
        <div className="c-pageBanner__container responsivePadding">
          <div className="c-pageBanner__content">
            <h1 className="c-pageBanner__heading">Resources</h1>
          </div>
          <img src={img} alt="Resources banner" className="c-pageBanner__img" />
        </div>
      </section>

      <section style={{}} className="sectionContainer1">
        <div className="c-text c-text--aboutUs responsivePadding">
          <h2 className="c-text__title">
            <span className="first-line">
              Designed by teachers. Contributed by senior examiners.
            </span>
            <br />
            Tailored to you.
          </h2>
          <div className="c-text__textContainer">
            <p className="c-text__text">
              At Learnogic, we recognise that learning content is not enough to
              attain the highest levels and grades. Writing to the demands of,
              and meeting the assessment criteria of, exam boards is often
              challenging. There remains a significant gap between what the
              students write and what the examiners expect in answers.
            </p>
            <p className="c-text__text">
              With this in mind, we collaborated with senior examiners and
              created a comprehensive bank of model answers and other valuable
              resources. This allows each student to bridge the gap whilst
              benefitting from greater productivity. It improves written work,
              raises assessment levels, and thus produces best outcomes.
            </p>
            <p className="c-text__text">
              Our platform, therefore, aims to drive student engagement in a
              hybrid learning environment. Every student desires the right tools
              to enhance academic excellence, with the objective of gaining
              outstanding results. There is a strong demand for accessibility,
              and time-saving solutions, which only Learnogic offers
            </p>
          </div>
        </div>
      </section>

      <section className="sectionContainer1">
        <div className="space-y-12 text-left">
          {isLoading ? (
            <div className="flex justify-center py-12">
              <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-yellow-500"></div>
            </div>
          ) : error ? (
            <div className="bg-red-50 border border-red-200 rounded-lg p-6 text-center">
              <p className="text-red-600 mb-4">{error}</p>
              <button
                onClick={fetchResources}
                className="px-4 py-2 bg-yellow-500 text-white rounded-md hover:bg-yellow-600 transition-colors"
              >
                Try Again
              </button>
            </div>
          ) : (
            resources.map((resource) => (
              <div
                key={resource.id}
                className="bg-white rounded-lg shadow-md p-6"
              >
                <h2 className="text-2xl font-bold mb-2">
                  {resource.data.title}
                </h2>
                <p className="text-gray-600 mb-6">({resource.data.exam})</p>

                <div className="bg-gray-50 rounded-lg p-6">
                  <h3 className="text-lg font-semibold mb-4">Diagrams</h3>
                  <ul className="list-disc pl-5 space-y-3">
                    {resource.themes.map((theme) => (
                      <li key={theme.id} className="pl-2">
                        <div className="flex justify-between items-center">
                          <span className="text-gray-700">{theme.name}</span>
                          <div className="flex items-center space-x-4 ml-4">
                            <span className="font-medium">£{theme.price}</span>
                            <button
                              onClick={() => handleAddToCart(resource, theme)}
                              className="bg-yellow-500 text-white px-4 py-2 rounded-md hover:bg-yellow-700 transition-colors"
                            >
                              Buy
                            </button>
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            ))
          )}
        </div>
      </section>

      <ModernTestimonial />
      <div className="bgContainer linearBG">
        <Box />

        <section className="sectionContainer">
          <div className="c-cta">
            <div className="c-cta__title">Get in touch</div>
            <p className="c-cta__intro">
              If you require additional information or have any queries,
              <br /> please feel free to send us a message.
            </p>
            <button
              className="c-button ctaOrange"
              style={{
                background: "rgb(66 66 66)",
                border: "none",
                color: "#fff",
              }}
              onClick={() => {
                navigate("/contact");
              }}
            >
              Contact us
            </button>
          </div>
        </section>
      </div>

      <GoToTopButton />
    </div>
  );
};

export default Resources;
