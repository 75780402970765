import React, { useEffect, useState, useRef } from "react";
import { Share2, Heart } from "lucide-react";
import { useCart } from "../cart/CartProvider";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import Box from "../Dashboard/Box";
import ModernTestimonial from "./ModernTestimonial";
import GoToTopButton from "../GotoTop";

const EventDetailsPage = () => {
  const { addToCart } = useCart();
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();
  const [activeTab, setActiveTab] = useState("why-attend");
  const [isAddingToCart, setIsAddingToCart] = useState(false);
  const [notification, setNotification] = useState({
    show: false,
    message: "",
  });

  // Reference to track if component is mounted
  const isMounted = useRef(true);

  // Set isMounted to false when component unmounts
  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  // Auto-hide notification after 3 seconds with safety check
  useEffect(() => {
    let timer;
    if (notification.show) {
      timer = setTimeout(() => {
        if (isMounted.current) {
          setNotification({ show: false, message: "" });
        }
      }, 3000);
    }
    return () => clearTimeout(timer);
  }, [notification.show]);

  // Get event details from navigation state
  const event = location.state?.eventDetails || {
    title: "Event not found",
    hosts: [{ name: "Unknown Host" }],
    sessions: [
      {
        date: "N/A",
        time: "N/A",
        timezone: "N/A",
        location: "N/A",
        price: "N/A",
        type: "N/A",
      },
    ],
    description: "Event details not available",
    why_attend: { points: [], title: "", description: "" },
    programme: { schedule: [], title: "", description: "" },
    trainers: { items: [] },
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleAddToCart = async () => {
    // Check if user is authenticated
    const isAuthenticated = !!localStorage.getItem("token");

    if (!isAuthenticated) {
      // If not authenticated, redirect to login
      navigate("/login", {
        state: {
          redirectAfterLogin: `/event/${id}`,
          message: "Please log in to add events to your cart",
        },
      });
      return;
    }

    try {
      setIsAddingToCart(true);

      const eventItem = {
        title: event.title,
        price: event.sessions[0].price,
        date: event.sessions[0].date,
        time: event.sessions[0].time,
        location: event.sessions[0].location,
        type: "event",
        event_id: parseInt(id),
      };

      await addToCart(eventItem);

      // Force state update for notification to trigger render
      setNotification({
        show: false,
        message: "",
      });

      // Small delay to ensure state change is registered before showing notification
      setTimeout(() => {
        setNotification({
          show: true,
          message: `${event.title} added successfully to cart!`,
        });

        // Log to verify notification is being triggered
        console.log("Notification shown:", `${event.title}`);
      }, 10);
    } catch (error) {
      console.error("Error adding event to cart:", error);
      alert("Failed to add event to cart. Please try again.");
    } finally {
      setIsAddingToCart(false);
    }
  };

  return (
    <div>
      {/* Notification component - improved visibility */}
      {notification.show && (
        <div
          className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-yellow-500 text-white px-8 py-4 rounded-md shadow-xl z-50 flex flex-col items-center"
          style={{
            minWidth: "300px",
            animation: "fadeIn 0.3s, fadeOut 0.3s 2.7s",
            boxShadow: "0 4px 15px rgba(0, 0, 0, 0.2)",
          }}
        >
          <div className="flex items-center mb-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-8 w-8 text-white mr-2"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M5 13l4 4L19 7"
              />
            </svg>
            <span className="text-xl font-bold">Success!</span>
          </div>
          <p className="text-center mb-2">{notification.message}</p>
          <button
            onClick={() => setNotification({ show: false, message: "" })}
            className="mt-2 bg-white text-yellow-500 px-4 py-1 rounded-md hover:bg-gray-100 transition-colors"
          >
            Close
          </button>
        </div>
      )}

      {/* Add style for animation */}
      <style jsx="true">{`
        @keyframes fadeIn {
          from {
            opacity: 0;
          }
          to {
            opacity: 1;
          }
        }
        @keyframes fadeOut {
          from {
            opacity: 1;
          }
          to {
            opacity: 0;
          }
        }
      `}</style>

      <div className="max-w-7xl mx-auto px-4 py-28">
        <div className="grid grid-cols-6 gap-8">
          <div className="col-span-2 bg-gray-100 rounded-lg p-8 flex items-center justify-center h-[500px]">
            <img
              src="https://media.hachettelearning.com/medialibraries/hodder/website/images/contact-vector.svg?width=339"
              alt="Event illustration"
              className="w-full h-full object-contain"
            />
          </div>

          <div className="col-span-4">
            <div className="flex justify-between items-center mb-6">
              <button className="flex items-center gap-2 bg-white rounded-full px-4 py-2 shadow">
                <Share2 className="w-4 h-4" />
                <span>Share Event</span>
              </button>
              <button className="text-gray-600">
                <Heart className="w-6 h-6" />
              </button>
            </div>

            <div className="space-y-4 mb-8">
              <div className="flex gap-3">
                <span
                  style={{ backgroundColor: event.color || "rgb(32 123 191)" }}
                  className="text-black px-6 py-1.5 rounded text-sm"
                >
                  {event.sessions[0].type}
                </span>
              </div>
              <h1 className="text-left text-3xl font-bold">{event.title}</h1>
            </div>

            <div className="flex flex-wrap gap-8 mb-8 justify-between">
              {event.hosts.map((host, index) => (
                <div key={index} className="flex items-center gap-4">
                  <div className="w-16 h-16 bg-gray-600 rounded-full flex items-center justify-center text-white text-xl">
                    {host.initials ||
                      host.name
                        .split(" ")
                        .map((n) => n[0])
                        .join("")}
                  </div>
                  <div>
                    <p className="text-gray-600 text-sm text-left">Hosted By</p>
                    <p className="font-medium text-lg text-left">{host.name}</p>
                  </div>
                </div>
              ))}
              <div className="mt-4 flex justify-end">
                <button
                  onClick={handleAddToCart}
                  disabled={isAddingToCart}
                  style={{ backgroundColor: "rgb(32 123 191)" }}
                  className="bg-blue-900 text-white px-8 py-3 rounded hover:bg-blue-800 transition-colors disabled:opacity-75 disabled:cursor-not-allowed"
                >
                  {isAddingToCart ? "Adding..." : "Add to cart"}
                </button>
              </div>
            </div>

            <div className="mt-8 space-y-4">
              {event.sessions.map((session, index) => (
                <div
                  key={index}
                  className="bg-gray-50 rounded-lg p-6 text-left"
                >
                  <div>
                    <h6 className="font-medium mb-2">Location:</h6>
                    <p>{session.location}</p>
                  </div>

                  <div className="mt-4">
                    <h6 className="font-medium mb-2">Date & Time:</h6>
                    <div className="flex items-start gap-2">
                      <div
                        style={{ backgroundColor: "rgb(32 123 191)" }}
                        className="w-3 h-3 rounded-full border-2 border-blue-600 mt-1.5"
                      />
                      <div>
                        <p>{`${session.date}, ${session.time}, ${session.timezone}`}</p>
                      </div>
                    </div>
                  </div>

                  <div className="mt-4 text-right">
                    <p className="text-4xl font-bold">{session.price}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="spacing-48px"></div>
        <div className="spacing-48px"></div>

        <div className="max-w-7xl mx-auto p-6">
          <nav className="border-b border-gray-200 mb-6">
            <ul className="flex space-x-8">
              {[
                { id: "why-attend", label: "Why attend?" },
                { id: "programme", label: "Programme" },
                { id: "trainers", label: "Trainer(s)" },
              ].map((tab) => (
                <li
                  key={tab.id}
                  className={`pl-0 cursor-pointer pb-2 border-b-2 transition-all duration-200 ${
                    activeTab === tab.id
                      ? "text-amber-500 font-semibold border-amber-500"
                      : "text-gray-600 border-transparent hover:text-amber-400 hover:border-amber-400"
                  }`}
                  onClick={() => setActiveTab(tab.id)}
                >
                  {tab.label}
                </li>
              ))}
            </ul>
          </nav>

          <div className="mt-6">
            {activeTab === "why-attend" && (
              <div className="space-y-6 bg-gray-50 p-6 rounded-lg text-left">
                <h2 className="text-xl font-semibold text-gray-900">
                  {event.why_attend?.title ||
                    `${event.title} - Course Overview`}
                </h2>

                <div className="space-y-4">
                  <p className="text-gray-700 leading-relaxed">
                    {event.why_attend?.description || event.description}
                  </p>

                  <div className="space-y-4">
                    <p className="font-medium text-gray-800">Key Benefits:</p>
                    <ul className="space-y-3">
                      {(
                        event.why_attend?.points || [
                          "Learn from industry experts and experienced practitioners",
                          "Gain practical insights and applicable knowledge",
                          "Network with fellow professionals in your field",
                          "Receive comprehensive course materials and resources",
                        ]
                      ).map((point, index) => (
                        <li key={index} className="flex items-start">
                          <span className="text-amber-500 mr-2">→</span>
                          <span>{point}</span>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>

                <div className="mt-6 space-y-4">
                  <p className="text-sm text-gray-600">
                    Course Reference: {id}
                  </p>
                  <p className="text-sm text-gray-600 italic">
                    Please note: All participants will receive a certificate of
                    completion.
                  </p>
                </div>
              </div>
            )}

            {activeTab === "programme" && (
              <div className="space-y-4 text-left">
                {event.programme?.schedule ? (
                  <>
                    {event.programme.title && (
                      <h2 className="text-xl font-semibold text-gray-900 mb-4">
                        {event.programme.title}
                      </h2>
                    )}
                    {event.programme.description && (
                      <p className="text-gray-700 mb-6">
                        {event.programme.description}
                      </p>
                    )}
                    {event.programme.schedule.map((item, index) => (
                      <div
                        key={index}
                        className={`p-4 rounded-lg transition-colors duration-200 ${
                          index % 2 === 0 ? "bg-gray-50" : "bg-white"
                        }`}
                      >
                        <div className="flex space-x-6">
                          <span className="text-amber-600 font-medium w-20">
                            {item.time}
                          </span>
                          <div className="flex-1">
                            <p className="text-gray-800 font-medium">
                              {item.title}
                            </p>
                            {item.details && item.details.length > 0 && (
                              <ul className="mt-2 space-y-2">
                                {item.details.map((detail, idx) => (
                                  <li key={idx} className="flex space-x-3">
                                    <span className="text-amber-400">→</span>
                                    <span className="text-gray-700">
                                      {detail}
                                    </span>
                                  </li>
                                ))}
                              </ul>
                            )}
                          </div>
                        </div>
                      </div>
                    ))}
                  </>
                ) : (
                  <p className="text-gray-700">
                    Programme details will be announced soon.
                  </p>
                )}
              </div>
            )}

            {activeTab === "trainers" && (
              <div className="bg-gray-50 p-6 rounded-lg">
                <div className="space-y-6">
                  {(event.trainers?.items || event.hosts).map(
                    (trainer, index) => (
                      <div key={index} className="space-y-4">
                        <h2 className="text-lg font-medium text-gray-900">
                          {trainer.name}
                        </h2>
                        <div className="flex items-center gap-4">
                          {trainer.photo?.url ? (
                            <img
                              src={trainer.photo.url}
                              alt={trainer.name}
                              className="w-20 h-20 rounded-full object-cover"
                            />
                          ) : (
                            <div className="w-20 h-20 bg-gray-600 rounded-full flex items-center justify-center text-white text-2xl">
                              {trainer.name
                                .split(" ")
                                .map((n) => n[0])
                                .join("")}
                            </div>
                          )}
                          <p className="text-gray-700 leading-relaxed flex-1">
                            {trainer.bio ||
                              `Expert trainer with extensive experience in ${event.title.toLowerCase()}. Specializes in delivering engaging and informative sessions that combine theoretical knowledge with practical applications.`}
                          </p>
                        </div>
                      </div>
                    )
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <ModernTestimonial />

      <div className="bgContainer linearBG">
        <Box />
      </div>

      <GoToTopButton />
    </div>
  );
};

export default EventDetailsPage;
