import React, { useState, useEffect } from "react";
import {
  Home,
  Book,
  Calendar,
  ShoppingCart,
  User,
  LogOut,
  Menu,
  X,
  ChevronRight,
  Trash2,
  Download,
  ExternalLink,
  FileText,
  File,
  Image,
  CreditCard,
  Edit,
  Settings,
} from "lucide-react";
import { useNavigate } from "react-router-dom";
import { useCallback } from "react";

const DashboardLayout = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [activeSection, setActiveSection] = useState("account"); // Set default to resources
  const [userData, setUserData] = useState(null);
  console.log(userData);

  const [isLoadingProfile, setIsLoadingProfile] = useState(false);
  const [profileError, setProfileError] = useState(null);
  const [purchaseData, setPurchaseData] = useState([]);
  const [isLoadingPurchases, setIsLoadingPurchases] = useState(false);
  const [purchasesError, setPurchasesError] = useState(null);
  const [downloadStatus, setDownloadStatus] = useState({});
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  // window.location.reload();
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const checkLoginStatus = useCallback(() => {
    const token = localStorage.getItem("token");
    setIsLoggedIn(!!token);
  }, []);

  // Check if the user is logged in using the token in localStorage
  useEffect(() => {
    // Initial check
    checkLoginStatus();

    // Add event listener to detect localStorage changes in other tabs/windows
    const handleStorageChange = (e) => {
      if (e.key === "token") {
        checkLoginStatus();
      }
    };

    window.addEventListener("storage", handleStorageChange);

    // Custom event for local changes within the same window
    const handleTokenChange = () => {
      checkLoginStatus();
    };

    window.addEventListener("tokenChanged", handleTokenChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
      window.removeEventListener("tokenChanged", handleTokenChange);
    };
  }, [checkLoginStatus]);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/login", {
        state: {
          redirectAfterLogin: "/dashboard",
          message: "Please log in to access your dashboard",
        },
      });
    } else {
      // Fetch purchases data immediately on mount
      fetchPurchasesData();
    }
  }, [navigate]);

  // Fetch user profile data when profile section is active
  useEffect(() => {
    if (activeSection === "profile" || activeSection === "account") {
      fetchUserProfile();
    }
  }, [activeSection]);

  const fetchUserProfile = async () => {
    setIsLoadingProfile(true);
    setProfileError(null);

    try {
      const token = localStorage.getItem("token");

      if (!token) {
        throw new Error("Authentication token not found");
      }

      const response = await fetch("https://api2.made2tech.com/users/me", {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error(`Failed to fetch profile: ${response.status}`);
      }

      const data = await response.json();
      setUserData(data);
    } catch (error) {
      console.error("Error fetching user profile:", error);
      setProfileError(error.message || "Failed to load profile information");
    } finally {
      setIsLoadingProfile(false);
    }
  };

  const fetchPurchasesData = async () => {
    setIsLoadingPurchases(true);
    setPurchasesError(null);

    try {
      const token = localStorage.getItem("token");

      if (!token) {
        throw new Error("Authentication token not found");
      }

      const response = await fetch("https://api2.made2tech.com/purchases/", {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error(`Failed to fetch purchases: ${response.status}`);
      }

      const data = await response.json();
      console.log("Purchases data:", data);
      setPurchaseData(data);
    } catch (error) {
      console.error("Error fetching purchases:", error);
      setPurchasesError(error.message || "Failed to load purchase information");
    } finally {
      setIsLoadingPurchases(false);
    }
  };

  const handleDownloadResource = async (url, fileName, fileId) => {
    try {
      setDownloadStatus((prev) => ({ ...prev, [fileId]: "downloading" }));

      const token = localStorage.getItem("token");
      if (!token) {
        throw new Error("Authentication token not found");
      }

      // Create a download link
      const a = document.createElement("a");
      a.href = url;
      a.download = fileName || "download";
      a.target = "_blank";

      // Start the download
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);

      // Update download status after a short delay to show success
      setTimeout(() => {
        setDownloadStatus((prev) => ({ ...prev, [fileId]: "success" }));

        // Reset status after showing success
        setTimeout(() => {
          setDownloadStatus((prev) => ({ ...prev, [fileId]: null }));
        }, 2000);
      }, 500);
    } catch (error) {
      console.error("Error downloading resource:", error);
      setDownloadStatus((prev) => ({ ...prev, [fileId]: "error" }));

      // Reset error status after a delay
      setTimeout(() => {
        setDownloadStatus((prev) => ({ ...prev, [fileId]: null }));
      }, 3000);
    }
  };

  // Function to handle "check all recent orders" click
  const handleRecentOrders = () => {
    setActiveSection("orders");
  };

  const navigation = [
    { id: "account", name: "My Account", icon: User },
    { id: "resources", name: "My Resources", icon: Book },
    { id: "events", name: "My Events", icon: Calendar },
    { id: "profile", name: "Profile", icon: Settings },
    { id: "orders", name: "Recent Orders", icon: ShoppingCart },
  ];

  const handleLogout = () => {
    localStorage.removeItem("token");
    navigate("/login");
  };

  // Update the getPurchasedResources function
  const getPurchasedResources = () => {
    // Create a Map to track unique resources by ID and transaction
    const resourceMap = new Map();

    purchaseData.forEach((purchase) => {
      purchase.purchase_items.forEach((item) => {
        if (item.item_type === "resource" && item.resource && item.theme) {
          // Create a unique key combining resource ID and transaction ID
          const uniqueKey = `${item.resource.id}-${purchase.transaction_id}`;

          // Only add if this exact resource from this transaction hasn't been added yet
          if (!resourceMap.has(uniqueKey)) {
            resourceMap.set(uniqueKey, {
              id: item.resource.id,
              title: item.resource.name,
              theme: item.theme.name,
              price: item.theme.price
                ? parseFloat(item.theme.price)
                : item.price / (item.quantity || 1),
              unitPrice: item.theme.price
                ? parseFloat(item.theme.price)
                : item.price / (item.quantity || 1),
              subtotal: item.price,
              totalPrice: purchase.total_amount,
              transactionId: purchase.transaction_id,
              purchaseDate: new Date(purchase.created_at).toLocaleDateString(),
              quantity: item.quantity || 1,
              // Add files for UI demonstration
              files: item.theme.data?.pdfs || [],
              docs: item.theme.data?.docs || [],
              images: item.theme.data?.images || [],
            });
          }
        }
      });
    });

    // Convert Map values to array
    return Array.from(resourceMap.values());
  };

  // Similarly update the getPurchasedEvents function
  const getPurchasedEvents = () => {
    // Create a Map to track unique events by ID and transaction
    const eventMap = new Map();

    purchaseData.forEach((purchase) => {
      purchase.purchase_items.forEach((item) => {
        if (item.item_type === "event" && item.event) {
          // Create a unique key combining event ID and transaction ID
          const uniqueKey = `${item.event.id}-${purchase.transaction_id}`;

          // Only add if this exact event from this transaction hasn't been added yet
          if (!eventMap.has(uniqueKey)) {
            const unitPrice =
              item.event.price === "Free" ? 0 : parseFloat(item.event.price);

            eventMap.set(uniqueKey, {
              id: item.event.id,
              title: item.event.name,
              price: unitPrice,
              unitPrice: unitPrice,
              subtotal: item.price,
              totalPrice: purchase.total_amount,
              transactionId: purchase.transaction_id,
              purchaseDate: new Date(purchase.created_at).toLocaleDateString(),
              quantity: item.quantity || 1,
              // Add mock date and time for UI demonstration
              date: "2025-04-15",
              time: "10:00 AM",
              location: "Online",
            });
          }
        }
      });
    });

    // Convert Map values to array
    return Array.from(eventMap.values());
  };
  // Function to get appropriate icon based on file type
  const getFileIcon = (file) => {
    const fileType = file.file_type?.toLowerCase() || "";

    if (fileType.includes("pdf")) {
      return <FileText className="w-4 h-4 text-red-500" />;
    } else if (fileType.includes("doc")) {
      return <FileText className="w-4 h-4 text-blue-500" />;
    } else if (
      fileType.includes("image") ||
      fileType.includes("png") ||
      fileType.includes("jpg") ||
      fileType.includes("jpeg")
    ) {
      return <Image className="w-4 h-4 text-green-500" />;
    } else {
      return <File className="w-4 h-4 text-gray-500" />;
    }
  };

  // Function to get download button state
  const getDownloadButton = (file, idx) => {
    const fileId = `${file.url}-${idx}`;
    const status = downloadStatus[fileId];

    if (status === "downloading") {
      return (
        <div className="animate-spin rounded-full h-4 w-4 border-b-2 border-yellow-500"></div>
      );
    } else if (status === "success") {
      return (
        <div className="text-green-500">
          <Download className="w-4 h-4" />
        </div>
      );
    } else if (status === "error") {
      return (
        <div className="text-red-500">
          <Download className="w-4 h-4" />
        </div>
      );
    }

    return (
      <button
        className="text-yellow-600 hover:text-yellow-700"
        onClick={() =>
          handleDownloadResource(file.url, file.original_name, fileId)
        }
      >
        <Download className="w-4 h-4" />
      </button>
    );
  };

  const renderContent = () => {
    // Loading state for purchases
    if (
      (activeSection === "resources" ||
        activeSection === "events" ||
        activeSection === "orders") &&
      isLoadingPurchases
    ) {
      return (
        <div className="bg-white rounded-lg p-6 shadow-sm text-center">
          <div className="flex justify-center py-8">
            <div className="animate-spin rounded-full h-10 w-10 border-b-2 border-yellow-500"></div>
          </div>
          <p className="text-gray-600">Loading your purchases...</p>
        </div>
      );
    }

    // Error state for purchases
    if (
      (activeSection === "resources" ||
        activeSection === "events" ||
        activeSection === "orders") &&
      purchasesError
    ) {
      return (
        <div className="bg-white rounded-lg p-6 shadow-sm">
          <div className="text-red-500 mb-4">{purchasesError}</div>
          <button
            onClick={fetchPurchasesData}
            className="px-4 py-2 bg-yellow-500 text-white rounded hover:bg-yellow-600 transition"
          >
            Try Again
          </button>
        </div>
      );
    }

    switch (activeSection) {
      case "account":
        return (
          <div className="bg-white rounded-lg p-6 shadow-sm">
            <h2 className="text-3xl font-bold text-yellow-600 mb-6">
              My account
            </h2>

            <div className="mb-8">
              <h3 className="text-xl mb-2">
                Hello {userData?.full_name || ""}{" "}
                <span className="text-gray-500 font-normal">
                  (not {userData?.full_name || ""}?{" "}
                  <a
                    href="#"
                    onClick={handleLogout}
                    className="text-yellow-600 hover:underline"
                  >
                    Log out
                  </a>
                  )
                </span>
              </h3>
              <p className="text-gray-700">
                From your account dashboard you can
              </p>

              <ul className="mt-4 space-y-2">
                <li className="flex items-center text-yellow-600 hover:text-yellow-700">
                  <ChevronRight className="w-4 h-4 mr-2" />
                  <a
                    href="#"
                    onClick={handleRecentOrders}
                    className="hover:underline"
                  >
                    check all recent orders
                  </a>
                </li>

                <li className="flex items-center text-yellow-600 hover:text-yellow-700">
                  <ChevronRight className="w-4 h-4 mr-2" />
                  <a
                    href="#"
                    onClick={() => navigate("/account/details")}
                    className="hover:underline"
                  >
                    update account details
                  </a>
                </li>
              </ul>
            </div>

            {/* Account Dashboard Summary */}
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mt-8">
              <div className="bg-gray-50 p-4 rounded-lg shadow-sm">
                <h4 className="font-medium mb-2 flex items-center">
                  <ShoppingCart className="w-5 h-5 mr-2 text-yellow-600" />
                  Orders
                </h4>
                <p className="text-gray-600 text-sm">
                  View your recent purchases and download resources
                </p>
                <button
                  onClick={() => setActiveSection("orders")}
                  className="mt-4 text-yellow-600 hover:text-yellow-700 text-sm font-medium"
                >
                  View Orders →
                </button>
              </div>

              <div className="bg-gray-50 p-4 rounded-lg shadow-sm">
                <h4 className="font-medium mb-2 flex items-center">
                  <Calendar className="w-5 h-5 mr-2 text-yellow-600" />
                  Events
                </h4>
                <p className="text-gray-600 text-sm">
                  Check your upcoming events and registrations
                </p>
                <button
                  onClick={() => setActiveSection("events")}
                  className="mt-4 text-yellow-600 hover:text-yellow-700 text-sm font-medium"
                >
                  View Events →
                </button>
              </div>

              <div className="bg-gray-50 p-4 rounded-lg shadow-sm">
                <h4 className="font-medium mb-2 flex items-center">
                  <User className="w-5 h-5 mr-2 text-yellow-600" />
                  Profile
                </h4>
                <p className="text-gray-600 text-sm">
                  Edit your account details and preferences
                </p>
                <button
                  onClick={() => setActiveSection("profile")}
                  className="mt-4 text-yellow-600 hover:text-yellow-700 text-sm font-medium"
                >
                  Edit Profile →
                </button>
              </div>
            </div>
          </div>
        );

      case "orders":
        const orderResources = getPurchasedResources();
        const orderEvents = getPurchasedEvents();

        return (
          <div className="bg-white rounded-lg p-6 shadow-sm">
            <h2 className=" text-left text-xl font-semibold mb-6">
              Recent Orders
            </h2>

            {orderResources.length === 0 && orderEvents.length === 0 ? (
              <div className="text-center py-8">
                <ShoppingCart className="w-12 h-12 mx-auto text-gray-400 mb-4" />
                <p className="text-gray-600">
                  You haven't made any purchases yet.
                </p>
                <div className="mt-4 flex flex-wrap justify-center gap-3">
                  <button
                    onClick={() => navigate("/resources")}
                    className="px-4 py-2 bg-yellow-500 text-white rounded hover:bg-yellow-600 transition"
                  >
                    Browse Resources
                  </button>
                  <button
                    onClick={() => navigate("/events")}
                    className="px-4 py-2 bg-yellow-500 text-white rounded hover:bg-yellow-600 transition"
                  >
                    Browse Events
                  </button>
                </div>
              </div>
            ) : (
              <>
                {/* Group all purchases by transaction ID */}
                {(() => {
                  const allPurchases = [
                    ...orderResources.map((item) => ({
                      ...item,
                      type: "resource",
                    })),
                    ...orderEvents.map((item) => ({ ...item, type: "event" })),
                  ];

                  // Group by transaction ID
                  const groupedByTransaction = {};
                  allPurchases.forEach((item) => {
                    if (!groupedByTransaction[item.transactionId]) {
                      // Find the corresponding purchase in the original data to get the total_amount
                      const originalPurchase = purchaseData.find(
                        (p) => p.transaction_id === item.transactionId
                      );

                      groupedByTransaction[item.transactionId] = {
                        transactionId: item.transactionId,
                        purchaseDate: item.purchaseDate,
                        totalAmount: originalPurchase
                          ? originalPurchase.total_amount
                          : 0,
                        items: [],
                      };
                    }
                    groupedByTransaction[item.transactionId].items.push(item);
                  });

                  // Convert to array and sort by date (newest first)
                  const transactions = Object.values(groupedByTransaction).sort(
                    (a, b) => {
                      return (
                        new Date(b.purchaseDate) - new Date(a.purchaseDate)
                      );
                    }
                  );
                  return (
                    <div className="space-y-8">
                      {transactions.map((transaction) => (
                        <div
                          key={transaction.transactionId}
                          className=" text-left border rounded-lg overflow-hidden"
                        >
                          <div className="bg-gray-50 p-4 border-b">
                            <div className="flex justify-between items-center">
                              <div>
                                <h3 className="font-medium">
                                  Order #{transaction.transactionId}
                                </h3>
                                <p className="text-sm text-gray-500">
                                  Purchased on {transaction.purchaseDate}
                                </p>
                              </div>
                              <div className="flex items-center gap-3">
                                <span className="px-3 py-1 bg-green-100 text-green-800 rounded-full text-xs">
                                  Completed
                                </span>
                                <span className="font-medium">
                                  Total: ${transaction.totalAmount.toFixed(2)}
                                </span>
                              </div>
                            </div>
                          </div>

                          <div className="p-4">
                            <h4 className="font-medium mb-3">Order Items:</h4>
                            <div className="space-y-4">
                              {transaction.items.map((item, idx) => (
                                <div
                                  key={`${item.type}-${item.id}-${idx}`}
                                  className="flex flex-col md:flex-row justify-between p-3 border rounded-lg"
                                >
                                  <div className="flex items-start gap-3">
                                    {item.type === "resource" ? (
                                      <Book className="w-8 h-8 text-yellow-600 flex-shrink-0 mt-1" />
                                    ) : (
                                      <Calendar className="w-8 h-8 text-yellow-600 flex-shrink-0 mt-1" />
                                    )}
                                    <div>
                                      <h5 className="font-medium">
                                        {item.title}
                                      </h5>
                                      {item.type === "resource" && (
                                        <p className="text-sm text-gray-600">
                                          {item.theme}
                                        </p>
                                      )}
                                      {item.type === "event" && (
                                        <div className="text-sm text-gray-600">
                                          <p>
                                            {item.date} at {item.time}
                                          </p>
                                          <p>Location: {item.location}</p>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                  <div className="mt-3 md:mt-0 flex flex-col md:items-end">
                                    <div className="flex flex-col items-end">
                                      <div className="text-sm font-medium">
                                        Price:{" "}
                                        {item.type === "resource" ||
                                        item.unitPrice > 0
                                          ? `$${item.unitPrice.toFixed(2)}`
                                          : "Free"}
                                      </div>
                                      {item.type === "resource" && (
                                        <div className="text-xs text-gray-700">
                                          Quantity: {item.quantity}
                                        </div>
                                      )}
                                      {item.type === "event" && (
                                        <div className="text-xs text-gray-700">
                                          Attendees: {item.attendees || 1}
                                        </div>
                                      )}
                                      <div className="text-sm font-medium text-yellow-600 mt-1 border-t pt-1">
                                        <div className="flex items-center justify-between gap-2">
                                          <span>Subtotal:</span>
                                          <span>
                                            {item.type === "resource" ||
                                            item.subtotal > 0
                                              ? `$${item.subtotal.toFixed(2)}`
                                              : "Free"}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="mt-2">
                                      {item.type === "resource" && (
                                        <button
                                          onClick={() =>
                                            setActiveSection("resources")
                                          }
                                          className="text-sm text-yellow-600 hover:text-yellow-700"
                                        >
                                          View Files →
                                        </button>
                                      )}
                                      {item.type === "event" && (
                                        <button
                                          onClick={() =>
                                            setActiveSection("events")
                                          }
                                          className="text-sm text-yellow-600 hover:text-yellow-700"
                                        >
                                          View Details →
                                        </button>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  );
                })()}
              </>
            )}
          </div>
        );

      case "resources":
        const purchasedResources = getPurchasedResources();

        return (
          <div className="bg-white rounded-lg p-6 shadow-sm">
            <h2 className="text-xl font-semibold mb-6">My Resources</h2>

            {purchasedResources.length === 0 ? (
              <div className="text-center py-8">
                <Book className="w-12 h-12 mx-auto text-gray-400 mb-4" />
                <p className="text-gray-600">
                  You haven't purchased any resources yet.
                </p>
                <button
                  onClick={() => navigate("/resources")}
                  className="mt-4 px-4 py-2 bg-yellow-500 text-white rounded hover:bg-yellow-600 transition"
                >
                  Browse Resources
                </button>
              </div>
            ) : (
              <div className="text-left grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                {purchasedResources.map((resource) => (
                  <div
                    key={`${resource.id}-${resource.transactionId}`}
                    className="rounded-lg p-4 shadow-sm border border-gray-100"
                  >
                    <div className="flex justify-between items-start">
                      <div className="max-w-[65%]">
                        <h3 className="text-sm font-medium mb-1">
                          {resource.title}
                        </h3>
                        <p className="text-xs text-gray-600 mb-1">
                          {resource.theme}
                        </p>
                        <p className="text-xs text-gray-500">
                          Purchased: {resource.purchaseDate}
                        </p>
                        <p className="text-xs text-gray-500 mb-2">
                          Order #: {resource.transactionId}
                        </p>
                      </div>
                      <div className="flex flex-col items-end text-right">
                        <div className="text-sm font-medium">
                          Price: ${resource.unitPrice.toFixed(2)}
                        </div>
                        <div className="text-xs text-gray-700 mt-1">
                          Qty: {resource.quantity}
                        </div>
                        <div className="text-sm font-medium text-yellow-600 mt-1 border-t pt-1">
                          <div className="flex items-center justify-between gap-2">
                            <span>Subtotal:</span>
                            <span>${resource.subtotal.toFixed(2)}</span>
                          </div>
                        </div>
                        <div className="text-xs text-gray-500 mt-1">
                          {/* Order Total: ${resource.totalPrice.toFixed(2)} */}
                        </div>
                      </div>
                    </div>

                    {/* Available Files Section */}
                    <div className="mt-3">
                      <p className="text-xs font-medium mb-1">
                        Available Files:
                      </p>
                      <div className="space-y-1 max-h-36 overflow-y-auto pr-1">
                        {/* PDF Files */}
                        {resource.files && resource.files.length > 0
                          ? resource.files.map((file, idx) => (
                              <div
                                key={`pdf-${idx}`}
                                className="flex items-center justify-between text-xs p-1 bg-gray-50 rounded"
                              >
                                <div className="flex items-center mr-2 truncate">
                                  <FileText className="w-3 h-3 text-red-500 mr-1 flex-shrink-0" />
                                  <span className="truncate max-w-[150px]">
                                    {file.original_name || `PDF ${idx + 1}`}
                                  </span>
                                </div>
                                {getDownloadButton(file, `pdf-${idx}`)}
                              </div>
                            ))
                          : null}

                        {/* Doc Files */}
                        {resource.docs && resource.docs.length > 0
                          ? resource.docs.map((file, idx) => (
                              <div
                                key={`doc-${idx}`}
                                className="flex items-center justify-between text-xs p-1 bg-gray-50 rounded"
                              >
                                <div className="flex items-center mr-2 truncate">
                                  <FileText className="w-3 h-3 text-blue-500 mr-1 flex-shrink-0" />
                                  <span className="truncate max-w-[150px]">
                                    {file.original_name ||
                                      `Document ${idx + 1}`}
                                  </span>
                                </div>
                                {getDownloadButton(file, `doc-${idx}`)}
                              </div>
                            ))
                          : null}

                        {/* Image Files */}
                        {resource.images && resource.images.length > 0
                          ? resource.images.map((file, idx) => (
                              <div
                                key={`img-${idx}`}
                                className="flex items-center justify-between text-xs p-1 bg-gray-50 rounded"
                              >
                                <div className="flex items-center mr-2 truncate">
                                  <Image className="w-3 h-3 text-green-500 mr-1 flex-shrink-0" />
                                  <span className="truncate max-w-[150px]">
                                    {file.original_name || `Image ${idx + 1}`}
                                  </span>
                                </div>
                                {getDownloadButton(file, `img-${idx}`)}
                              </div>
                            ))
                          : null}

                        {/* No files message */}
                        {(!resource.files || resource.files.length === 0) &&
                          (!resource.docs || resource.docs.length === 0) &&
                          (!resource.images ||
                            resource.images.length === 0) && (
                            <p className="text-xs text-gray-500 italic">
                              No downloadable files available
                            </p>
                          )}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        );

      case "events":
        const purchasedEvents = getPurchasedEvents();

        return (
          <div className="bg-white rounded-lg p-6 shadow-sm">
            <h2 className="text-xl font-semibold mb-6">My Events</h2>

            {purchasedEvents.length === 0 ? (
              <div className="text-center py-8">
                <Calendar className="w-12 h-12 mx-auto text-gray-400 mb-4" />
                <p className="text-gray-600">
                  You haven't registered for any events yet.
                </p>
                <button
                  onClick={() => navigate("/events")}
                  className="mt-4 px-4 py-2 bg-yellow-500 text-white rounded hover:bg-yellow-600 transition"
                >
                  Browse Events
                </button>
              </div>
            ) : (
              <div className="space-y-4">
                {purchasedEvents.map((event) => (
                  <div
                    key={`${event.id}-${event.transactionId}`}
                    className="text-left rounded-lg p-4 shadow-sm border border-gray-100"
                  >
                    <div className="flex justify-between flex-col md:flex-row">
                      <div>
                        <h3 className="font-medium mb-1">{event.title}</h3>
                        <p className="text-xs text-gray-500 mb-1">
                          Purchased: {event.purchaseDate}
                        </p>
                        <p className="text-xs text-gray-500 mb-2">
                          Order #: {event.transactionId}
                        </p>

                        <div className="flex items-center text-sm text-gray-600 mt-2">
                          <Calendar className="w-4 h-4 mr-2" />
                          <span>
                            {event.date} at {event.time}
                          </span>
                        </div>

                        <p className="text-sm text-gray-600 mt-2">
                          <span className="font-medium">Location:</span>{" "}
                          {event.location}
                        </p>
                      </div>

                      <div className="flex flex-col items-start md:items-end mt-4 md:mt-0">
                        <div className="text-sm font-medium">
                          Price:{" "}
                          {event.unitPrice > 0
                            ? `$${event.unitPrice.toFixed(2)}`
                            : "Free"}
                        </div>
                        <div className="text-xs text-gray-700 mt-1">
                          Attendees: {event.quantity}
                        </div>
                        <div className="text-sm font-medium text-yellow-600 mt-1 border-t pt-1">
                          <div className="flex items-center justify-between gap-2">
                            <span>Subtotal:</span>
                            <span>
                              {event.subtotal > 0
                                ? `$${event.subtotal.toFixed(2)}`
                                : "Free"}
                            </span>
                          </div>
                        </div>
                        <div className="text-xs text-gray-500 mt-1">
                          {/* Order Total: ${event.totalPrice.toFixed(2)} */}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        );

      case "profile":
        if (isLoadingProfile) {
          return (
            <div className="bg-white rounded-lg p-6 shadow-sm text-center">
              <div className="flex justify-center py-8">
                <div className="animate-spin rounded-full h-10 w-10 border-b-2 border-yellow-500"></div>
              </div>
              <p className="text-gray-600">Loading your profile...</p>
            </div>
          );
        }

        if (profileError) {
          return (
            <div className="bg-white rounded-lg p-6 shadow-sm">
              <div className="text-red-500 mb-4">{profileError}</div>
              <button
                onClick={fetchUserProfile}
                className="px-4 py-2 bg-yellow-500 text-white rounded hover:bg-yellow-600 transition"
              >
                Try Again
              </button>
            </div>
          );
        }

        return (
          <div className="bg-white rounded-lg p-6 shadow-sm">
            <h2 className="text-xl font-semibold mb-6">Profile</h2>
            <div className="max-w-2xl">
              <div className="flex items-center space-x-4 mb-6">
                <div className="w-20 h-20 bg-gray-200 rounded-full flex items-center justify-center">
                  <User className="w-8 h-8 text-gray-500" />
                </div>
                <div>
                  <h3 className="font-medium text-lg">
                    {userData?.full_name || "William Jones"}
                  </h3>
                  <p className="text-gray-600">
                    {userData?.email || "william.jones@example.com"}
                  </p>
                  {userData?.user_type && (
                    <span className="inline-block mt-1 px-3 py-1 bg-yellow-100 text-yellow-800 rounded-full text-xs">
                      {userData.user_type}
                    </span>
                  )}
                </div>
              </div>
              <div className="space-y-4">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <div>
                    <label className="block text-left text-sm font-medium text-gray-700 mb-1">
                      Full Name
                    </label>
                    <input
                      type="text"
                      className="w-full px-3 py-2 rounded-lg bg-gray-50"
                      value={userData?.full_name || "William Jones"}
                      readOnly
                    />
                  </div>
                  <div>
                    <label className="block text-left text-sm font-medium text-gray-700 mb-1">
                      Email
                    </label>
                    <input
                      type="email"
                      className="w-full px-3 py-2 rounded-lg bg-gray-50"
                      value={userData?.email || "william.jones@example.com"}
                      readOnly
                    />
                  </div>
                </div>

                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <div>
                    <label className="block text-left text-sm font-medium text-gray-700 mb-1">
                      Title
                    </label>
                    <input
                      type="text"
                      className="w-full px-3 py-2 rounded-lg bg-gray-50"
                      value={userData?.title || "Mr."}
                      readOnly
                    />
                  </div>
                  <div>
                    <label className="block text-left text-sm font-medium text-gray-700 mb-1">
                      Job Title
                    </label>
                    <input
                      type="text"
                      className="w-full px-3 py-2 rounded-lg bg-gray-50"
                      value={userData?.job_title || "Teacher"}
                      readOnly
                    />
                  </div>
                </div>

                <div>
                  <label className="block text-left text-sm font-medium text-gray-700 mb-1">
                    School
                  </label>
                  <input
                    type="text"
                    className="w-full px-3 py-2 rounded-lg bg-gray-50"
                    value={userData?.school || "Westside High School"}
                    readOnly
                  />
                </div>

                <div>
                  <label className="block text-left text-sm font-medium text-gray-700 mb-1">
                    Billing Address
                  </label>
                  <textarea
                    className="w-full px-3 py-2 rounded-lg bg-gray-50"
                    value={
                      userData?.billing_address ||
                      "123 Main St, Anytown, CA 94321"
                    }
                    readOnly
                    rows={3}
                  />
                </div>

                <div className="pt-4">
                  <div className="flex items-center justify-between">
                    <button
                      className="px-4 py-2 bg-yellow-500 text-white rounded-lg hover:bg-yellow-600 transition"
                      onClick={() => navigate("/profile/edit")}
                    >
                      Edit Profile
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );

      default:
        return null;
    }
  };

  return (
    <div
      style={{ minHeight: "88vh" }}
      className="min-h-screen bg-gray-50 mt-24"
    >
      {/* Mobile navigation toggle */}
      <div className="lg:hidden fixed top-0 left-0 right-0 z-20 bg-white shadow-sm px-4 py-2">
        <button
          onClick={() => setIsSidebarOpen(!isSidebarOpen)}
          className="p-2 rounded-lg hover:bg-gray-100"
        >
          {isSidebarOpen ? (
            <X className="w-6 h-6" />
          ) : (
            <Menu className="w-6 h-6" />
          )}
        </button>
      </div>

      {/* Sidebar */}
      <div
        className={`mt-24 fixed absolute inset-y-0 left-0 z-10 w-64 bg-white shadow-sm transform transition-transform duration-200 ease-in-out lg:translate-x-0 ${
          isSidebarOpen ? "translate-x-0" : "-translate-x-full"
        }`}
      >
        <div className="flex flex-col h-full">
          <div className="p-4 border-b">
            <h1 className="text-2xl font-bold text-yellow-600">
              {userData?.full_name}
            </h1>
          </div>

          <nav className="flex-1 p-4 space-y-1">
            {navigation.map((item) => {
              const Icon = item.icon;
              return (
                <button
                  key={item.id}
                  onClick={() => setActiveSection(item.id)}
                  className={`flex items-center w-full px-4 py-2 text-left rounded-lg transition-colors ${
                    activeSection === item.id
                      ? "bg-yellow-50 text-yellow-700"
                      : "text-gray-700 hover:bg-gray-50"
                  }`}
                >
                  <Icon className="w-5 h-5 mr-3" />
                  <span className="flex-1">{item.name}</span>
                  {activeSection === item.id && (
                    <ChevronRight className="w-4 h-4" />
                  )}
                </button>
              );
            })}
          </nav>

          <div className="p-4 border-t">
            <button
              onClick={handleLogout}
              className="flex items-center w-full px-4 py-2 text-left text-gray-700 rounded-lg hover:bg-gray-50"
            >
              <LogOut className="w-5 h-5 mr-3" />
              Log out
            </button>
          </div>
        </div>
      </div>

      {/* Main content */}
      <div
        className={`lg:ml-64 transition-all duration-200 ease-in-out ${
          isSidebarOpen ? "ml-64" : "ml-0"
        }`}
      >
        <main className="p-8 pt-24 lg:pt-8">{renderContent()}</main>
      </div>
    </div>
  );
};

export default DashboardLayout;
