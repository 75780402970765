import React, { useEffect, useState } from "react";
import "./style.css";
import Box from "../Dashboard/Box";

const additionalStyles = {
  input: {
    width: "90%",
    borderTop: "none",
    borderLeft: "none",
    borderRight: "none",
    borderBottom: "1px solid #000",
    height: "32px",
    background: "transparent",
    outline: "none",
    padding: "4px 0",
    position: "relative",
    zIndex: 10,
  },
  textarea: {
    width: "90%",
    borderTop: "none",
    borderLeft: "none",
    borderRight: "none",
    borderBottom: "1px solid #000",
    minHeight: "120px",
    background: "transparent",
    outline: "none",
    padding: "4px 0",
    resize: "vertical",
    fontFamily: "inherit",
    fontSize: "inherit",
    position: "relative",
    zIndex: 10,
  },
  select: {
    width: "90%",
    borderTop: "none",
    borderLeft: "none",
    borderRight: "none",
    borderBottom: "1px solid #000",
    height: "32px",
    background: "transparent",
    outline: "none",
    padding: "4px 0",
    position: "relative",
    zIndex: 10,
  },
  formContainer: {
    position: "relative",
    zIndex: 5,
  },
};

const ContactForm = () => {
  const [formData, setFormData] = useState({
    school_name: "",
    name: "",
    email_address: "",
    position: "",
    description: "",
  });

  const [errors, setErrors] = useState({});

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Explicitly handle individual input changes to ensure proper event handling
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    // Clear error for this field when user starts typing
    if (errors[name]) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: null,
      }));
    }
  };

  const validateForm = () => {
    let tempErrors = {};
    let isValid = true;

    if (!formData.school_name.trim()) {
      tempErrors.school_name = "This field is required";
      isValid = false;
    }

    if (!formData.name.trim()) {
      tempErrors.name = "This field is required";
      isValid = false;
    }

    if (!formData.email_address.trim()) {
      tempErrors.email_address = "This field is required";
      isValid = false;
    }

    if (!formData.position) {
      tempErrors.position = "This field is required";
      isValid = false;
    }

    setErrors(tempErrors);
    return isValid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Form submitted with data:", formData);

    if (validateForm()) {
      console.log("Validation passed. Form data:", formData);
      // Here you would typically send the data to a server

      // Reset form after successful submission
      setFormData({
        school_name: "",
        name: "",
        email_address: "",
        position: "",
        description: "",
      });
    }
  };

  // Create a specific handler for the select element
  const handleSelectChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    if (errors[name]) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: null,
      }));
    }
  };

  return (
    <div>
      <section className="c-pageBanner c-pageBanner--contact sectionContainer">
        {/* Add pointer-events: none to ensure overlay doesn't block inputs */}
        <div
          className="c-pageBanner__backgroundOverlay"
          style={{ pointerEvents: "none" }}
        ></div>
        <div style={{ paddingTop: "0px" }} className="c-pageBanner__container">
          <div className="c-contact">
            <div className="c-contact__content">
              <h1 className="c-contact__contentTitle">Contact form</h1>
              <p
                style={{ fontWeight: "bold", fontSize: "22px" }}
                className="c-contact__contentIntro"
              >
                If you would like to learn more about
              </p>
              <ul className="c-contact__list">
                <li
                  style={{
                    fontWeight: "bold",
                    fontSize: "22px",
                    padding: 0,
                    marginTop: 22,
                    marginLeft: 3,
                  }}
                >
                  CPD sessions
                </li>
                <li
                  style={{
                    fontWeight: "bold",
                    fontSize: "22px",
                    padding: 0,
                    marginTop: 22,
                    marginLeft: 3,
                  }}
                >
                  student events
                </li>
                <li
                  style={{
                    fontWeight: "bold",
                    fontSize: "22px",
                    padding: 0,
                    marginTop: 22,
                    marginLeft: 3,
                  }}
                >
                  ordering resources
                </li>
                <li
                  style={{
                    fontWeight: "bold",
                    fontSize: "22px",
                    padding: 0,
                    marginTop: 22,
                    marginLeft: 3,
                  }}
                >
                  student subscriptions
                </li>
                <li
                  style={{
                    fontWeight: "bold",
                    fontSize: "22px",
                    padding: 0,
                    marginTop: 22,
                    marginLeft: 3,
                  }}
                >
                  working with us (careers)
                </li>
              </ul>
              <p
                style={{ fontWeight: "bold", fontSize: "22px", marginTop: 22 }}
                className="c-contact__followUp"
              >
                get in touch and we will connect with you as soon as we become
                available.
              </p>
            </div>
            {/* Increase form z-index to be above overlay */}
            <div
              className="c-contact__form"
              style={{ ...additionalStyles.formContainer, zIndex: 20 }}
            >
              <form className="form-container" onSubmit={handleSubmit}>
                <div className="form-div">
                  <div className="form-text-div">
                    <div className="Labelcontent">
                      School or Institution name
                      <span
                        style={{
                          fontWeight: "300",
                          color: "green",
                          fontSize: 18,
                        }}
                      >
                        *
                      </span>
                    </div>
                    <input
                      type="text"
                      placeholder="School or Institution Name"
                      style={additionalStyles.input}
                      name="school_name"
                      value={formData.school_name}
                      onChange={handleInputChange}
                      onClick={(e) => e.stopPropagation()}
                    />
                    {errors.school_name && (
                      <span className="error-message">
                        {errors.school_name}
                      </span>
                    )}
                  </div>
                  <div className="form-text-div">
                    <div className="Labelcontent">
                      Name
                      <span
                        style={{
                          fontWeight: "300",
                          color: "green",
                          fontSize: 18,
                        }}
                      >
                        *
                      </span>
                    </div>
                    <input
                      type="text"
                      placeholder="Name"
                      style={additionalStyles.input}
                      name="name"
                      value={formData.name}
                      onChange={handleInputChange}
                      onClick={(e) => e.stopPropagation()}
                    />
                    {errors.name && (
                      <span className="error-message">{errors.name}</span>
                    )}
                  </div>
                </div>
                <div className="form-div">
                  <div className="form-text-div">
                    <div className="Labelcontent">
                      Email address
                      <span
                        style={{
                          fontWeight: "300",
                          color: "green",
                          fontSize: 18,
                        }}
                      >
                        *
                      </span>
                    </div>
                    <input
                      type="email"
                      placeholder="Email address"
                      style={additionalStyles.input}
                      name="email_address"
                      value={formData.email_address}
                      onChange={handleInputChange}
                      onClick={(e) => e.stopPropagation()}
                    />
                    {errors.email_address && (
                      <span className="error-message">
                        {errors.email_address}
                      </span>
                    )}
                  </div>
                  <div className="form-text-div">
                    <div className="Labelcontent">
                      Position
                      <span
                        style={{
                          fontWeight: "300",
                          color: "green",
                          fontSize: 18,
                        }}
                      >
                        *
                      </span>
                    </div>
                    <select
                      style={{ ...additionalStyles.select, cursor: "pointer" }}
                      name="position"
                      value={formData.position}
                      onChange={handleSelectChange}
                      onClick={(e) => e.stopPropagation()}
                    >
                      <option value="">Select a position</option>
                      <option value="Examiner">Examiner</option>
                      <option value="Student">Student</option>
                      <option value="Teacher">Teacher</option>
                    </select>
                    {errors.position && (
                      <span className="error-message">{errors.position}</span>
                    )}
                  </div>
                </div>
                <div className="form-text-div">
                  <div className="Labelcontent">Your message</div>
                  <textarea
                    placeholder="Please enter your message"
                    style={additionalStyles.textarea}
                    name="description"
                    value={formData.description}
                    onChange={handleInputChange}
                    onClick={(e) => e.stopPropagation()}
                  />
                </div>
                <div style={{ marginTop: 20 }}>
                  <button type="submit" className="submitButton">
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
      <div className="bgContainer linearBG">
        <Box />
      </div>
    </div>
  );
};

export default ContactForm;
