import React, { useState, useEffect } from "react";
import {
  Plus,
  Edit,
  Trash2,
  LoaderCircle,
  X,
  Upload,
  File,
  ChevronLeft,
  ChevronRight,
} from "lucide-react";
import { useParams, useNavigate } from "react-router-dom";
import ThemeCard from "./ThemeCard";

const ResourceThemes = () => {
  const { resourceId } = useParams();
  const navigate = useNavigate();
  const [themes, setThemes] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editingTheme, setEditingTheme] = useState(null);

  // Pagination states
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const defaultThemeData = {
    name: "",
    price: "",
    data: {
      images: [],
      pdfs: [],
      docs: [],
    },
  };

  const [themeData, setThemeData] = useState(defaultThemeData);

  useEffect(() => {
    fetchThemes();
  }, [resourceId, currentPage, pageSize]);

  const fetchThemes = async () => {
    try {
      const token = localStorage.getItem("access_token");
      const response = await fetch(
        `https://api2.made2tech.com/themes/${resourceId}/themes?page=${currentPage}&page_size=${pageSize}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) throw new Error("Failed to fetch themes");
      const data = await response.json();

      // Handle the case where total_pages is 0
      setThemes(data.items || []);
      setTotalPages(Math.max(1, data.total_pages || 1));

      // If we're on a page higher than total_pages, go back to page 1
      if (currentPage > 1 && data.items.length === 0) {
        setCurrentPage(1);
      }
    } catch (err) {
      setError(err.message);
      setThemes([]);
      setTotalPages(1);
    } finally {
      setIsLoading(false);
    }
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setThemeData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);

    const allowedImageTypes = ["image/jpeg", "image/png", "image/gif"];
    const allowedPdfTypes = ["application/pdf"];
    const allowedDocTypes = [
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "application/vnd.ms-powerpoint",
      "application/vnd.openxmlformats-officedocument.presentationml.presentation",
    ];

    const images = files.filter((file) =>
      allowedImageTypes.includes(file.type)
    );
    const pdfs = files.filter((file) => allowedPdfTypes.includes(file.type));
    const docs = files.filter((file) => allowedDocTypes.includes(file.type));

    if (files.length !== images.length + pdfs.length + docs.length) {
      setError(
        "Some files were not added. Please check the allowed file types."
      );
    }

    setThemeData((prev) => ({
      ...prev,
      data: {
        images: [...prev.data.images, ...images],
        pdfs: [...prev.data.pdfs, ...pdfs],
        docs: [...prev.data.docs, ...docs],
      },
    }));
  };

  const removeFile = (fileType, index) => {
    setThemeData((prev) => ({
      ...prev,
      data: {
        ...prev.data,
        [fileType]: prev.data[fileType].filter((_, idx) => idx !== index),
      },
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem("access_token");
      const formData = new FormData();

      formData.append("name", themeData.name);
      formData.append("price", themeData.price);

      // Append files with their respective types
      themeData.data.images.forEach((file) => {
        formData.append(`files`, file);
      });
      themeData.data.pdfs.forEach((file) => {
        formData.append(`files`, file);
      });
      themeData.data.docs.forEach((file) => {
        formData.append(`files`, file);
      });

      const url = editingTheme
        ? `https://api2.made2tech.com/themes/${editingTheme.id}`
        : `https://api2.made2tech.com/themes/${resourceId}`;

      const response = await fetch(url, {
        method: editingTheme ? "PUT" : "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      });

      if (!response.ok) {
        throw new Error(
          `Failed to ${editingTheme ? "update" : "create"} theme`
        );
      }

      await fetchThemes();
      setIsModalOpen(false);
      setEditingTheme(null);
      setThemeData(defaultThemeData);
    } catch (err) {
      setError(err.message);
    }
  };

  const handleDelete = async (themeId) => {
    if (!window.confirm("Are you sure you want to delete this theme?")) return;

    try {
      const token = localStorage.getItem("access_token");
      const response = await fetch(
        `https://api2.made2tech.com/themes/${themeId}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) throw new Error("Failed to delete theme");
      await fetchThemes();
    } catch (err) {
      setError(err.message);
    }
  };

  // Pagination controls
  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  const PaginationControls = () => (
    <div className="flex items-center justify-center space-x-4 mt-6">
      <button
        onClick={() => handlePageChange(currentPage - 1)}
        disabled={currentPage === 1}
        className={`p-2 rounded-lg ${
          currentPage === 1
            ? "text-gray-400 cursor-not-allowed"
            : "text-blue-600 hover:bg-blue-50"
        }`}
      >
        <ChevronLeft className="w-5 h-5" />
      </button>

      <div className="flex items-center space-x-2">
        {[...Array(totalPages)]?.map((_, index) => {
          const pageNumber = index + 1;
          return (
            <button
              key={pageNumber}
              onClick={() => handlePageChange(pageNumber)}
              className={`px-3 py-1 rounded-lg ${
                currentPage === pageNumber
                  ? "bg-blue-600 text-white"
                  : "text-gray-600 hover:bg-blue-50"
              }`}
            >
              {pageNumber}
            </button>
          );
        })}
      </div>

      <button
        onClick={() => handlePageChange(currentPage + 1)}
        disabled={currentPage === totalPages}
        className={`p-2 rounded-lg ${
          currentPage === totalPages
            ? "text-gray-400 cursor-not-allowed"
            : "text-blue-600 hover:bg-blue-50"
        }`}
      >
        <ChevronRight className="w-5 h-5" />
      </button>
    </div>
  );

  if (isLoading) {
    return (
      <div className="flex items-center justify-center h-64">
        <LoaderCircle className="w-8 h-8 animate-spin text-blue-500" />
      </div>
    );
  }

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <div>
          <button
            onClick={() => navigate("/admin/resources")}
            className="text-blue-600 hover:text-blue-800 mb-2"
          >
            ← Back to Resources
          </button>
          <h2 className="text-2xl font-bold text-gray-900">Themes</h2>
        </div>
        <button
          onClick={() => setIsModalOpen(true)}
          className="flex items-center px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700"
        >
          <Plus className="w-4 h-4 mr-2" />
          Add Theme
        </button>
      </div>

      {error && (
        <div className="bg-red-50 text-red-700 p-4 rounded-lg">
          <p>Error: {error}</p>
        </div>
      )}

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {themes?.map((theme) => (
          <ThemeCard
            key={theme.id}
            theme={theme}
            onEdit={(theme) => {
              setEditingTheme(theme);
              setThemeData({
                name: theme.name,
                description: theme.description || "",
                price: theme.price,
                data: {
                  images: [],
                  pdfs: [],
                  docs: [],
                },
              });
              setIsModalOpen(true);
            }}
            onDelete={handleDelete}
          />
        ))}
      </div>

      <PaginationControls
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={handlePageChange}
      />

      {isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
          <div className="bg-white rounded-lg w-full max-w-2xl max-h-[90vh] overflow-y-auto">
            <div className="flex justify-between items-center p-6 border-b">
              <h2 className="text-xl font-semibold">
                {editingTheme ? "Edit Theme" : "Add New Theme"}
              </h2>
              <button
                onClick={() => {
                  setIsModalOpen(false);
                  setEditingTheme(null);
                  setThemeData(defaultThemeData);
                }}
                className="text-gray-500 hover:text-gray-700"
              >
                <X className="w-6 h-6" />
              </button>
            </div>

            <form onSubmit={handleSubmit} className="p-6 space-y-6">
              <div>
                <label className="block text-left text-sm font-medium text-gray-700 mb-2">
                  Theme Name
                </label>
                <input
                  type="text"
                  name="name"
                  value={themeData.name}
                  onChange={handleInputChange}
                  className="w-full p-2 border rounded focus:ring-2 focus:ring-blue-500"
                  required
                />
              </div>

              <div>
                <label className="block text-left text-sm font-medium text-gray-700 mb-2">
                  Price
                </label>
                <input
                  type="text"
                  name="price"
                  value={themeData.price}
                  onChange={handleInputChange}
                  placeholder="e.g. 49.99"
                  className="w-full p-2 border rounded focus:ring-2 focus:ring-blue-500"
                  required
                />
              </div>

              <div>
                <label className="block text-left text-sm font-medium text-gray-700 mb-2">
                  Files
                </label>
                <div className="space-y-4">
                  {Object.entries(themeData.data)?.map(([type, files]) => (
                    <div key={type}>
                      {files.length > 0 && (
                        <div className="mb-4">
                          <h4 className="text-sm font-medium text-gray-700 mb-2 capitalize">
                            {type}:
                          </h4>
                          {files?.map((file, index) => (
                            <div
                              key={index}
                              className="flex items-center justify-between bg-gray-50 p-2 rounded mb-2"
                            >
                              <div className="flex items-center">
                                <File className="w-4 h-4 mr-2" />
                                <span className="text-sm">{file.name}</span>
                              </div>
                              <button
                                type="button"
                                onClick={() => removeFile(type, index)}
                                className="text-red-600 hover:text-red-800"
                              >
                                <Trash2 className="w-4 h-4" />
                              </button>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  ))}
                  <label className="cursor-pointer flex items-center justify-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50">
                    <Upload className="w-4 h-4 mr-2" />
                    Upload Files
                    <input
                      type="file"
                      className="hidden"
                      multiple
                      onChange={handleFileChange}
                      accept=".pdf,.doc,.docx,.ppt,.pptx,.jpg,.jpeg,.png,.gif"
                    />
                  </label>
                  <p className="text-sm text-gray-500">
                    Accepted files: Images (JPG, PNG, GIF), PDFs, Documents
                    (DOC, DOCX, PPT, PPTX)
                  </p>
                </div>
              </div>

              <div className="flex justify-end space-x-3 pt-4">
                <button
                  type="button"
                  onClick={() => {
                    setIsModalOpen(false);
                    setEditingTheme(null);
                    setThemeData(defaultThemeData);
                  }}
                  className="px-4 py-2 border border-gray-300 rounded-md text-gray-700 hover:bg-gray-50"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700"
                >
                  {editingTheme ? "Update Theme" : "Add Theme"}
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default ResourceThemes;
