import React from "react";
import { Edit, Trash2, File } from "lucide-react";

const FileList = ({ files = [], type }) => {
  if (files.length === 0) return null;
  return (
    <div className="mb-4">
      <h4 className="text-sm font-medium text-gray-700 mb-2 capitalize">
        {type}:
      </h4>
      <div className="space-y-2">
        {files.map((file, index) => (
          <div key={index} className="flex items-center text-sm text-gray-600">
            <File className="w-4 h-4 mr-2" />
            <span>{file?.original_name || file?.name || file || ""}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

const ThemeCard = ({ theme, onEdit, onDelete }) => {
  return (
    <div className="bg-white rounded-lg shadow-md p-6">
      <div className="flex justify-between items-start mb-4">
        <div>
          <h3 className="text-lg font-semibold">{theme.name}</h3>
          <p className="text-sm font-bold text-blue-600">£{theme.price}</p>
          {theme.description && (
            <p className="text-sm text-gray-600 mt-2">{theme.description}</p>
          )}
        </div>
        <div className="flex space-x-2">
          <button
            onClick={() => onEdit(theme)}
            className="text-blue-600 hover:text-blue-800"
          >
            <Edit className="w-4 h-4" />
          </button>
          <button
            onClick={() => onDelete(theme.id)}
            className="text-red-600 hover:text-red-800"
          >
            <Trash2 className="w-4 h-4" />
          </button>
        </div>
      </div>

      <div className="mt-4 space-y-4">
        {Object.entries(theme.data || {}).map(([type, files]) => (
          <FileList key={type} type={type} files={files} />
        ))}
      </div>
    </div>
  );
};

export default ThemeCard;
