import React, { useEffect, useState } from "react";
import { MapPin } from "lucide-react";
import { useNavigate } from "react-router-dom";
import Box from "../Dashboard/Box";
import GoToTopButton from "../GotoTop";
import img from "../../Assets/Images/P1. Events-min.jpg";
import inperson from "../../Assets/Images/EV1. In-person CPD-min.jpg";
import online from "../../Assets/Images/EV2. Online CPD-min.jpg";
import bespoke from "../../Assets/Images/EV3. Bespoke CPD-min.jpg";
import student from "../../Assets/Images/EV4. Student events-min.jpg";

const NewCourses = () => {
  const navigate = useNavigate();
  const [selectedType, setSelectedType] = useState("");
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);

  // Month order for proper alphabetical sorting
  const monthOrder = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchEvents();
  }, []);

  const fetchEvents = async () => {
    try {
      const response = await fetch("https://api2.made2tech.com/event/");
      const data = await response.json();

      // Transform API data to match our event structure
      const transformedEvents = data.map((event) => {
        // Parse teachers array if it's a string
        let teachers = event.teachers;
        if (
          teachers.length === 1 &&
          typeof teachers[0] === "string" &&
          teachers[0].startsWith("[")
        ) {
          try {
            teachers = JSON.parse(teachers[0]);
          } catch (e) {
            teachers = [teachers[0]];
          }
        }

        // Only add pound symbol if the price is not "Free"
        const formattedPrice =
          event.price === "Free"
            ? "Free"
            : event.price.startsWith("£")
            ? event.price
            : `£${event.price}`;

        return {
          id: event.id,
          title: event.title,
          teachers: teachers,
          date: {
            month: event.date_month,
            day: event.date_day,
            year: event.date_year,
          },
          location: event.location,
          price: formattedPrice,
          type: event.type,
          color: event.color,
          why_attend: event.why_attend,
          programme: event.programme,
          trainers: event.trainers,
        };
      });

      // Sort events by year first and then by month
      const sortedEvents = [...transformedEvents].sort((a, b) => {
        // First compare years
        const yearA = parseInt(a.date.year);
        const yearB = parseInt(b.date.year);

        if (yearA !== yearB) {
          return yearA - yearB; // Ascending order of years (2024 before 2025)
        }

        // If years are the same, sort by month
        const monthA = monthOrder.indexOf(a.date.month);
        const monthB = monthOrder.indexOf(b.date.month);
        return monthA - monthB;
      });

      setEvents(sortedEvents);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching events:", error);
      setLoading(false);
    }
  };

  const handleEventClick = (event) => {
    navigate(`/event/${event.id}`, {
      state: {
        eventDetails: {
          title: event.title,
          hosts: Array.isArray(event.teachers)
            ? event.teachers.map((teacher) => ({ name: teacher }))
            : [{ name: event.teachers }],
          sessions: [
            {
              date: `${event.date.day}${getDaySuffix(event.date.day)} ${
                event.date.month
              } ${event.date.year}`,
              time: "11:00 AM - 12:00 PM",
              timezone: "+00:00",
              location: event.location,
              price: event.price,
              type: event.type,
            },
          ],
          color: event.color,
          description:
            event.why_attend?.description || getEventDescription(event.type),
          why_attend: event.why_attend,
          programme: event.programme,
          trainers: event.trainers,
        },
      },
    });
  };

  const getDaySuffix = (day) => {
    const num = parseInt(day);
    if (num >= 11 && num <= 13) return "th";
    switch (num % 10) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  };

  const getEventDescription = (type) => {
    const descriptions = {
      "In-person CPD":
        "Join us for an engaging In-person CPD session with our expert trainers. Network with fellow professionals and gain hands-on experience.",
      "Online CPD":
        "Participate in our professional development session from anywhere in the world. Interactive online learning with expert guidance.",
      "Bespoke CPD":
        "A customized training session tailored to your specific needs and requirements.",
      "Student Events":
        "Special events designed for students to enhance their learning experience and academic performance.",
    };
    return descriptions[type] || "Join us for this educational event.";
  };

  const filteredEvents = selectedType
    ? events.filter((event) => event.type === selectedType)
    : events;

  return (
    <div>
      <section className="c-pageBanner c-pageBanner--events sectionContainer1">
        <div className="c-pageBanner__backgroundOverlay"></div>
        <div className="c-pageBanner__container responsivePadding">
          <div className="c-pageBanner__content">
            <h1 className="c-pageBanner__heading">Events</h1>
          </div>
          <img src={img} alt="Courses banner" className="c-pageBanner__img" />
        </div>
      </section>

      <section style={{}} className="sectionContainer1 bgPureWhite">
        <div className="c-coursesTb responsivePadding">
          <div className="c-coursesTb__header">
            <h2 style={{ marginTop: 0 }} className="c-coursesTb__headerHeading">
              Learning with us
            </h2>
          </div>
          <div className="c-coursesTb__thumbnails">
            <div className="c-coursesTb__thumbnail">
              <img
                src={inperson}
                alt=""
                className="c-coursesTb__thumbnailImg"
              />
              {/* <div className="c-tag c-tag--bestSeller">Best seller</div> */}
              <div className="c-coursesTb__thumbnailTitle">
                In-person CPD
                <p className="thumbnail-description">
                  Attend our key in-person training sessions and learn from our
                  senior examiners. Take the opportunity to network with fellow
                  teachers. Our courses are predominantly held in London, with
                  additional sessions available in various cities in the UK and
                  around the world.
                </p>
              </div>
            </div>

            <div className="c-coursesTb__thumbnail">
              <img src={online} alt="" className="c-coursesTb__thumbnailImg" />
              {/* <div className="c-tag c-tag--bestSeller">Best seller</div> */}
              <div className="c-coursesTb__thumbnailTitle">
                Online CPD
                <p className="thumbnail-description">
                  Engage in extensive professional development from any part of
                  the world. We provide precise training across qualifications
                  that cover a range of challenging content and assessment. Our
                  short focussed sessions keep staff engaged at all times.
                </p>
              </div>
            </div>

            <div className="c-coursesTb__thumbnail">
              <img src={bespoke} alt="" className="c-coursesTb__thumbnailImg" />
              {/* <div className="c-tag c-tag--bestSeller">Best seller</div> */}

              <div className="c-coursesTb__thumbnailTitle">
                Bespoke CPD
                <p className="thumbnail-description">
                  We offer customised in-house training tailored to your
                  specific needs. You select the focus area. Our senior
                  examiners will design and run a bespoke programme that
                  seamlessly aligns with your department's targets and
                  institution's priorities.
                </p>
              </div>
            </div>

            <div className="c-coursesTb__thumbnail">
              <img src={student} alt="" className="c-coursesTb__thumbnailImg" />
              {/* <div className="c-tag c-tag--bestSeller">Best Seller</div> */}
              <div className="c-coursesTb__thumbnailTitle">
                Student events
                <p className="thumbnail-description">
                  The main objective of our in-house events is to enhance pupil
                  learning. Conducted by senior examiners, these key events
                  provide effective learning strategies and guidance on
                  improving the quality of written work. The outcome? Higher
                  overall pupil grades.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="sectionContainer1">
        <div style={{ paddingLeft: 0, paddingRight: 0 }} className="p-6">
          <div className="bg-amber-500/50 p-4 rounded-lg mb-8">
            <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
              <div className="text-left w-full p-2 border rounded-md bg-white">
                GCE
              </div>
              <div className="text-left w-full p-2 border rounded-md bg-white">
                Edexcel
              </div>
              <div className="text-left w-full p-2 border rounded-md bg-white">
                Economics
              </div>
              <select
                value={selectedType}
                onChange={(e) => setSelectedType(e.target.value)}
                className="w-full p-2 border rounded-md hover:border-amber-500 focus:ring-2 focus:ring-amber-500 focus:outline-none transition-all"
              >
                <option value="">All types</option>
                <option value="In-person CPD">In-person CPD</option>
                <option value="Online CPD">Online CPD</option>
                <option value="Bespoke CPD">Bespoke CPD</option>
                <option value="Student Events">Student events</option>
              </select>
            </div>
          </div>

          {loading ? (
            <div className="text-center py-8">Loading events...</div>
          ) : (
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              {filteredEvents.map((event) => (
                <div
                  key={event.id}
                  onClick={() => handleEventClick(event)}
                  className="border rounded-lg shadow-sm p-6 relative cursor-pointer 
                     transition-all duration-300 ease-in-out
                     hover:shadow-lg hover:scale-[1.02] hover:border-amber-500
                     active:scale-[0.98] 
                     bg-white hover:bg-gray-50"
                >
                  <div
                    style={{
                      backgroundColor: event.color,
                      color: "black",
                      borderRadius: 8,
                    }}
                    className="absolute top-0 right-0 text-sm px-3 py-1 rounded-tr-lg"
                  >
                    {event.type}
                  </div>

                  <div className="flex flex-row items-start gap-6 sm:gap-28">
                    <div className="text-center min-w-24 group">
                      <div
                        style={{ backgroundColor: "rgb(32 123 191)" }}
                        className="text-white py-1 rounded-t-md w-24 
                              transition-colors duration-300 group-hover:bg-blue-700"
                      >
                        {event.date.month}
                      </div>
                      <div
                        className="border-x border-b rounded-b-md py-2 w-24
                              transition-colors duration-300 group-hover:bg-gray-50"
                      >
                        <div className="text-2xl font-bold">
                          {event.date.day}
                        </div>
                        <div className="text-sm text-gray-600">
                          {event.date.year}
                        </div>
                      </div>
                    </div>

                    <div className="space-y-2 text-left flex-grow">
                      <h3
                        className="text-lg font-semibold group-hover:text-amber-600 
                             transition-colors duration-300"
                      >
                        {event.title}
                      </h3>
                      <p className="text-gray-600">
                        {event.teachers.join(", ")}
                      </p>

                      <div className="flex items-center text-gray-600">
                        <MapPin className="w-4 h-4 mr-2" />
                        <span>{event.location}</span>
                      </div>

                      <p className="text-gray-800 font-medium">
                        {event.price}
                        {event.price !== "Free" && (
                          <span className="text-sm text-gray-500">
                            {" "}
                            (ex VAT)
                          </span>
                        )}
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>

      <div className="bgContainer linearBG">
        <Box />
        <section className="sectionContainer">
          <div className="c-cta">
            <div className="c-cta__title">Get in touch</div>
            <p className="c-cta__intro">
              If you require additional information or have any queries,
              <br /> please feel free to send us a message.
            </p>
            <button
              className="c-button ctaOrange"
              style={{
                background: "rgb(66 66 66)",
                border: "none",
                color: "#fff",
              }}
              onClick={() => {
                navigate("/contact");
              }}
            >
              Contact us
            </button>
          </div>
        </section>
      </div>

      <GoToTopButton />
    </div>
  );
};

export default NewCourses;
